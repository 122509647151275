import React, { Component } from 'react';
import './news-card.css'

class NewsCard extends Component {
    render(){ return (
            <div className="col-lg-4 d-grid">
                <div className="card shadow mb-4 h-100">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary" data-cy={"news-card-title-" + this.props.position}>{this.props.title}</h6>
                    </div>
                    <div className="card-body card-body-news">
                        <div className="text-center">
                            {/* <img className="mb-2 news-image" src={this.props.image} alt="..." /> */}
                            <img className="img-fluid px-3 px-sm-4 mt-3 mb-4 news-image" src={this.props.image} data-cy={"news-card-image-" + this.props.position} alt="..." />
                        </div>
                        <p data-cy={"news-card-description-" + this.props.position}>{this.props.description}</p>
                        <a className="readMeButton" rel="noopener noreferrer" data-cy={"news-card-read-more-" + this.props.position} data-tid={this.props.title} href={this.props.link} target="_blank" >Read more &rarr;</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsCard;