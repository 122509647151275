import React, { Component } from "react";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import HttpUtil from '../../../../api/http-util';
import InFlightService from '../../../../services/InFlightService';
import InflightCard from './InflightCard';

import './inFlight.css'

class InFlightPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eid: this.props.eid,
      smsr: null,
      smdp: null,
      oktaAuth: this.props.oktaAuth,
    }
  }

  retrieveInflights = () => {
    this.setState({smsr: null});
    this.setState({smdp: null});

    const headerConfig = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
    InFlightService.getInFlights(this.state.eid, headerConfig)
    .then((response) => {
        var inFlightData = response.data;
        this.setState({smsr: inFlightData.smsr});
        this.setState({smdp: inFlightData.smdp});
      })
    .catch((e) => {
      this.setState({smsr: []});
      this.setState({smdp: []});
    });
  }

  componentDidMount() {
    this.retrieveInflights();
  }

  render(){
    let cardTitle = "SM_SR"
    let cardTasks = this.state.smsr;

    if ( (this.state.smdp !== undefined) && (this.state.smdp !== null)){
      cardTitle = "SM_DP";
      cardTasks = this.state.smdp;
    }

    return (
      <div>
        <div className="row">
          <div className="col-10">
            <h5 className="text-primary"><i className="bi bi-truck"></i> In-Flight Operations</h5>
            <p>Here are the <strong>current in-flight</strong> operations that are being executed on this eSIM.</p>
          </div>

          <div className="col-2 action-btns-right">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-bottom">Refresh In-Flights</Tooltip>}>
              <button type="button"
                  data-cy="inflight-refresh"
                  onClick={e => this.retrieveInflights()}
                  className="btn btn-circle rps-action-btn btn-info">
                <i className="bi bi-arrow-counterclockwise fa-sm fa-fw"></i>
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <hr/>
        <div className="row">
          <InflightCard title={cardTitle} tasks={cardTasks}/>
        </div>

      </div>
    )
  }
}

export default InFlightPane;
