import React, { Component } from "react";
import {Spinner} from 'react-bootstrap';

import ColdStorageProfileItem from './ColdStorageProfileItem';

class ProfileTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateToggleFormat: 2
    }
  }
  renderTableHeaders(){
    return (
      <thead>
        <tr>
          <th className="w-30">EID</th>
          <th className="w-25">ICCIDs</th>
          <th className="w-25">Created</th>
          <th className="w-15">Move to M2M RSP</th>
        </tr>
      </thead>
    )
  }

  renderEmptyTable(isLoading){
      let loadingContent = <td colSpan="8"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
      if (isLoading){
        loadingContent = <td colSpan="8"><Spinner animation="border" size="sm" /></td>;
      }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody>
            <tr>
              {loadingContent}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  toggleDateFormat = (index) => {
    let newIndex = (index === 3) ? 1 : (index + 1)
    this.setState({dateToggleFormat: newIndex})
  }

  renderTable(){
    return (
      <div className="table-responsive">
        <div id="alert-form" className="col-lg-12 d-grid" />
        <table className="table table-striped table-hover" data-tid="cold-eis-table">
          {this.renderTableHeaders()}
          <tbody>
            {
              this.props.content && this.props.content.map((profile, index) => (
                <ColdStorageProfileItem profile={profile} key={index} config={this.props.config} dateToggleFormat={this.state.dateToggleFormat} toggleFunction={this.toggleDateFormat} tableIndex={index}/>
              )) 
            }
          </tbody>
        </table>
      </div>
    )
  }

  render(){ 
    if (this.props.content === null){
      return this.renderEmptyTable(true);

    } else if (this.props.content.length === 0){
      return this.renderEmptyTable(false);

    } else{
      return this.renderTable();
    }
  }
}

export default ProfileTable;
