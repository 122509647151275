import React, { Component } from "react";
import { withOktaAuth } from '@okta/okta-react';
import HttpUtil from '../../api/http-util';

import NewsService from '../../services/NewsService';
import NewsCard from '../../components/NewsCard';

class NewsReel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsReel: [],
            oktaAuth: this.props.oktaAuth,
        }
    }

    componentDidMount(){
        this.setState({newsReel: this.retrieveNews()});
    }

    retrieveNews = () => {
        if(localStorage.getItem("news_reel") === null){
          const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
          NewsService.getNews(config).then((response) => {
              var news = response.data.Items;
              // reorder the records to be asc
              news = NewsService.orderByRecordNumber(news);
              var d = new Date();
              var tomorrow_at_2am = Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate() + 1, 2, 0, 0);
              news[0].ttl = tomorrow_at_2am;
              localStorage.setItem("news_reel", JSON.stringify(news))
              this.setState({newsReel: news});
          })
          .catch((e) => {
              this.setState({newsReel: []});
          });
        }
        else{
            var news = JSON.parse(localStorage.getItem("news_reel"));
            if(new Date().getTime > news[0].ttl){
                localStorage.removeItem("news_reel");
                this.retrieveNews();

            }else{
                return news
            }
        }

    }

    renderNewsReel(){
        if (this.state.newsReel !== undefined){
            return (
                <>
                    {
                        this.state.newsReel.map(function(news, index){
                            //position used for test references
                            let position = index + 1
                            return (
                                <NewsCard key={index} title={news.title} description={news.description} link={news.link} image={news.image} position={position}/>
                            );
                        })
                    }
                </>
            )
        }
    }


    render(){
        return (
            <>{this.renderNewsReel()}</>
        )
    }
}

export default withOktaAuth(NewsReel);
