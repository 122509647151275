import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import HttpUtil from "../../../api/http-util";
import ReactPaginate from "react-paginate";
import ConsumerProfileInventoryService from "../../../services/ConsumerProfileInventoryService";
import ProfileStates from "./ProfileStates";

import Search from "../../common/search/Search";
import ProfileTable from "./ProfileTable";
import ShowItems from "../../common/table/ShowItems";
import AlertOperationRequest from "./AlertOperationRequest.js";
import ReactDOM from "react-dom";
import "./consumerProfiles.css";

class ProfileCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			content: null,
			totalPages: 1,
			page: this.props.page,
			size: this.props.size,
			search: this.props.search,
			oktaAuth: this.props.oktaAuth,
			totalItems: 0,
			firstRecord: 0,
			lastRecord: 0,
			disabled: null,
			searchFilter: "",
			profileStates: ['AVAILABLE', 'INSTALLED', 'RELEASED', 'DOWNLOADED', 'LINKED', 'CONFIRMED', 'UNAVAILABLE', 'ERROR', 'ALLOCATED'],
			stateColors: {
				ALL: '#0591b5', AVAILABLE: '#1cc88a', INSTALLED: '#106348', RELEASED: '#703f54', ALLOCATED: '#eb8334', LINKED: '#7323a1',
				CONFIRMED: '#0d85e0', DOWNLOADED: '#e74a3b', ERROR: '#e74a3b', UNAVAILABLE: '#614f13'
			},
			getAllResponse: null
		};
	}

	handleGetProfilesResponse = (response) => {
		var page = response.data.page;
		this.setState({ totalPages: page.totalPages });
		var content = response.data._embedded.profiles;
		this.setState({ content: content, getAllResponse: content });
		const total = response.data.page.totalElements;
		this.setState({ totalItems: total });
		this.calculateFirstRecord();
		this.calculateLastRecord();
	};

	//Get the profiles by the state selected in the side panel.
	searchByProfileState(profileType, clicked) {
		let params = {
			page: clicked === undefined && this.state.profileStates.includes(this.props.searchFilter) && this.props.searchFilter !== this.state.searchFilter? this.state.page: !clicked?.params?.page ? 0: this.state.page,
			size: this.state.size, 
		}

		const config = HttpUtil.getHttpConfig(
			params,
			this.state.oktaAuth.getAccessToken()
		);

		this.setState({ search: profileType })
		this.setState({ searchFilter: profileType })
		
		ConsumerProfileInventoryService.searchByProfileState(
			profileType,
			config
		)
			.then((response) => this.handleGetProfilesResponse(response))
			.catch((e) => {
				this.setState({ totalPages: 0 });
				this.setState({ firstRecord: 0 });
				this.setState({ lastRecord: 0 });
				this.setState({ totalItems: 0 });
				this.setState({ content: [] });
			});
	}

	/* Copied from M2M - not required
	handleSearchProfilesResponse = (response) => {
		// this should only fine one profile, we had to remove the partial search dude to M2M issue
		this.setState({ totalPages: 0 });
		this.setState({ disabled: "disabled" });
		var profile = response.data.data;
		this.setState({ firstRecord: 1 });
		this.setState({ lastRecord: 1 });
		this.setState({ totalItems: 1 });

		var content = [];
		content.push(profile);
		this.setState({ content: content });
	};
	*/

	handleSearchByIccidProfilesResponse = (response) => {
		// this should only fine one profile, we had to remove the partial search dude to M2M issue
		this.setState({ totalPages: 0 });
		this.setState({ disabled: "disabled" });
		var profile = response.data._embedded.profiles[0];
		this.setState({ firstRecord: 1 });
		this.setState({ lastRecord: 1 });
		this.setState({ totalItems: 1 });
		var content = [];
		if (profile === "") {
			this.setState({ content: content });
			this.setState({ firstRecord: 0 });
			this.setState({ lastRecord: 0 });
			this.setState({ totalItems: 0 });
		} else {
			content.push(profile);
			this.setState({ content: content });
		}
		document.getElementById("0").click()
	};

	retrieveProfiles = (retrieveProfiles) => {
		if (this.state.size < 10) {
			this.setState({ size: 10 });
			this.handlePageChange({ selected: parseInt(this.state.page) });
		} else if (this.state.size > 10 && this.state.size < 20) {
			this.setState({ size: 20 });
			this.handlePageChange({ selected: parseInt(this.state.page) });
		} else if (
			(this.state.size > 20 && this.state.size < 50) ||
			this.state.size > 50
		) {
			this.setState({ size: 50 });
			this.handlePageChange({ selected: parseInt(this.state.page) });
		}
		const params = ConsumerProfileInventoryService.getRequestParams(
			this.state.page,
			this.state.size,
			this.state.search
		);

		const config = HttpUtil.getHttpConfig(
			params,
			this.state.oktaAuth.getAccessToken()
		);

		if (retrieveProfiles === 'ALL') {
			this.resetRadioButtons();
			this.searchByMnoId(config);
		} else if (this.state.searchFilter === "ICCID" && this.state.search !== '') {
			this.hideAlert();
			this.searchByIccid(config);
			this.resetDoughnutLabels();
		} else if (this.state.searchFilter === "ProfileType") {
			this.hideAlert();
			this.searchByProfileType(config);
			this.resetDoughnutLabels();
		} else if (this.state.profileStates.includes(this.state.searchFilter)) {
			this.hideAlert();
			this.searchByProfileState(this.state.searchFilter, config)
		} else {
			this.hideAlert();
			this.resetRadioButtons();
			this.searchByMnoId(config);
		}
	};

	retrieveProfilesRefresh = () => {

		const params = ConsumerProfileInventoryService.getRequestParams(
			this.state.page,
			this.state.size,
			this.state.search
		);

		const config = HttpUtil.getHttpConfig(
			params,
			this.state.oktaAuth.getAccessToken()
		);

		if (this.state.search !== "" && this.state.search !== undefined) {
			if (this.props.searchFilter === "ICCID") {
				this.handlePageRefreshRadioButtons("ICCID")
				this.searchByIccid(config);
			} else if (this.props.searchFilter === "ProfileType") {
				this.handlePageRefreshRadioButtons("ProfileType")
				this.searchByProfileType(config);
			}
		} else {
			this.resetRadioButtons();
			this.searchByMnoId(config);
		}
	};

	handlePageRefreshRadioButtons(searchType) {
		document.getElementById(`${searchType}RadioButton`).checked = 'checked'
		document.getElementById("profile-state-label").innerHTML = '';
		this.setState({ searchFilter: `${searchType}` })
	}

	resetDoughnutLabels() {
		document.getElementById("profile-state-label").innerHTML = '';
	}

	searchByMnoId(config) {
		this.hideAlert();
		this.setState({ searchFilter: 'ALL'})
		if (this.state.search !== 'ALL' && this.state.search !== undefined && this.state.search !== '') {
			this.setState({ search: 'ALL' })
		}
		ConsumerProfileInventoryService.getAll(config)
			.then((response) => {
				this.handleGetProfilesResponse(response);
				this.setState({ getAllResponse: response.data._embedded.profiles })
			})
			.catch((e) => {
				this.setState({ totalPages: 0 });
				this.setState({ firstRecord: 0 });
				this.setState({ lastRecord: 0 });
				this.setState({ totalItems: 0 });
				this.setState({ content: [] });
			});
	}
	searchByProfileType(config) {
		this.hideAlert();
		ConsumerProfileInventoryService.searchByProfileType(
			this.state.search,
			config
		)
			.then((response) => this.handleGetProfilesResponse(response))
			.catch((e) => {
				this.setState({ totalPages: 0 });
				this.setState({ firstRecord: 0 });
				this.setState({ lastRecord: 0 });
				this.setState({ totalItems: 0 });
				this.setState({ content: [] });
			});
	}

	searchByIccid(config) {
		if (this.state.search.length < 19 || this.state.search.length > 20) {
			this.showAlert(
				"Danger",
				"Invalid Search",
				`Your search does not meet the required length for an ICCID.`
			);
		} else {
			ConsumerProfileInventoryService.searchbyIccid(
				this.state.search,
				config
			)
				.then((response) =>
					this.handleSearchByIccidProfilesResponse(response)
				)
				.catch((e) => {
					this.setState({ totalPages: 0 });
					this.setState({ firstRecord: 0 });
					this.setState({ lastRecord: 0 });
					this.setState({ totalItems: 0 });
					this.setState({ content: [] });
				});
		}
	}

	calculateFirstRecord() {
		this.setState({ firstRecord: this.state.page * this.state.size + 1 });
	}

	calculateLastRecord() {
		var recordEnd =
			this.state.page * parseInt(this.state.size) +
			parseInt(this.state.size);
		if (recordEnd > this.state.totalItems) {
			this.setState({ lastRecord: this.state.totalItems });
		} else {
			this.setState({ lastRecord: recordEnd });
		}
	}

	handlePageChange = (event) => {
		if (this.state.searchFilter === 'ICCID') {
			this.setState({ searchFilter: 'ALL' })
		}
		this.setState({ page: event.selected });
	};

	componentDidMount() {
		if(this.state.page > 0){
			this.handlePageChange({'selected' : parseInt(this.state.page)})}
		if (this.props.searchFilter !== undefined) {
			this.retrieveProfilesRefresh();
		} else if (!this.state.profileStates.includes(this.props.search)) {
			this.retrieveProfiles();
		}
		if ((this.state.searchFilter === 'ICCID' || this.state.searchFilter === 'ALL') && this.props.searchFilter !== 'ProfileType') {
			this.resetRadioButtons();
			if (this.props.search === '' || this.props.search === undefined) {
				this.resetSearchBar(this.state.searchFilter);
			}
		}
	};

	componentDidUpdate(prevProps, prevState) {
		var newSizeParam = "size=" + this.state.size;
		var newPageParam = "page=" + this.state.page;

		// Handle switching from page 2 on doughnut to all doughnut view
		if (this.state.searchFilter !== this.props.searchFilter && this.state.searchFilter !== prevState.searchFilter){
			this.handlePageChange({'selected' : parseInt(0)})
		}

		// check if state has changed
		if (prevState.size !== this.state.size) {
			this.retrieveProfiles(this.props.searchFilter);
			window.history.replaceState(
				null,
				"",
				"/consumerprofiles?search=" + this.state.search + "&searchFilter=" + this.state.searchFilter + "&" + newSizeParam + "&" + newPageParam
			);
		}

		if (prevState.page !== this.state.page && !this.state.profileStates.includes(this.state.searchFilter)) {
			this.retrieveProfiles();
			window.history.replaceState(
				null,
				"",
				"/consumerprofiles?search=" + this.state.search + "&searchFilter=" + this.state.searchFilter + "&" + newSizeParam + "&" + newPageParam
			);
			document.getElementById("searchBar").placeholder = "Search...";
		}		

		if (prevState.search !== this.state.search) {
			if (this.state.search !== undefined && this.state.search !== null && this.state.search !== '') {
				// Verify that search string is only numeric characters
				if (this.state.searchFilter === "ICCID" && Boolean(this.state.search.match(/^\d+[F]?$/)) === false && this.state.search !== "") {
					this.showAlert("Danger", "Invalid Search", `An ICCID must contain only numeric characters.`);
					window.history.replaceState(
						null,
						"",
						"/consumerprofiles?search=" + this.state.search + "&searchFilter=" + this.state.searchFilter + "&" + newSizeParam + "&" + newPageParam
					);
					document.getElementById("searchBar").placeholder = "Search...";
				}
				else {
					this.retrieveProfiles(this.state.search);
					this.props.history.push(
						"/consumerprofiles?search=" + this.state.search + "&searchFilter=" + this.state.searchFilter + "&" + newSizeParam + "&" + newPageParam
					);
					document.getElementById("searchBar").placeholder = "Search...";
				}
				// Just push url if switching between radio buttons
			} else if (prevState.searchFilter !== this.state.searchFilter && this.state.searchFilter !== 'ALL') {
				this.props.history.push(
					"/consumerprofiles?search=" + this.state.search + "&searchFilter=" + this.state.searchFilter + "&" + newSizeParam + "&" + newPageParam
				);
				document.getElementById("searchBar").placeholder = "Search...";
			} else {
				this.retrieveProfiles();
				this.props.history.push(
					"/consumerprofiles?search=" + this.state.search + "&searchFilter=" + this.state.searchFilter + "&" + newSizeParam + "&" + newPageParam
				);
				document.getElementById("searchBar").placeholder = "Search...";
			}
		}
	}

	handleSizeChange = (event) => {
		this.hideAlert();
		this.setState({ size: event.target.value });
		this.setState({ page: 0 });
		this.calculateFirstRecord();
		this.calculateLastRecord();
	};

	handleSearchBoxClick = () => {
		var searchBar = document.getElementById("searchBar");
		this.setStateByRadioButton();
		this.setState({ search: searchBar.value});
		this.setState({ page: 0 });
		if (searchBar.value === "") {
			this.setState({ search: searchBar.value })
			this.setState({ searchFilter: "ALL" })
		}
	};

	handleSearchBoxChange = (event) => {
		// only send the search when the 'enter' button is pressed to reduce API calls
		if (event.key === "Enter") {
			if (event.target.value === '') {
				this.setState({ search: 'ALL' });
				this.retrieveProfiles('ALL')
				document.getElementById("searchBar").placeholder = "Search...";
			} else {
			this.setStateByRadioButton();
			this.setState({ search: event.target.value });
			this.setState({ page: 0 });}
		}
	};

	setStateByRadioButton() {
		if (document.getElementById('ICCIDRadioButton').checked === true) {
			this.setState({ searchFilter: "ICCID" })
		} else if (document.getElementById('ProfileTypeRadioButton').checked === true) {
			this.setState({ searchFilter: "ProfileType" })
		}
	}

	showAlert = (variant, heading, message) => {
		ReactDOM.render(
			<AlertOperationRequest
				variant={variant}
				heading={heading}
				message={message}
				close={this.hideAlert}
			/>,
			document.getElementById("alert-form")
		);
	};

	hideAlert = () => {
		ReactDOM.render("", document.getElementById("alert-form"));
	};

	resetSearchBar = (type) => {
		this.hideAlert()
		if (type !== "ICCID" && type !== "ProfileType") {
			document.getElementById("searchBar").value = "";
			document.getElementById("searchBar").placeholder = "Search...";
			this.resetRadioButtons();
		} else {
			this.setState({ search: '' });
			this.setState({ searchFilter: `${type}` });
			document.getElementById("searchBar").value = "";
			document.getElementById("searchBar").placeholder = "Search...";
		}
		document.getElementById("searchBar").placeholder = "Search...";

	};

	resetRadioButtons() {
		document.getElementById('ICCIDRadioButton').checked = true
	}

	render() {
		return (
			<>
				<div className="row">
					<div id="alert-banner" className="col-lg-12 d-grid" />
				</div>

				<div className="row">
					<div className="col-lg-9 col-md-8 col-sm-12 d-grid profilesTable">
						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<h6 className="m-0 font-weight-bold text-primary">
									<i className="bi bi-box"></i> My Consumer
									Profiles
								</h6>
							</div>
							<div className="card-body">
								<div id="alert-form" className="col-lg-12 d-grid" />
								<div className="search-responsive">
									<div className="row" style={{ paddingBottom: 8 }}>
										<Search
											value={this.state.search}
											placeholder="Search..."
											handleSearchBoxChange={
												this.handleSearchBoxChange
											}
											handleSearchBoxClick={
												this.handleSearchBoxClick
											}
										/>
									</div>
									<div className="form-check form-check-inline radioButtons">
										<input
											className="form-check-input"
											type="radio"
											name="inlineRadioOptions"
											id="ICCIDRadioButton"
											value="ICCID"
											onChange={() => {
												this.resetSearchBar("ICCID");
											}}
										/>
										<label
											className="form-check-label"
											htmlFor="ICCIDRadioButton"
										>
											ICCID
										</label>
									</div>
									<div className="form-check form-check-inline radioButtons">
										<input
											className="form-check-input"
											type="radio"
											name="inlineRadioOptions"
											id="ProfileTypeRadioButton"
											value="ProfileType"
											onChange={() => {
												this.resetSearchBar("ProfileType");
											}}
										/>
										<label
											className="form-check-label"
											data-cy="ProfileType"
										>
											Profile Type
										</label>

									</div>
									<div className="form-check form-check-inline radioButtons" style={{ float: "right" }}>
										<label id="profile-state-label"
											className="form-check-label"
											   data-cy="profileType"
										>
										</label>

									</div>

								</div>
								<div className="table-responsive">
									<ProfileTable content={this.state.content} oktaAuth={this.state.oktaAuth} getAllResponse={this.state.getAllResponse} stateColors={this.state.stateColors} />
								</div>
								<hr />
								<div className="table-bottom-bar">
									<div className="row">
										<ShowItems
											size={this.state.size}
											handleSizeChange={this.handleSizeChange}
										/>
										<div
											className={
												"col-sm-12 col-md-6 react-paginate" +
												this.state.disabled
											}
										>
											<ReactPaginate
												previousLabel={"Previous"}
												nextLabel={"Next"}
												breakLabel={"..."}
												pageCount={this.state.totalPages}
												marginPagesDisplayed={1}
												pageRangeDisplayed={3}
												onPageChange={this.handlePageChange}
												renderOnZeroPageCount={null}
												containerClassName={"pagination"}
												pageClassName={"page-item"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
												previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
												forcePage={this.state.page}
											/>
										</div>
										<div className="record-count">
											Showing items {this.state.firstRecord} -{" "}
											{this.state.lastRecord} of{" "}
											{this.state.totalItems}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid col-lg-3 col-md-4 col-sm-12 d-grid profileStateContainer">
						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<h6 className="m-0 font-weight-bold text-primary">
									<i className="bi bi-box"></i> Profile States
								</h6>
							</div>

							<div className="card-body profile-states-card">
								<ProfileStates
									oktaAuth={this.state.oktaAuth}
									searchStates={(profileType) => { this.searchByProfileState(profileType) }}
									getAll={(profileState) => this.retrieveProfiles(profileState)}
									resetSearchBar={(profileState) => this.resetSearchBar(profileState)}
									handlePageChange={(handlePageChange) => this.handlePageChange(handlePageChange)}
									search={this.state.search}
									stateColors={this.state.stateColors}
									page={this.state.page}
									size={this.state.size}
								></ProfileStates>
							</div>
							<div className="table-bottom-bar">
								<div className="row"></div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withOktaAuth(withRouter(ProfileCard));