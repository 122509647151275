import React, { Component } from "react";

class CampaignItem extends Component {
    formatDate(timestamp){
        var time = new Date(timestamp);
        return time.toLocaleString('en-GB', { localeMatcher: "best fit" , dateStyle: "long"});
    }

    formatTime(timestamp){
        var time = new Date(timestamp);
        return time.toLocaleString('en-GB', { localeMatcher: "best fit", timeStyle: "medium"});
    }

    renderStatus(status){
        var style = "text-dark bg-light";

        if (status === "IN_PROGRESS"){
            style = "text-light badge-primary"

        }else if (status === "COMPLETED"){
            style = "text-light badge-success"

        }else if (status === "ERROR"){
            style = "text-light badge-danger"
        }

        return (
            <span className={"badge rounded-pill" + style}>{status}</span>
        )
    }

    renderType(type){
        var icon = "bi bi-question-circle";
        var title = type;

        if (type === "audit-eis"){
            icon = "bi bi-bug";
            title = "Audit EIS";

        }else if (type === "download-profile"){
            icon = "bi bi-cloud-arrow-down";
            title = "Download Profile";
        
        }else if (type === "enable-profile"){
            icon = "bi bi-file-check";
            title = "Enable Profile";
        
        }else if (type === "disable-profile"){
            icon = "bi bi-file-minus";
            title = "Disable Profile";
        
        }else if (type === "delete-profile"){
            icon = "bi bi-file-excel";
            title = "Delete Profile";

        }else if(type === "update-imsi"){
            icon = "bi bi-pencil-square";
            title = "Update IMSI";

        }else if(type === "update-msisdn"){
            icon = "bi bi-pencil-square";
            title = "Update MSISDN";

        }else if(type === "set-fallback-attribute"){
            icon = "bi bi-arrow-counterclockwise";
            title = "Set Fallback Attribute";
        }else if(type === "generate-qr"){
            icon = "bi bi-qr-code";
            title = "Generate QR";
        }

        return <><i className={icon}></i><span> {title}</span></>;
    }

    renderRowColour(status){
        let rowColor = "";
        if (this.props.campaign.status === "COMPLETED"){
            rowColor = "success";

        }else if (this.props.campaign.status === "ERROR"){
            rowColor = "danger";
        }

        return rowColor;
    }
    
    render(){
        //Removes 'consumer-' prefix from the created time present only on consumer campaigns
        if(this.props.campaign.created.startsWith("consumer")){
            this.props.campaign.created = this.props.campaign.created.replace(/^consumer-/,'')
        }
        let cretedTimestamp = this.formatDate(this.props.campaign.created) + " at " +  this.formatTime(this.props.campaign.created);

        let rowColor = this.renderRowColour(this.props.campaign.status);

        return <tr className={'table-' + rowColor}>
            <td data-cy="campaign-name" className="click-row campaign-name">
                <a href={this.props.campaign.type === 'generate-qr' ? '/qrCampaigns?id=' + this.props.campaign.id : '/campaigns?id=' + this.props.campaign.id}>
                    {this.props.campaign.name}
                </a>
            </td>
            <td data-cy="campaign-created-time">{cretedTimestamp}</td>
            <td data-cy="campaign-type">{this.renderType(this.props.campaign.type)}</td>
            <td data-cy="campaign-author">{this.props.campaign.author}</td>
            <td data-cy="campaign-status">{this.renderStatus(this.props.campaign.status)}</td>
        </tr>
    }
};
    
export default CampaignItem;
