import React, {Component } from 'react';
import { Line } from "react-chartjs-2";
import './count-per-month-card.css'

import MonthlyGraphService from '../../services/MonthlyGraphService';

import { withOktaAuth } from '@okta/okta-react';

const options = {
    plugins:{
        legend: {
            display: false
        },
    },
    scales: {
        x: {
            grid:{
                display:false
            }
        },
        y: {
            ticks: {
                beginAtZero: true,
                callback: function(value) {if (value % 1 === 0) {return value;}}
            }
        }
    },
    elements: {
        line: {
            fill: true
        }
    }
};

class CountPerMonthCard extends Component {
    constructor(props) {
        super(props);   
        
        this.state = {
            height: 100,
            colWidth: this.configureColWidth(this.props.colWidth),
            counts:[],
            oktaAuth: this.props.oktaAuth,
            months: this.props.months
        };
    }

    configureColWidth(colWidthFromProps){
        let colWidth = 6; // default value
        if (colWidthFromProps !== undefined){
            colWidth = colWidthFromProps;
        }
        return colWidth;
    }

    configureChartData(chartLabels, chartData, title, colour){
        let color = "rgba(78, 115, 223, 1)";
        let backgroundColor = "rgba(78, 115, 223, 0.5)";

        if (this.props.color === "success"){
            color = "rgba(28, 200, 138, 1)";
            backgroundColor = "rgba(28, 200, 138, 0.05)";
        } else if (this.props.color === "info"){
            color = "rgba(91, 192, 222, 1)";
            backgroundColor = "rgba(91, 192, 222, 0.5)";
        }

        return {
            labels: chartLabels,
            datasets: [
                {
                    label: title,
                    lineTension: 0,
                    backgroundColor: backgroundColor,
                    borderColor: color,
                    pointRadius: 5,
                    pointBackgroundColor: "#f5f8fd",
                    pointBorderColor: color,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: color,
                    pointHitRadius: 10,
                    pointBorderWidth: 1,
                    data: chartData,
                }
            ]
        };
    }

    prepareGraphData(monthsData){
        // get current month
        var date = new Date();
        var currentMonth = date.getMonth()+1;

        // months labels
        let monthsLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        // fetched counts
        let monthsCounts = MonthlyGraphService.convertMapToArray(monthsData); 

        // reorganise data based on current month
        var m;
        var c;
       
        if(currentMonth === 1){
             c = MonthlyGraphService.reorderCountsForJanuary(currentMonth, monthsCounts);
        } else {
            c = MonthlyGraphService.reorderByCurrentMonth(currentMonth, monthsCounts);
        }

        m = MonthlyGraphService.reorderByCurrentMonth(currentMonth, monthsLabels);
        return {
            labels: m,
            values: c
        }
    }

    render(){
        var data = this.prepareGraphData(this.props.months)
        let chartData1 = this.configureChartData(data.labels, data.values, this.props.title, this.props.color);

        return <div className={'d-grid col-xl-' + this.state.colWidth}>
            <div className="card shadow mb-4 h-100">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className={"m-0 font-weight-bold text-" + this.props.color}>{this.props.title} (per month)</h6>
                </div>
                <div className="card-body">
                    <Line data-tid={this.props.title} data={chartData1} options={options} height={this.state.height}/>
                </div>
            </div>
        </div>;
    }
}

export default withOktaAuth(CountPerMonthCard);
