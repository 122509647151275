import http from "../api/http-admin-portal";
import HttpUtil from '../api/http-util';

// #####
// Admin Portal - Features API Integrations
// #####
const listFeature = (oktaAuth) => {
  const config = HttpUtil.getAuthHeader(oktaAuth);
  return http.get("/admin/features", config );
};

const createNewFeature = (data, oktaAuth) => {
  const config = HttpUtil.getAuthHeader(oktaAuth);
  return http.post("/admin/features", data, config);
};

const updateNewFeature = (data, oktaAuth) => {
  const config = HttpUtil.getAuthHeader(oktaAuth);
  const id = data.id;
  const url = "/admin/features/" + id
  delete data.id

  return http.patch(url, data, config);
};

// #####
// Admin Portal - Applications API Integrations
// #####

const listApplications = (oktaAuth) => {
  const config = HttpUtil.getAuthHeader(oktaAuth);
  return http.get("/admin/applications", config );
};

// #####
// Admin Portal - Customers API Integrations
// #####

const listCustomers = (oktaAuth) => {
  const config = HttpUtil.getAuthHeader(oktaAuth);
  return http.get("/admin/customers", config );
};

const getCustomer = (oktaAuth, customer_id) => {
  const config = HttpUtil.getAuthHeader(oktaAuth);
  return http.get("/admin/customers/" + customer_id, config);
};

const getCustomerIam = (oktaAuth, customer_id) => {
  const config = HttpUtil.getAuthHeader(oktaAuth);
  return http.get("/admin/customers/" + customer_id + "/iam", config);
};

const getCustomerUsers = (oktaAuth, customer_id) => {
  const config = HttpUtil.getAuthHeader(oktaAuth);
  return http.get("/admin/customers/" + customer_id + "/users", config);
};

// #####
// Admin Portal - Utils Functions
// #####

const sortByField = (array, field) => {
  return array.sort((a, b) => a[field].localeCompare(b[field]))
}

// #####
// Admin Portal - Export Functions
// #####

const functions = {
  listFeature,
  createNewFeature,
  updateNewFeature,
  listApplications,
  listCustomers,
  getCustomer,
  getCustomerIam,
  getCustomerUsers,
  sortByField
};

export default functions;
