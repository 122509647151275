import React, { Component } from "react";
import {OverlayTrigger, Tooltip, Spinner} from 'react-bootstrap';

import InFlightService from '../../../../services/InFlightService';

class InflightCard extends Component {

    renderOperationIcon(action){
        if (action === "ES2-DownloadProfile"){
            return "bi-cloud-arrow-down";
        } else if (action === "ES4-EnableProfile"){
            return "bi-file-check";
        } else if (action === "ES4-DisableProfile"){
            return "bi-file-minus";
        } else if (action === "ES4-DeleteProfile"){
            return "bi-file-excel";
        } else if (action === "AUDIT_EIS"){
            return "bi-bug";
        }
    }
    
    formatTime(timestamp){
        var time = new Date(timestamp);
        return time.toLocaleString('en-GB', { localeMatcher: "best fit", timeStyle: "long"});
    }

    renderVP(vp){
        let unit = vp;

        if (vp < 60){
            unit = unit + " seconds";
        } else if (vp > 60){
            unit = parseFloat((vp/60)).toFixed(0) + " minutes";
        }

        return unit;
   }

    renderNoInFlight(){
        return (
            <div className="col-12">
                <div className="">
                    <div className="alert alert-warning" role="alert" data-cy="no-inflight">
                        <p><i className="bi bi-exclamation-triangle" /> No in-flight operations detected.</p>
                    </div>
                </div>
            </div>
        )
    }

    renderLoadingInFlight(){
        return (
            <div className="col-12">
                <div className="loadingSpinner">
                    <Spinner animation="border" size="sm" />
                </div>
            </div>
        )
    }



    renderInFlight(){
        let operationIcon = this.renderOperationIcon(this.props.tasks.procedureName);

        let startTimestamp = this.formatTime(this.props.tasks.createdTime);
        let expiryTimestamp = this.formatTime(this.props.tasks.expiryTime);

        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <p data-tid='In Flight Process'><strong>In-Flight Process:</strong> <i className={"bi " + operationIcon}></i> {this.props.tasks.procedureName}</p>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-left">Time to Live</Tooltip>}>
                            <strong><span><i className={"bi bi-stopwatch"}></i> TTL: </span></strong> 
                        </OverlayTrigger>
                        {this.renderVP(this.props.tasks.validityPeriod)}
                    </div>
                    <div className="col-6 rightAlign">
                        <p><strong>Started:</strong> {startTimestamp}</p>
                        <p><strong>Expiry:</strong> {expiryTimestamp}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p><strong>Stages:</strong></p>
                        {this.renderStagesTable(this.props.tasks.procedureName)}
                    </div>
                </div>
            </div>
        );
    }

    renderStagesTable(procedureName){
        return (
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.tasks.stages && this.props.tasks.stages.map((stage, index) => (
                                this.renderStagesTableRow(index, procedureName, stage)
                            ))
                        }
                        <tr>
                            <td className="align-middle"></td>
                            <td className="align-middle"><Spinner animation="border" size="sm" /></td>
                            <td className="align-middle"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    renderStagesTableRow(index, procedureName, stage){
        let stageTranslation = InFlightService.translateOperationProcedureName(procedureName, stage.name);

        if (stageTranslation !== undefined){
            let tooltip = (<Tooltip id="tooltip-left">{stageTranslation.en.explanation}</Tooltip>);
            return (
                <tr key={index}>
                    <td className="align-middle">{this.formatTime(stage.TIME_COMPLETED)}</td>
                    <OverlayTrigger placement="bottom" overlay={tooltip}>
                        <td className="align-middle">
                            <span>{stageTranslation.en.translation}</span>
                        </td>
                    </OverlayTrigger >
                    <td className="align-middle"><span className="badge bg-success text-light">{stage.result}</span></td>
                </tr>
            ) 
        }
        return;
    }

    render(){ 
        if (this.props.tasks === undefined){
            return this.renderNoInFlight();

        }else if (this.props.tasks === null){
            return this.renderLoadingInFlight();

        }else{
            return this.renderInFlight();
        }
    }
};
    
export default InflightCard;    