import React, { Component } from 'react';
import './sidebar-admin.css'
import { withOktaAuth } from '@okta/okta-react';

class Sidebar extends Component {

  hasRole(roleName){
    const claims = this.props?.authState?.accessToken?.claims;
    if (claims) {
      const claimKeys = Object.keys(claims);
      const roleKey = claimKeys?.find(key => /roles_/.test(key));
      const roles = claims[roleKey];

      let role = "";
      roles?.forEach(element => { 
        if (element.includes(roleName)){
          role = element}
        }
      );
      if (role) return true;
    }
    return false;
  }

  render() {
    let { toggled, active } = this.props;
    active = active.toLowerCase();

    return (
      <ul className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'} id="accordionSidebar">

        {/* <!-- Sidebar - Brand --> */}
        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard">
          <div className="sidebar-brand-icon ">
            <img id="kigenWhiteLogo" src="img/kigen-white-logo.svg" alt="" />
          </div>
          {/* <div className="sidebar-brand-text mx-3">Kigen IoT<sup>1.0</sup></div> */}
        </a>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Nav Item - Dashboard --> */}
        <li className={active === "home" ? 'nav-item active' : 'nav-item'}>
          <a className="nav-link" href="/dashboard">
            <i className="bi bi-arrow-return-left"></i>
            <span>Return to KSM</span></a>
        </li>

        {/* <!-- Divider --> */}
        {/* <hr className="sidebar-divider" />

        <li className={active === "overview" ? 'nav-item active' : 'nav-item'}>
          <a className="nav-link" href="/admin">
            <i className="bi bi-house"></i>
            <span>Overview</span></a>
        </li> */}

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">
          Manage
        </div>

        {/* <!-- Nav Item - Dashboard --> */}
        <li className={active === "customers" ? 'nav-item active' : 'nav-item'}>
          <a className="nav-link" href="/admin-customers">
            <i className="bi bi-people"></i>
            <span>Customers</span></a>
        </li>
        <li className={active === "environments" ? 'nav-item active' : 'nav-item'}>
          <a className="nav-link" href="/admin-environments">
            <i className="bi bi-diagram-2"></i>
            <span>Environment</span></a>
        </li>
        <li className={active === "features" ? 'nav-item active' : 'nav-item'}>
          <a className="nav-link" href="/admin-features">
            <i className="bi bi-toggles2"></i>
            <span>Features Toggles</span></a>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />
      </ul>)
  }
}

export default withOktaAuth(Sidebar);
