import React, { Component } from "react";
import { Spinner, Offcanvas } from 'react-bootstrap';
import ConsumerProfileInventoryService from "../../../services/ConsumerProfileInventoryService";
import ConsumerOperationsService from "../../../services/ConsumerOperationsService";
import HttpUtil from "../../../api/http-util";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import ProfileItem from './ProfileItem';
import './tableButton.css'
import ConsumerSingleOperationsButtons from "./ConsumerSingleOperationsButtons";


class ProfileTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowIsActive: undefined,
      show: false,
      title: null,
      titleValue: null,
      oktaAuth: this.props.oktaAuth,
      data: {},
      titleColor: null,
      qrCode: null,
      campaignIsRunning :false
    }
  }

  renderTableHeaders() {
    return (
      <thead>
        <tr>
          <th>Iccid</th>
          <th>Mno Id</th>
          <th>Profile Type</th>
          <th>State</th>
        </tr>
      </thead>
    )
  }

  // Handles the response from getting the profile counts
  handleProfileResponse(object) {
    var content = this.prepareMetadata(object?.data?._embedded?.profiles[0])

    if (content) {
      this.setState({ data: content })
    }
  }

  getMetadataByICCID(iccid) {
    const config = HttpUtil.getHttpConfig(
      null,
      this.state.oktaAuth.getAccessToken()
    );
    ConsumerProfileInventoryService.searchbyIccid(iccid, config)
      .then((response) => {
        if(response?.data?._embedded?.profiles[0].state.toUpperCase() === "RELEASED"){
          this.fetchActivationCode(response)
        } else this.handleProfileResponse(response, null)
      })
      .catch((e) => {
        console.log(
          "An error occurred trying to get metadata for ICCID.",
          e
        );
      });
  }

  fetchActivationCode(metadata) {
    const config = HttpUtil.getHttpConfig(
      null,
      this.state.oktaAuth.getAccessToken()
    );
    ConsumerOperationsService.fetchActivationCode(metadata?.data?._embedded?.profiles[0].iccid, config)
      .then((response) => {
        this.setState({qrCode: response})
        this.handleProfileResponse(metadata, response)
      }).catch((e) => {
        console.log("An Error Occured retrieving QR Code.",
          e)
        this.setState({qrCode: {"data":{"activationCode":"An Error Occured retrieving QR Code"}}})
        this.handleProfileResponse(metadata, null)
      });
  }

  prepareMetadata(object) {
    var content = {}
    let imageFormat;
    let iconType;
    let icon;

    if (object?.iccid) {

      if (object?.profileMetaData?.iconType) {
        if (object?.profileMetaData?.iconType === 0) {
          iconType = 'JPEG';
          imageFormat = 'data:image/jpg;base64';
        } else if (object?.profileMetaData?.iconType === 1) {
          iconType = 'PNG';
          imageFormat = 'data:image/png;base64';
        }
      }

      if (imageFormat) {
        icon = <img data-cy="icon" src={`${imageFormat}, ${object?.profileMetaData?.icon}`} alt="ICCID: " style={{ maxHeight: '30px', maxwidth: '30px' }}></img>
      } else {
        icon = 'N/A'
        iconType = 'N/A'
      }

      this.checkCampaignOfThisProfileTypeIsRunning(object);

      content['ICCID'] = <span data-cy="metadata-iccid">{object?.iccid}</span>
      content['Profile Type'] = <span data-cy="metadata-profileType">{object?.profileType}</span>
      content['Profile State'] = <span data-cy="metadata-profileState" className="font-weight-bold" style={{ color: this.props.stateColors[object?.state.toUpperCase()] }}>{object?.state}</span>
      content['Mobile Network Operator ID'] = <span data-cy="metadata-mnoid">{object?.mnoId}</span>
      content['Service Provider Name'] = <span data-cy="metadata-serviceProvider">{object?.profileMetaData?.operatorServiceProviderName}</span>
      content['Profile Name'] = <span data-cy="metadata-profileName">{object?.profileMetaData?.profileName}</span>
      content['Profile Class'] = <span data-cy="metadata-profileClass">{object?.profileMetaData?.profileClass}</span>
      content['Icon'] = <span data-cy="metadata-icon">{icon}</span>
      content['Icon Type'] = <span data-cy="metadata-iconType">{iconType}</span>
      content['MccMnc'] = <span data-cy="metadata-mccmnc">{object?.profileMetaData?.profileOwner?.mccMnc}</span>
      if (this.state.qrCode) {
        content['Activation Code'] = <span data-cy="metadata-actCode">{this.state.qrCode?.data?.activationCode}</span>
      }

      for (const [key, value] of Object.entries(content)) {
        if (value === null || value === undefined || value === '') {
          content[key] = 'not-set'
        }
      }

      this.setState({ titleColor: this.props.stateColors[object?.state.toUpperCase()] })
    }
    return content
  }


  renderEmptyTable(isLoading) {
    let loadingContent = <td colSpan="8"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
    if (isLoading) {
      loadingContent = <td colSpan="8"><Spinner animation="border" size="sm" /></td>;
    }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          {this.renderTableHeaders()}
          <tbody>
            <tr>
              {loadingContent}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  handleClose = () => {
    window.history.replaceState(
      null,
      "",
      "/consumerprofiles" + this.props.location.search
    );
    this.setState({ show: false, rowIsActive: undefined });
  };

  generateModalTableData() {
    let content = []
    for (const [key, value] of Object.entries(this.state.data)) {
      content.push(
        <tr key={key}>
          <td className="font-weight-bold">{key}</td>
          <td >{value}</td>
        </tr>)
    }
    return content
  }

  checkCampaignOfThisProfileTypeIsRunning(object){
    ConsumerProfileInventoryService.checkCampaignOfThisProfileTypeIsRunning(object?.profileType,HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken()))
        .then((response) =>
            this.setState({"campaignIsRunning": response.data}))
        .catch((e) => {
          console.log(
              "An error occurred trying to checkCampaignOfThisProfileTypeIsRunning",
              e
          );
        })
  }


  renderTable() {

    let content = this.generateModalTableData()
    return (

      <div className="table-responsive">
        <Offcanvas id={"offCanvas"} show={this.state.show} onHide={e => this.handleClose()} placement="end">
          <Offcanvas.Header closeButton></Offcanvas.Header>

          <Offcanvas.Body>
            <table className={'table table-striped table-hover'} id="qrcode-table">
              <thead>
                <tr>
                  <th scope='colgroup' style={{ backgroundColor: (this.state.titleColor != null ? this.state.titleColor : "#c5c8d1"), color: '#FFFFFF' }}>Consumer single operations</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <ConsumerSingleOperationsButtons name="" color="info" iccid={this.state.titleValue} campaignIsRunning={this.state.campaignIsRunning} state={this.state.data} oktaAuth={this.state.oktaAuth} />
                </td>
              </tr>
              </tbody>
            </table>
            <table className={'table table-striped table-hover'} id="metadata-table">
              <thead data-cy="details-header" style={{ backgroundColor: (this.state.titleColor != null ? this.state.titleColor : "#c5c8d1"), color: '#FFFFFF' }}>
                <tr>
                  <th width='40%'>
                    Profile Data
                  </th>
                  <th width='60%'>Details</th>
                </tr>
              </thead>
              <tbody>
                {content}
              </tbody>
            </table>
            <div style={{display:`${this.state.qrCode === null ? "none" : ''}`}}>
                <div className="d-flex justify-content-center">
                  <p>
                      <img data-cy="qr-code" style={{height:`110px`, display:`${this?.state?.qrCode?.data?.qrCode ? '' : 'none'}`}}src={this?.state?.qrCode?.data?.qrCode} alt=""/>
                  </p>
                </div>
                </div>
          </Offcanvas.Body>
        </Offcanvas>

        <table className="table table-striped table-hover" data-tid="profile-table" id="profile-table">
          {this.renderTableHeaders()}
          <tbody>
            {
              this.props.content && this.props.content.map((profile, index) => (
                <ProfileItem profile={profile} key={index} position={index} onClick={(id, iccid) => { this.changeColor(id, iccid) }} rowIsActive={this.state.rowIsActive} stateColors={this.props.stateColors} />
              ))
            }
          </tbody>
        </table>
      </div>

    )
  }

  changeColor(id, iccid) {
    if (this.state.rowIsActive === id) {
      this.setState({
        qrCode: null
      });

      window.history.replaceState(
        null,
        "",
        "/consumerprofiles" + this.props.location.search
      );
      this.handleClose();
    } else {
      this.setState({ show: true });
      this.setState({ titleValue: iccid });
      if (this.state.rowIsActive === undefined) {
        this.setState({
          qrCode: null
        });
        this.setState({
          rowIsActive: id,
        });
        this.getMetadataByICCID(iccid)
        this.handleProfileResponse(iccid)
        window.history.replaceState(
          null,
          "",
          "/consumerprofiles?search=" + iccid + "&searchFilter=ICCID"
        );
      }
      else if (this.state.rowIsActive !== id) {
        var element = document.getElementById(this.state.rowIsActive)
        element?.classList?.remove('tractive')
        if(document.getElementById('closeModalQR')){
          document.getElementById('closeModalQR').click()
        }
        window.history.replaceState(
            null,
            "",
            "/consumerprofiles?search=" + iccid + "&searchFilter=ICCID"
        );
        this.setState({
          qrCode: null
        });
        this.setState({
          rowIsActive: id
        });
        this.getMetadataByICCID(iccid)
        this.handleProfileResponse(iccid)
      }
    }
  }

  render() {
    if (this.props.content === null) {
      return this.renderEmptyTable(true)

    } else if (this.props.content.length === 0) {
      return this.renderEmptyTable(false);

    } else {
      return this.renderTable();
    }
  }
}

export default withOktaAuth(withRouter(ProfileTable));