import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// footer
import LoginForm from '../../components/LoginForm';

// okta
import { useOktaAuth } from '@okta/okta-react';

const Login = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const freshServiceWidget = document.getElementById('freshwidget-button');
    if(freshServiceWidget) {
      freshServiceWidget.setAttribute('style', 'display:none !important');
    }

    if(!authState || !authState.isAuthenticated) {
      // when user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      }).catch((error) => {
        // in case of okta error clear token manager
        oktaAuth.tokenManager.clear();
        history.push('/');

      });
    }
  }, [authState, oktaAuth]); // update if authState changes

  const history = useHistory();
  const DASHBOARD_PATH = window.location.origin.includes('localhost') ? '/dashboard' : `${process.env.REACT_APP_PULSE_URL}/dashboard`;

  if(!authState){
    return (
        <div>Loading...</div>
    );
  }


  return (
      <div className="bg-gradient-primary" style={{height: `100%`}}>
        {/* <!-- Page Wrapper --> */}
        <div className="container">
          { authState.isAuthenticated && !userInfo
              && <div>Checking Credentials...</div>}

          {authState.isAuthenticated && userInfo
              && (
                  <div>
                    {window.location.href = DASHBOARD_PATH}
                  </div>
              )}

          {!authState.isAuthenticated
              && (
                  <div className="container">
                    <LoginForm />
                  </div>
              )}
        </div>
        {/* <!-- End of Page Wrapper --> */}
      </div>


  );
};

export default Login;
