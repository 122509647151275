import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./campaigns.css"
import HttpUtil from '../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';
import CampaignService from '../../../services/CampaignService';

// import Search from '../../common/search/Search';
import CampaignTable from '../../Campaign/list-view/CampaignTable';
import ShowItems from '../../common/table/ShowItems';
import Pagination from '../../common/table/PaginationPreviousNext';

import CreateQRCampaign from "../create-qr-campaign/CreateQRCampaign";

class CampaignList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      size: this.props.size,
      previous: this.props.previous,
      next: this.props.next,
      // search: this.props.search,
      oktaAuth: this.props.oktaAuth,
    };
  }

  handleAPIResponse = (response) => {
    // set pagination cursor
    this.setState({previous: response.data.previous});
    this.setState({next: response.data.next});

    // set content
    this.setState({content: response.data.Items});
  }

  retrieveCampaigns = (isNext, overrideParams) => {
    var params = overrideParams;

    if (overrideParams == null){
      if (isNext === false){
        params = CampaignService.getRequestParams(this.state.size, this.state.previous, null, this.state.search);
      }else{
        params = CampaignService.getRequestParams(this.state.size, null, this.state.next, this.state.search);
      }
    }

    const config = HttpUtil.getHttpConfig(params, this.state.oktaAuth.getAccessToken());
    //This needs to be changed to only show QR campaigns
    CampaignService.getAllQrCampaigns(config)
      .then((response) => this.handleAPIResponse(response)).catch((e) => {
        this.setState({content: []});
    });
  };

  componentDidMount() {
    this.retrieveCampaigns(true, null);
  }

  componentDidUpdate(prevProps, prevState) {
    // handle
    if (prevState.size !== this.state.size){
      var newSizeParam = "size=" + this.state.size;

      var params = CampaignService.getRequestParams(this.state.size, null, null, this.state.search);
      this.retrieveCampaigns(true, params);

      window.history.replaceState(null, '', 'qrCampaigns?' + newSizeParam);
    }
  }

  handlePreviousPageChange = (previous) => {
    var newSizeParam = "size=" + this.state.size;
    var newPreviousParam = "&previous=" + previous;

    this.retrieveCampaigns(false, null);
    window.history.replaceState(null, '', 'qrCampaigns?' + newSizeParam + newPreviousParam);
  };

  handleNextPageChange = (next) => {
    var newSizeParam = "size=" + this.state.size;
    var newNextParam = "&next=" + next;

    this.retrieveCampaigns(true, null);
    window.history.replaceState(null, '', 'qrCampaigns?' + newSizeParam + newNextParam);
  };

  handleSizeChange = (event) => {
    // updating this value is handled in `componentDidUpdate`
    this.setState({size: event.target.value});
  };

  // handleSearchBoxChange = (event) => {
  //   // only send the search when the 'enter' button is pressed to reduce API calls
  //   if(event.key === 'Enter'){
  //     this.setState({search: event.target.value});
  //     this.setState({page: 0});
  //   }
  // }

  render() {
    return (
      <div className="row">
        <div id="campaignsButtons" className="col-lg-12">
          <CreateQRCampaign title="New Campaign"/>
        </div>

        <div className="col-lg-12 d-grid">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary"><i className="bi bi-cpu"></i> My Campaigns</h6>
            </div>
            <div className="card-body">
                <div className="search-responsive">
                  <div className="row">
                    {/* REMOVE SEARCH FOR NOW
                    <Search
                      value={this.state.search}
                      placeholder="Search for campaign ..."
                      handleSearchBoxChange={this.handleSearchBoxChange}/> */}
                  </div>
                </div>
                <div className="table-responsive">
                    <CampaignTable content={this.state.content}/>
                </div>
                <hr/>
                <div className="table-bottom-bar">
                  <div className="row">
                    <ShowItems size={this.state.size} handleSizeChange={this.handleSizeChange}/>
                    <Pagination
                      handlePreviousPageChange={this.handlePreviousPageChange}
                      handleNextPageChange={this.handleNextPageChange}
                      previous={this.state.previous}
                      next={this.state.next} />
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withOktaAuth(withRouter(CampaignList));
