import React, { Component} from "react";
import { withRouter } from "react-router-dom";
import EisService from '../../../services/EisService';
import './tableButton.css';
import HttpUtil from '../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';
import AlertOperationRequest from "../../Campaign/single-view/CampaignOperations/AlertOperationRequest.js";
import ReactDOM from 'react-dom'

import DateToggle from "../../Date/DateToggle"
import ColdStorageInventoryService from "../../../services/ColdStorageInventoryService";

class ProfileItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
          oktaAuth: this.props.oktaAuth
        }
      }
    
      
    redirectToEid(iccid) {
      const headerConfig = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());

      EisService.getEidByIccid(iccid, headerConfig).then((value) => {
          let eidValue = value.data._id
          window.open("/eis?eid=" + eidValue, "_self")
        }).catch((e) => {
          let jsonResponse = JSON.stringify(e)
          if (jsonResponse.includes("Request failed with status code 401")) {
            this.showAlert("Danger", "Insufficient Permissions", `You do not have access to view this eUICC.`);
        } else {
          console.log("Error has occurred", e);
    }});   
    }

    showAlert = (variant, heading, message) => {
      ReactDOM.render(
        <AlertOperationRequest  variant={variant} heading={heading} message={message} close={this.hideAlert}/>,
        document.getElementById('alert-form')
      );
      setTimeout(() => this.hideAlert(), 5000);
    };
  
    hideAlert = () => {
        ReactDOM.render("", document.getElementById('alert-form'));
    };
    
    activateEIS = (eid, config) => {      
      console.log("activateEIS here")
      ColdStorageInventoryService.activate(eid, config)
				.then((response) => {
          this.showAlert("success", "EIS Successful Activated", response.data._id + ` was moved from Cold Storage!`);

          // refresh window if successful to reflect the change
          // setTimeout(() => window.location.reload(), 1000);
          setTimeout(() => {
            this.props.history.push("/storage");
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log("error", error);
          this.showAlert("danger", "EIS Not Activated", eid + ` was not Activated from Cold Storage!`);
        });
    }

    createEidActivationModal = (eid, config) => {
      return (
        <>
          <td data-tid="btnActivateEid">
            <button  type="button" className="btn btn-primary btn-cold-activate" data-toggle="modal" data-target={'#activateModal' + this.props.profile._id} >
              <i className="bi bi-box-arrow-right"/>
            </button>

            {/* THIS MIGHT NEED TO BE MOVED */}
            <div className="modal fade" id={'activateModal' + eid} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Confirm eSIM move to M2M RSP!</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body fontSizeTable">
                    <p>Please confirm eSIM move to M2M RSP for the following EID:</p>
                    <p className="mono"><b id={'activateMessage' + eid}>{eid}</b></p>
                    <p className="text-danger">This operation can not be undone!</p>
                  </div>
                  <div className="modal-footer">
                    <button id={'cancelActivateBtn' + eid} type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button id={'confirmActivateBtn' + eid} type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => this.activateEIS(eid, config)}>Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </>
      );
    }

    createIccidRowCell = (profileInfo) => {
      // split iccid into divs
      let iccidDivs = [];

      let index = 0;
      profileInfo.forEach(info => {
        iccidDivs.push (<div key={index} >{info.iccid}</div>)
        index += 1
      });

      return (
        <td className="text-monospace" data-cy="cold-storage-iccid">{iccidDivs}</td>
      )
    }

    render(){ 
        return (
          <tr className={'table'}>
            <td data-cy="cold-storage-eid" className="align-middle text-monospace">{this.props.profile._id}</td>
            {this.createIccidRowCell(this.props.profile.profileInfo)}
            <DateToggle notTable={false} value={this.props.profile.createdDate} type={this.props.dateToggleFormat} toggleFunction={this.props.toggleFunction} dateId={`cold-storage-created-${this.props.tableIndex}`}/>
            {this.createEidActivationModal(this.props.profile._id, this.props.config)}
          </tr>
        )
    }
};
    
export default withOktaAuth(withRouter(ProfileItem));