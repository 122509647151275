import React, { Component } from 'react';
import {Alert} from 'react-bootstrap';
import "./AlertOperationRequest.css";

class AlertDismissibleExample extends Component {
    render() {
        let headerHTML = "";

        if(this.props.heading){
            headerHTML = <Alert.Heading>{this.props.heading}</Alert.Heading>;
        }
        return (
            <Alert variant={this.props.variant} data-tid={this.props.heading} onClose={() => this.props.close()} dismissible>
                {headerHTML}
                <p>{this.props.message}</p>
            </Alert>
        );
    }
}

export default AlertDismissibleExample;