const getAuthHeader = (accessToken) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  };
};

const getHttpConfig = (params, accessToken) => {
  const httpHeaders = getAuthHeader(accessToken);
  const paramConfig = { params: params };
  return {...httpHeaders, ...paramConfig};
};

const functions = {
  getAuthHeader,
  getHttpConfig,
};

export default functions;
