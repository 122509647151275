import http from "../api/http-common";

const generateActivationCode = (data, config) => {
  let params = `?qr=true&iccid=${data.iccid}`;
  if (data.confirmationCode){
   params= params+`&confirmationCode=${data.confirmationCode}`
  }
  if (data.eid){
    params = params+`&eid=${data.eid}`
  }
  config.headers["fromGui"] = 'true';
  console.log("config here", config)
  return  http.get("/consumer/operations/generate/activation/code"+params, config);
};

const fetchActivationCode = (iccid, config) => {
  return  http.get(`/consumer/operations/fetch/activation/code?iccid=${iccid}`, config);
};

const shareActivationCode = (data,config) =>{
  let x =  http.post(`/consumer/operations/share/activation/code?email=${data.email}&iccid=${data.iccid}`,{}, config)
  return x;
}



const functions = {
  generateActivationCode,
  fetchActivationCode,
  shareActivationCode
};

export default functions;
