import React, { Component } from 'react';
import QueryString from 'query-string';

//Navigation
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Footer from '../../components/Footer';

import CampaignSingle from '../../components/Campaign/single-view/CampaignSingle'
import CampaignList from '../../components/Campaign/list-view/CampaignList'

import { withOktaAuth } from '@okta/okta-react';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    // redirect to 404 if user doesn't have campaign role
    const claims = this.props.authState.accessToken.claims;
    if(claims){
      const claimKeys = Object.keys(claims);
      const roleKey = claimKeys?.find(key => /roles_/.test(key));
      const roles = claims[roleKey];
      const campaignRole = roles?.find(value => /ksm_campaign/.test(value));
      const M2MRole = roles?.find(value => /ksm_m2m/.test(value));
      if(!campaignRole || !M2MRole){
        window.location.href = "/404";
      }
    }
  }

  render() {
    let params = QueryString.parse(this.props.location.search)

    var size = params.size;
    if (size === undefined) size = 10;

    var previous = params.previous;
    if (previous === undefined) previous = "";

    var next = params.next;
    if (next === undefined) next = "";

    var search = params.search;

    // default to render list view
    var viewToRender =  <CampaignList size={size} previous={previous} next={next} search={search} roles={this.props.authState.accessToken.claims}/>

    if ((params.id !== undefined) && (params.id !== "")){
      // render single view
      viewToRender = <CampaignSingle id={params.id} error={params.error}/>
    }
    
    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          <Sidebar active="Campaigns"/>

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">

            {/* <!-- Main Content --> */}
            <div id="content">
              <Topbar icon="bi-lightning-charge" title="Campaigns"/>

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">
                {viewToRender}
              </div>
              {/* <!-- /.container-fluid --> */}

            </div>
            {/* <!-- End of Main Content --> */}

            <Footer />
          </div>
          {/* <!-- End of Content Wrapper --> */}

        </div>
        {/* <!-- End of Page Wrapper --> */}
      </div>
    )
  }
}

export default withOktaAuth(Dashboard);
