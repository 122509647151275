import http from "../api/http-common";

const getCallback = (eid, config) => {
  return http.get("/rsp/callback/eid/" + eid, config);
};

function orderByLatestDate(content){
  content = content.sort(function (a, b) {
    // find a processingStart
    let dateA = new Date();
    if (a.callback !== null){
      dateA = new Date(a.callback.processingStart);
    }

    // find b processingStart
    let dateB = new Date();
    if (b.callback !== null){
      dateB = new Date(b.callback.processingStart);
    }

    return dateB - dateA;
  });

  return content;
}

function reverseOrder(content){
  return content.sort().reverse();
}

const deleteCallbackByMessageId = (messageId, config) => {
  return http.delete("/rsp/callback/message/" + messageId, config);
};

const functions = {
  getCallback,
  orderByLatestDate,
  reverseOrder,
  deleteCallbackByMessageId
};

export default functions;
