import http from "../api/http-common";

const getAllColdStorageProfiles = (config) => {
    return http.get("/rsp/inventory/cold/storage", config);
};

const searchByEid = (eid, config) => {
  return http.get("/rsp/inventory/cold/storage/" + eid, config);
};

const searchByIccid = (iccid, config) => {
  return http.get("/rsp/inventory/cold/storage/iccid/" + iccid, config);
};

const activate = (eid, config) => {
  return http.patch("/rsp/inventory/cold/storage/" + eid + "/activate/", null, config);
};

function getRequestParams(page, size, search){
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["size"] = size;
  }

  if (search) {
    params["searchFilter"] = search;
  }

  return params;
};

const functions = {
  getAllColdStorageProfiles,
  searchByEid,
  searchByIccid,
  activate,
  getRequestParams
};

export default functions;
