import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import HttpUtil from "../../../api/http-util";
import ReactPaginate from "react-paginate";
import ProfileInventoryService from "../../../services/ProfileInventoryService";

import Search from "../../common/search/Search";
// import TableButtons from '../../common/table/TableButtons';
import ProfileTable from "./ProfileTable";
import ShowItems from "../../common/table/ShowItems";
//import Pagination from "../../common/table/Pagination";
import AlertOperationRequest from "./AlertOperationRequest.js";
import ReactDOM from 'react-dom'

class ProfileCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: null,
			totalPages: Number(this.props.page) + 1,
			page: this.props.page,
			size: this.props.size,
			search: this.props.search,
			oktaAuth: this.props.oktaAuth,
			totalItems: 0,
			firstRecord: 0,
			lastRecord: 0,
			disabled: null,
		};
	}

	handleGetProfilesResponse = (response) => {
		var page = response.data.page;
		this.setState({ totalPages: page.totalPages });

		var content = response.data._embedded.profiles;
		this.setState({ content: content });
		const total = response.data.page.totalElements;
		this.setState({ totalItems: total });
		this.calculateFirstRecord();
		this.calculateLastRecord();
	};

	handleSearchProfilesResponse = (response) => {
		// this should only fine one profile, we had to remove the partial search dude to M2M issue
		this.setState({ totalPages: 0 });
		this.setState({ disabled: "disabled" });
		var profile = response.data;
		this.setState({firstRecord: 1})
		this.setState({lastRecord: 1})
		this.setState({totalItems: 1})
		var content = [];
		content.push(profile);
		this.setState({ content: content });
	};

	retrieveProfiles = () => {
		if (this.state.size < 10) {
			this.setState({size: 10});
			this.handlePageChange({'selected' : parseInt(this.state.page)});
		} else if (this.state.size > 10 && this.state.size < 20) {
			this.setState({size: 20});
			this.handlePageChange({'selected': parseInt(this.state.page)});
		} else if ((this.state.size >20 && this.state.size < 50) || this.state.size > 50) {
			this.setState({size: 50});
			this.handlePageChange({'selected': parseInt(this.state.page)});
		}
		const params = ProfileInventoryService.getRequestParams(
			this.state.page,
			this.state.size,
			this.state.search
		);
		const config = HttpUtil.getHttpConfig(
			params,
			this.state.oktaAuth.getAccessToken()
		);

		if (this.state.search !== "" && this.state.search !== undefined) {
			if (this.state.search.length >= 18 && this.state.search.length <= 22) {
				this.hideAlert();
				ProfileInventoryService.search(config)
					.then((response) => this.handleSearchProfilesResponse(response))
					.catch((e) => {
						this.setState({ totalPages: 0 });
						this.setState({firstRecord: 0})
						this.setState({lastRecord: 0})
						this.setState({totalItems: 0})
						this.setState({ content: [] });
					});
			}
		} else {
			this.hideAlert();
			ProfileInventoryService.getAll(config)
				.then((response) => this.handleGetProfilesResponse(response))
				.catch((e) => {
					this.setState({ totalPages: 0 });
					this.setState({firstRecord: 0})
					this.setState({lastRecord: 0})
					this.setState({totalItems: 0})
					this.setState({ content: [] });
				});
		}
	};

	calculateFirstRecord() {
		this.setState({ firstRecord: this.state.page * this.state.size + 1 });
	}

	calculateLastRecord() {
		var recordEnd =
			this.state.page * parseInt(this.state.size) +
			parseInt(this.state.size);
		if (recordEnd > this.state.totalItems) {
			this.setState({ lastRecord: this.state.totalItems });
		} else {
			this.setState({ lastRecord: recordEnd });
		}
	}

	handlePageChange = (event) => {
		this.setState({ page: event.selected });
		this.hideAlert();
		this.setState({search: undefined});
		document.getElementById("searchBar").value = '';
	};

	componentDidMount() {
		// load data on first component render
		this.retrieveProfiles();
	}

	componentDidUpdate(prevProps, prevState) {
		var newSizeParam = "size=" + this.state.size;
		var newPageParam = "page=" + this.state.page;

		if (prevState.search !== this.state.search) {
			if (this.state.search !== "" && this.state.search !== undefined) {
				// Verify that search string is only numeric characters
				if (Boolean(this.state.search.match(/^\d+$/)) === false){
					this.showAlert("Danger", "Invalid Search", `An ICCID must contain only numeric characters.` );
					return;
				} else if (this.state.search.length < 18 || this.state.search.length > 22){
					this.showAlert("Danger", "Invalid Search", `Your search does not meet the required length for an ICCID.` );
					return;
				}
				else {
					this.retrieveProfiles();
					this.props.history.push(
						"/profiles?search=" + this.state.search
					);
				}
			} else {
				if (this.state.search === '') {
					// search is cleared
					this.setState({ page: 0 });
				}
				this.retrieveProfiles();
				window.history.replaceState(
					null,
					"",
					"profiles?" + newSizeParam + "&" + newPageParam
				);
			}
		}
		else if (prevState.size !== this.state.size || prevState.page !== this.state.page) {
			this.retrieveProfiles();
			window.history.replaceState(
				null,
				"",
				"profiles?" + newSizeParam + "&" + newPageParam
			);
		}
	}

	handleSizeChange = (event) => {
		this.setState({ size: event.target.value });
		this.setState({ page: 0 });
		this.hideAlert();
		this.setState({search: undefined});
		document.getElementById("searchBar").value = '';
		this.calculateFirstRecord();
		this.calculateLastRecord();
	};

	handleSearchBoxClick = () => {
		var searchBar = document.getElementById("searchBar");
		this.setState({ search: searchBar.value });
	};

	handleSearchBoxChange = (event) => {
		// only send the search when the 'enter' button is pressed to reduce API calls
		if (event.key === "Enter") {
			this.setState({ search: event.target.value });
		}
	};

	showAlert = (variant, heading, message) => {
		ReactDOM.render(
			<AlertOperationRequest  variant={variant} heading={heading} message={message} close={this.hideAlert}/>,
			document.getElementById('alert-form')
		);
		// setTimeout(() => this.hideAlert(), 4000);
	};

	hideAlert = () => {
		ReactDOM.render("", document.getElementById('alert-form'));
	};

	render() {
		return (
			<div className="row">
				<div className="col-lg-12 d-grid">
					<div className="card shadow mb-4">
						<div className="card-header py-3">
							<h6 className="m-0 font-weight-bold text-primary">
								<i className="bi bi-box"></i> My Profiles
							</h6>
						</div>
						<div className="card-body">
							<div id="alert-form" className="col-lg-12 d-grid" />
							<div className="search-responsive">
								<div className="row">
									<Search
										value={this.state.search}
										placeholder="Search for iccid ..."
										handleSearchBoxChange={
											this.handleSearchBoxChange
										}
										handleSearchBoxClick={
											this.handleSearchBoxClick
										}
									/>
									{/* <TableButtons /> */}
								</div>
							</div>
							<div className="table-responsive">
								<ProfileTable content={this.state.content} />
							</div>
							<hr />
							<div className="table-bottom-bar">
								<div className="row">
									<ShowItems
										size={this.state.size}
										handleSizeChange={this.handleSizeChange}
									/>
									<div
										className={
											"col-sm-12 col-md-6 react-paginate" +
											this.state.disabled
										}
									>
										<ReactPaginate
											previousLabel={"Previous"}
											nextLabel={"Next"}
											breakLabel={"..."}
											pageCount={this.state.totalPages}
											marginPagesDisplayed={1}
											pageRangeDisplayed={3}
											onPageChange={this.handlePageChange}
											renderOnZeroPageCount={null}
											containerClassName={"pagination"}
											pageClassName={"page-item"}
											pageLinkClassName={"page-link"}
											previousClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextClassName={"page-item"}
											nextLinkClassName={"page-link"}
											breakClassName={"page-item"}
											breakLinkClassName={"page-link"}
											activeClassName={"active"}
											forcePage={Number(this.state.page)}
										/>
									</div>
									<div className="record-count">
										Showing items {this.state.firstRecord} -{" "}
										{this.state.lastRecord} of{" "}
										{this.state.totalItems}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withOktaAuth(withRouter(ProfileCard));
