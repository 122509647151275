import React, {Component } from "react";
import OffcanvasOperationButton from "./OffcanvasOperationButton";
import { withOktaAuth } from '@okta/okta-react';

class ConsumerSingleOperationsButtons extends Component {

    render(){

        return (
            <div className={'click-row col-md-12 click-card col-xl-' + this.props.colWidth}>
                    <div className="card-body" style={{ padding: 0}}>
                        <div className="row no-gutters align-items-center">
                            <div className="col">
                                
                            <OffcanvasOperationButton placement="bottom" title="Generate Qr" icon="bi-qr-code"
                                                                  operationType="generate-qr"
                                                                  message="Enter EID and Confirmation code(CC) values below"
                                                                //   onClick={e => OperationsService.handleGenerateActivationCode(this.props.iccid, this.props.oktaAuth.getAccessToken())}
                                                                  isButtonDisabled = {(this?.props?.state["Profile State"]?.props?.children === 'AVAILABLE') && (!this.props.campaignIsRunning) ? false : true}
                                                                  oktaAuth={this.props.oktaAuth}
                                                                  iccid={this.props.iccid}
                                                                  profileState={this?.props?.state["Profile State"]?.props?.children}
                            />


                            <OffcanvasOperationButton placement="bottom" title="Share Codes" icon="bi-share"
                                                                  operationType="share-qr"
                                                                  message="Please enter an email address you'd like to share this QR Code & Activation Code with."
                                                                  validity={900}
                                                                  isButtonDisabled = {this?.props?.state["Profile State"]?.props?.children === 'RELEASED' ? false : true}
                                                                  oktaAuth={this.props.oktaAuth}
                                                                  iccid={this.props.iccid}/>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }







}

















export default withOktaAuth(ConsumerSingleOperationsButtons);