import React, { Component } from "react";
import './campaign-single.css'
class CampaignAttribute extends Component {

    render(){
      let rowColor = "";

      if (this.props.error === true){
        rowColor = "danger";
      }

      return (
        <tr className={'table-' + rowColor}>
          <td className="align-middle tr-data"><b>{this.props.title}:</b></td>
          <td className="align-middle tr-data" data-tid={this.props.title}>{this.props.value}</td>
        </tr>
      )
    }
};

    
export default CampaignAttribute;