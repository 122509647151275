import ReactDOM from 'react-dom'

import AlertOperationRequest from "../components/EIS/single-view/rsp-operations/AlertOperationRequest";

export default class AlertService {

  // Alert functions

  static showAlert = (variant, heading, message) => {
    ReactDOM.render(
      <AlertOperationRequest  variant={variant} heading={heading} message={message} close={this.hideAlert}/>,
      document.getElementById('alert-banner')
    );
    setTimeout(() => this.hideAlert(), 3000);
  }

  static hideAlert = () => {
    ReactDOM.render("", document.getElementById('alert-banner'));
  }
};