import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from "./routes";

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};
const App = () => <Routes />;
export default AppWrapper;
