import React, { Component} from "react";
import { withRouter } from "react-router-dom";
import EisService from '../../../services/EisService';
import './tableButton.css';
import HttpUtil from '../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';
import AlertOperationRequest from "../../Campaign/single-view/CampaignOperations/AlertOperationRequest.js";
import ReactDOM from 'react-dom'

class ProfileItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
          oktaAuth: this.props.oktaAuth
        }
      }
    
    redirectToEid(iccid) {
      const headerConfig = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());

      EisService.getEidByIccid(iccid, headerConfig).then((value) => {
          let eidValue = value.data._id
          window.open("/esims?eid=" + eidValue, "_self")
        }).catch((e) => {
          let jsonResponse = JSON.stringify(e)
          if (jsonResponse.includes("Request failed with status code 401")) {
            this.showAlert("Danger", "Insufficient Permissions", `You do not have access to view this eUICC.`);
        } else {
          console.log("Error has occurred", e);
    }});   
    }
    showAlert = (variant, heading, message) => {
      ReactDOM.render(
        <AlertOperationRequest  variant={variant} heading={heading} message={message} close={this.hideAlert}/>,
        document.getElementById('alert-form')
      );
      setTimeout(() => this.hideAlert(), 8000);
    };
  
    hideAlert = () => {
        ReactDOM.render("", document.getElementById('alert-form'));
      };
    
    render()
    { 
        let rowColor = "";
        let profileStatusBadge = <span className="badge bg-success text-light">{this.props.profile.state}</span>;

        let iccidHtml = this.props.profile.iccid;
        if (this.props.profile.state === "DOWNLOADED"){
            profileStatusBadge = <span className="badge bg-danger text-light">{this.props.profile.state}</span>;
            rowColor = "danger";
            iccidHtml = (<button className="button" onClick={() => this.redirectToEid(this.props.profile.iccid)}>{this.props.profile.iccid}</button>)
        }
        if (this.props.profile.state === "UNAVAILABLE"){
            profileStatusBadge = <span className="badge bg-secondary text-light">{this.props.profile.state}</span>;
        }

        return <tr className={'table-' + rowColor}>
          <td data-tid="iccId">{iccidHtml}</td>
          <td data-tid="mnoId">{this.props.profile?.mnoId}</td>
          <td data-tid="profileType">{this.props.profile?.profileType}</td>
          <td data-tid="imsi">{this.props.profile?.subscriptionAddress.imsi}</td>
          <td data-tid="msisdn">{this.props.profile?.subscriptionAddress.msisdn}</td>
          <td data-tid="status">{profileStatusBadge}</td>
        </tr>
    }
};
    
export default withOktaAuth(withRouter(ProfileItem));