import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";

// common pages
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
// inventories
import Profiles from "./pages/Profiles";
// KSM-1318
import ConsumerProfiles from "./pages/ConsumerProfiles";
import Eis from "./pages/Eis";
import ColdStorage from "./pages/ColdStorage/";
// campaigns
import Campaigns from "./pages/Campaigns";
// admin portal
import AdminEnvironments from "./pages/AdminEnvironments";
import AdminCustomers from "./pages/AdminCustomers";
import AdminFeatures from "./pages/AdminFeatures";

// error pages
import Api from "./pages/Api";
import NotFound from "./pages/NotFound";

// okta
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import config from './config';
import QrCampaigns from "./pages/QrCampaigns";

const oktaAuth = new OktaAuth(config.oidc);

const Routes = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

    const customAuthHandler = () => {
        history.push('/');
    };

  return (
      <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={restoreOriginalUri}
          onAuthRequired={customAuthHandler}
      >
        <Switch>
            {/* <Route exact path="/" component={SignIn} /> */}
            <Route exact path="/" component={Login} />
            <Route path='/callback' component={LoginCallback} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <SecureRoute path="/dashboard" component={Dashboard} />
            <SecureRoute path="/profiles" component={Profiles} />
            <SecureRoute path="/consumerprofiles" component={ConsumerProfiles} />
            <SecureRoute path="/qrCampaigns" component={QrCampaigns} />
            <SecureRoute path="/esims" component={Eis} />
            <SecureRoute path="/storage" component={ColdStorage} />
            <SecureRoute path="/campaigns" component={Campaigns} />
            <SecureRoute path="/api" component={Api} />
            {/* admin pages */}
            <SecureRoute path="/admin-customers" component={AdminCustomers} />
            <SecureRoute path="/admin-environments" component={AdminEnvironments} />
            <SecureRoute path="/admin-features" component={AdminFeatures} />

            {/* error pages */}
            <Route path="/404" component={NotFound} />
            <Route path="*" component={NotFound} />
        </Switch>
    </Security>
  );
};

export default Routes;
