import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clickMenuOpen } from "../../../redux/actions";

import { useOktaAuth } from "@okta/okta-react";
import Cookies from 'js-cookie';
import MaintenanceWindowAlert from "../../MaintenanceWindowAlert";

const Topbar = ({ clickMenuOpen, icon, title }) => {
	const { authState, oktaAuth } = useOktaAuth();

	const [userName, setUserName] = useState(
		localStorage.getItem("oktaUserName") || ""
	);

	const [userOrg, setUserOrg] = useState(
		localStorage.getItem("oktaUserOrg") || ""
	);

	useEffect(() => {
		localStorage.setItem("oktaUserName", userName);
		localStorage.setItem("oktaUserOrg", userOrg);
	}, [userName, userOrg]);

	const logout = async () => {
		try {
			const loggedIn = await oktaAuth.isAuthenticated();
			if(loggedIn) {
				setUserName("");
				setUserOrg("");
				await oktaAuth.signOut();
				localStorage.clear();
			} else {
				window.location.replace('/');
			}
		} catch (err) {
			throw err;
		}
	};

	const hasRole = (roleName) => {
		const claims = authState?.accessToken?.claims;
		if (claims) {
			const claimKeys = Object.keys(claims);
			const roleKey = claimKeys?.find(key => /roles_/.test(key));
			const roles = claims[roleKey];

			let role = "";
			roles?.forEach(element => {
					if (element.includes(roleName)){
						role = element}
				}
			);
			if (role) return true;
		}
		return false;
	}

	const openInNewTab = (url) => {
		window.open(url, "_blank", "noopener,noreferrer");
	};

	const goToAdminPage = () => {
		if (hasRole("okta_admin")){
			window.location.href = "/admin-customers";
		}
	}

	const environmentBadge = () => {
		const badge = process.env.REACT_APP_ENVIRONMENT_BADGE_STRING;

		// dont show badge for production
		if (badge === "Production") {
			return;
		} else {
			return (
				<li className="nav-item dropdown no-arrow">
					<div className="text-center nav-link dropdown-toggle">
						<div>
							<span className="badge badge-danger">{badge}</span>
						</div>
					</div>
				</li>
			);
		}
	};

	const getAPIToken = () => {
		// fetch token from cookie

		const allCookies = Cookies.get();
		if (allCookies['okta-token-storage_accessToken']) {
			const AUTH_TOKEN = JSON.parse(allCookies['okta-token-storage_accessToken']);
			const token = "Bearer " + AUTH_TOKEN.accessToken
			// copy token to clipboard
			navigator.clipboard.writeText(token);
		}
	}

	useEffect(() => {
		if (!authState?.isAuthenticated) {
			setUserName("");
			setUserOrg("");
		} else {
			if (localStorage.getItem("oktaUserName") === "") {
				oktaAuth.getUser().then((info) => {
					//set name
					setUserName(info.name);
					// set organization
					setUserOrg(info.org);
				});
			}
		}
	}, [authState, oktaAuth]);

	return (
		<div>
			<MaintenanceWindowAlert oktaAuth={oktaAuth} />
			<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
				{/* <!-- Sidebar Toggle (Topbar) --> */}
				{/* <button onClick={() => { clickMenuOpen() }}  id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars"></i>
              </button> */}

				{/* <!-- Page Heading --> */}
				<div className="page-title d-sm-flex align-items-center justify-content-between">
					<h1 className="h3">
						<i className={"bi " + icon}></i> {title}
					</h1>
				</div>

				{/* <!-- Topbar Navbar --> */}
				<ul className="navbar-nav ml-auto">
					{/* <!-- Nav Item - Environment Information --> */}
					{environmentBadge()}

					{/* <!-- Nav Divider --> */}
					<div className="topbar-divider d-none d-sm-block" />

					{/* <!-- Nav Item - User Information --> */}
					<li className="nav-item dropdown no-arrow">
						<a
							className="nav-link dropdown-toggle"
							href="/"
							id="userDropdown"
							role="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							data-cy="user-dropdown"
						>
							<div className="text-center">
								<div>
									<span className="mr-2 d-lg-inline text-gray-600 small">
										<strong>{userName}</strong>
									</span>
								</div>
								<div>
									<span className="badge badge-info" data-cy="user-badge">
										{userOrg}
									</span>
								</div>
							</div>
						</a>
						{/* <!-- Dropdown - User Information --> */}
						<div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">

							{hasRole("okta_admin")
								? (
									<>
										<button className="dropdown-item" data-cy="user-guide" onClick={() => goToAdminPage()}>
											<i className="fas fa-cogs fa-sm fa-fw mr-2 text-400"></i> Configurations
										</button>
										<div className="dropdown-divider"></div>
									</>
								)
								: ""
							}

							<button className="dropdown-item" data-cy="user-guide" onClick={() => getAPIToken()}>
								<i className="fas fa-sharp fa-solid fa-key fa-sm fa-fw mr-2 text-400"></i> API Token
							</button>

							<button className="dropdown-item" data-cy="user-guide" onClick={() => openInNewTab(process.env.REACT_APP_WEB_DOCS_BASE_URL)}>
								<i className="fas fa-question-circle fa-sm fa-fw mr-2 text-400"></i> User Guide
							</button>

							<button className="dropdown-item" data-cy="support-portal" onClick={() => openInNewTab(process.env.REACT_APP_FRESHDESK_SUPPORT_PORTAL_URL)}>
								<i className="fas fa-ticket-alt fa-sm fa-fw mr-2 text-400"></i> Support Portal
							</button>

							<div className="dropdown-divider"></div>

							<button className="dropdown-item" data-cy="logout" onClick={logout}>
								<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-400"></i> Logout
							</button>
						</div>
					</li>
				</ul>
			</nav>
		</div>
	);
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = (store) => ({
	toggled: store.menuState.menuOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
