import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";

// import AlertService from '../../../../services/AlertService';
import AdminPortalService from '../../../../services/AdminPortalService';
import DateToggle from "../../../Date/DateToggle"
import Spinner from 'react-bootstrap/Spinner';

import '../admin-customers.css'

class CustomersList extends Component {
    constructor(props) {
		super(props);
        this.state = {
            dateToggleFormat: 1,
            oktaAuth: this.props.oktaAuth,
            isLoading: true,
            customers: null,
        }
        this.toggleDateFormat = this.toggleDateFormat.bind(this);
    }

    // API Integration Functions

    getCustomerList(){
        AdminPortalService.listCustomers(this.state.oktaAuth.getAccessToken())
        .then((response) => {
            // sort by name
            let sortByName = AdminPortalService.sortByField(response.data, "created")

            this.setState({
                customers: sortByName,
                isLoading: false
            })
        })
        .catch((e) => {
            console.log(`Error occurred in AdminPortalService getCustomerList: ${e}`);
            this.setState({
                isLoading: false
            })
        });
    }

    // Component Utils Functinos

    toggleDateFormat(index){
        let newIndex = (index === 3) ? 1 : (index + 1)
        this.setState({dateToggleFormat: newIndex})
    }

    displayCustomers(){
        let customers;
        if (this.state.customers === null){
            let message = (<i className="bi bi-exclamation-triangle"> No customers found</i>)
            if (this.state.isLoading === true){
                message = (<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>)
            }
            customers = (<tr><td colSpan="8">{message}</td></tr>)
        }else{
            customers = []
            this.state.customers.forEach(customer => {
                customers.push(
                    <tr key={customer.id}>
                        <td>
                            <a href={'/admin-customers?customer_id=' + customer.id}>{customer.name}</a>
                        </td>
                        <td>{customer.description}</td>
                        <DateToggle value={customer.created} type={this.state.dateToggleFormat} toggleFunction={this.toggleDateFormat}/>
                        <DateToggle value={customer.lastUpdated} type={this.state.dateToggleFormat} toggleFunction={this.toggleDateFormat}/>
                        <td>
                            {/* {this.editFeatureModal(feature)} */}
                        </td>
                    </tr>
                )
            })
        }
        return customers
    }

    // ReactJS Functinos

    componentDidMount(){
        this.getCustomerList()
    }

    // render

    render() {
		return (
            <>
                <div className="row">
                    <div id="alert-banner" className="col-lg-12 d-grid" />
                </div>

                <div className="row">
                    <div className="col-lg-12 d-grid">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    <i className="bi bi-people"></i> Customers
                                </h6>
                            </div>
                            <div className="card-body">
                            <div id="alert-form" className="col-lg-12 d-grid" />
                                <div className="table-responsive">
                                    <div id="buttonPanel">
                                        {/* {this.addFeatureModal()} */}
                                    </div>
                                    <table className="table table-striped table-hover" data-tid="cold-eis-table">
                                        <thead>
                                            <tr>
                                                <th className="col-md-2">Name</th>
                                                <th className="col-md-3">Description</th>
                                                <th className="col-md-3">Created</th>
                                                <th className="col-md-3">Last Updated</th>
                                                <th className="col-md-1">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.displayCustomers()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
		);
	}
}

export default withOktaAuth(withRouter(CustomersList));