import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import ReactPaginate from "react-paginate";

import EisService from "../../../services/EisService";

import Search from "../../common/search/Search";
// import TableButtons from '../../common/table/TableButtons';
import EisTable from "./EisTable";
import ShowItems from "../../common/table/ShowItems";
//import Pagination from "../../common/table/Pagination";
import { withOktaAuth } from "@okta/okta-react";
import HttpUtil from "../../../api/http-util";
import AlertOperationRequest from "../single-view/rsp-operations/AlertOperationRequest.js";
import ReactDOM from 'react-dom'

class EisCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: null,
			page: this.props.page,
			size: this.props.size,
			totalPages: Number(this.props.page)+1,
			search: this.props.search,
			oktaAuth: this.props.oktaAuth,
			totalItems: 0,
			firstRecord: 0,
			lastRecord: 0,
			disabled: null,
		};
	}

	handleAPIResponse = (response) => {
		// KCM-634 - handle removal of partial search
		if (response.data.content === undefined) {
			var c = [];
			c.push(response.data);

			let content = EisService.transformResponse(c);
			this.setState({ totalPages: 0, disabled: "disabled", firstRecord: 1, lastRecord: 1, totalItems: 1, content: content }); //disable pagination as only one item should be returned

		} else {
			var page = response.data.page;
			let content = EisService.transformResponse(response.data.content);
			const total = response.data.page.totalElements;
			this.setState({ totalPages: page.totalPages, content: content, totalItems: total });
			this.calculateFirstRecord();
			this.calculateLastRecord();
		}
	};

	retrieveEis = () => {
		if(this.state.size < 10){
			this.setState({size: 10});
			this.handlePageChange({'selected' : parseInt(this.state.page)});
		  }else if(this.state.size > 10 && this.state.size < 20){
			this.setState({size: 20});
			this.handlePageChange({'selected': parseInt(this.state.page)});
		  }else if((this.state.size >20 && this.state.size < 50) || this.state.size > 50){
			this.setState({size: 50});
			this.handlePageChange({'selected': parseInt(this.state.page)});
		  }
		  
		const params = EisService.getRequestParams(
			this.state.page,
			this.state.size,
			this.state.search
		);
		const config = HttpUtil.getHttpConfig(
			params,
			this.state.oktaAuth.getAccessToken()
		);

		if (this.state.search !== "" && this.state.search !== undefined) {
			EisService.search(config)
				.then((response) => this.handleAPIResponse(response))
				.catch((e) => {
					console.log(`Error occurred in EisSearch: ${e}`);
					this.setState({ totalPages: 0, firstRecord: 0, lastRecord: 0, totalItems: 0, content: []});
				});
		} else {
			EisService.getAll(config)
				.then((response) => this.handleAPIResponse(response))
				.catch((e) => {
					console.log(`Error occurred in EisService getAll: ${e}`);
					this.setState({ totalPages: 0 });
					this.setState({firstRecord: 0})
					this.setState({lastRecord: 0})
					this.setState({totalItems: 0})
					this.setState({ content: [] });
				});
		}
	};

	retrieveEisByIccid = () => {
		const params = EisService.getRequestParams(
			this.state.page,
			this.state.size,
			this.state.search
		);
		const config = HttpUtil.getHttpConfig(
			params,
			this.state.oktaAuth.getAccessToken()
		);

		if (this.state.search !== "" && this.state.search !== undefined) {
			EisService.getEidByIccid(this.state.search, config)
				.then((response) => this.handleAPIResponse(response))
				.catch((e) => {
					console.log(`Error occurred in getEidByIccid: ${e}`);
					this.setState({ totalPages: 0 });
					this.setState({firstRecord: 0})
					this.setState({lastRecord: 0})
					this.setState({totalItems: 0})
					this.setState({ content: [] });
				});
		} else {
			EisService.getAll(config)
				.then((response) => this.handleAPIResponse(response))
				.catch((e) => {
					console.log(`Error occurred in EisService getAll: ${e}`);
					this.setState({ totalPages: 0 });
					this.setState({firstRecord: 0})
					this.setState({lastRecord: 0})
					this.setState({totalItems: 0})
					this.setState({ content: [] });
				});
		}
	};

	calculateFirstRecord() {
		this.setState({ firstRecord: this.state.page * this.state.size + 1 });
	}

	calculateLastRecord() {
		var recordEnd =
			this.state.page * parseInt(this.state.size) +
			parseInt(this.state.size);
		if (recordEnd > this.state.totalItems) {
			this.setState({ lastRecord: this.state.totalItems });
		} else {
			this.setState({ lastRecord: recordEnd });
		}
	}

	componentDidMount() {
		// load data on first component render
		// Check put in for length of props search in support of refreshing page after searching by iccid
		if(this.state.page > 0){
			this.handlePageChange({'selected' : parseInt(this.state.page)});
		}else if (this?.props?.search?.length >= 18 && this?.state?.search?.length <= 22) {
			this.retrieveEisByIccid();
		}else {
			this.retrieveEis();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		var newSizeParam = "size=" + this.state.size;
		var newPageParam = "&page=" + this.state.page;
		var newSearchParam = "";

		var includeSearch = false;
		if (this.state.search !== undefined && this.state.search !== "") {
			newSearchParam = "&search=" + this.state.search;
			includeSearch = true;
		}

		if (
			prevState.size !== this.state.size ||
			prevState.page !== this.state.page ||
			prevState.search !== this.state.search
		) {
			// Verify that search string is only numeric characters
			if (includeSearch && Boolean(this.state.search.match(/^\d+$/)) === false && this.state.search !== ""){
				this.showAlert("Danger", "Invalid Search", `An EID or ICCID must contain only numeric characters.` );
				window.history.replaceState(
					null,
					"",
					"esims?" + newSizeParam + newPageParam
				);
			}
			else if (includeSearch && this.state.search.length === 32) {
				this.hideAlert();
				this.retrieveEis();
				window.history.replaceState(
					null,
					"",
					"esims?" + newSizeParam + newPageParam + newSearchParam
				);
			} else if (includeSearch && this.state.search.length >= 18 && this.state.search.length <= 22) {
				this.hideAlert();
				this.retrieveEisByIccid();
				window.history.replaceState(
					null,
					"",
					"esims?" + newSizeParam + newPageParam + newSearchParam
				);
			} 
			else if (includeSearch && this.state.search.length > 0) {
				this.showAlert("Danger", "Invalid Search", `Your search does not meet the required length for an EID or ICCID.` );
				window.history.replaceState(
					null,
					"",
					"esims?" + newSizeParam + newPageParam
				);
			}
			else {
				if(prevState.search !== this.state.search && this.state.search === '') {
					// search is cleared
					this.setState({ page: 0 });
				}
				this.hideAlert();
				this.retrieveEis();
				window.history.replaceState(
					null,
					"",
					"esims?" + newSizeParam + newPageParam
				);
			}
		}
	}
	handlePageChange = (event) => {
		this.setState({ page: event.selected, search: undefined });
		this.hideAlert();
		document.getElementById("searchBar").value = '';
	};

	handleSizeChange = (event) => {
		this.setState({ size: event.target.value, page: 0, search: undefined });
		this.hideAlert();
		document.getElementById("searchBar").value = '';
	};

	handleSearchBoxClick = () => {
		var searchBar = document.getElementById("searchBar");
		this.setState({ search: searchBar.value });
	};

	handleSearchBoxChange = (event) => {
		// only send the search when the 'enter' button is pressed to reduce API calls
		if (event.key === "Enter") {
			this.setState({ search: event.target.value });
		}
	};

	showAlert = (variant, heading, message) => {
        ReactDOM.render(
          <AlertOperationRequest  variant={variant} heading={heading} message={message} close={this.hideAlert}/>,
          document.getElementById('alert-form')
        );
        // setTimeout(() => this.hideAlert(), 4000);
      };
    
    hideAlert = () => {
        ReactDOM.render("", document.getElementById('alert-form'));
      };

	render() {
		return (
			<div className="row">
				<div className="col-lg-12 d-grid">
					<div className="card shadow mb-4">
						<div className="card-header py-3">
							<h6 className="m-0 font-weight-bold text-primary">
								<i className="bi bi-cpu"></i> My eSIMs
							</h6>
						</div>
						<div className="card-body">
							<div className="search-responsive">
							<div id="alert-form" className="col-lg-12 d-grid" />
								<div className="row">
									<Search
										value={this.state.search}
										placeholder="Search by EID or ICCID ..."
										handleSearchBoxChange={
											this.handleSearchBoxChange
										}
										handleSearchBoxClick={
											this.handleSearchBoxClick
										}
									/>
									{/* <TableButtons /> */}
								</div>
							</div>
							<div className="table-responsive">
								<EisTable content={this.state.content} />
							</div>
							<hr />
							<div className="table-bottom-bar">
								<div className="row">
									<ShowItems
										size={this.state.size}
										handleSizeChange={this.handleSizeChange}
									/>
									<div
										className={
											"col-sm-12 col-md-6 react-paginate" +
											this.state.disabled
										}
									>
										<ReactPaginate
											previousLabel={"Previous"}
											nextLabel={"Next"}
											breakLabel={"..."}
											pageCount={this.state.totalPages}
											marginPagesDisplayed={1}
											pageRangeDisplayed={3}
											onPageChange={this.handlePageChange}
											renderOnZeroPageCount={null}
											containerClassName={"pagination"}
											pageClassName={"page-item"}
											pageLinkClassName={"page-link"}
											previousClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextClassName={"page-item"}
											nextLinkClassName={"page-link"}
											breakClassName={"page-item"}
											breakLinkClassName={"page-link"}
											activeClassName={"active"}
											forcePage={Number(this.state.page)}
										/>
									</div>
									<div className="record-count">
										Showing items {this.state.firstRecord} -{" "}
										{this.state.lastRecord} of{" "}
										{this.state.totalItems}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withOktaAuth(withRouter(EisCard));
