import React, { Component } from 'react';

// footer
import ForgotPasswordForm from '../../components/ForgotPasswordForm';

class Login extends Component {
  render() {
    return (
      <div className="bg-gradient-primary" style={{height: `100%`}}>
        {/* <!-- Page Wrapper --> */}
        <div className="container">
          <ForgotPasswordForm />
        </div>
        {/* <!-- End of Page Wrapper --> */}
      </div>
    )
  }
}

export default Login;