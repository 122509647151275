import React, { Component } from "react";
import {OverlayTrigger, Tooltip, Spinner} from 'react-bootstrap';

class CallbackItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
          callback: this.props.callback,
        }
    }

    renderOperationIcon(action){
        if (action === "ES2-DownloadProfile"){
            return "bi-cloud-arrow-down";
        } else if (action === "ES4-EnableProfile"){
            return "bi-file-check";
        } else if (action === "ES4-DisableProfile"){
            return "bi-file-minus";
        } else if (action === "ES4-DeleteProfile"){
            return "bi-file-excel";
        } else if (action === "ES4-AuditEIS"){
            return "bi-bug";
        } else if(action === "ES4-UpdateSubscriptionAddress"){
            return "bi bi-pencil-square";
        }

    }

    renderColor(status){
        let color = "danger";
        if (status === "EXECUTED_SUCCESS"){
            color = "success";
        } else if (status === "IN_PROGRESS"){
            color = "info";
        }
        return color;
    }

    formatDate(timestamp){
        var time = new Date(timestamp);
        return time.toLocaleString('en-GB', { localeMatcher: "best fit" , dateStyle: "full"});
    }
    
    formatTime(timestamp){
        var time = new Date(timestamp);
        return time.toLocaleString('en-GB', { localeMatcher: "best fit", timeStyle: "long"});
    }

    durationBetweenTimestamps(timestampA, timestampB){
        var t1 = new Date(timestampA);
        var t2 = new Date(timestampB);
        var diff = t2.getTime() - t1.getTime();
        return diff;  
    }
    
    formatDurationSeconds(timestampA, timestampB){
        return Math.trunc(this.durationBetweenTimestamps(timestampA, timestampB) / 1000 );
    }
    
    formatDurationMinutes(timestampA, timestampB){
        return Math.trunc(this.durationBetweenTimestamps(timestampA, timestampB) / 1000 /60 );
    }

    renderStatusCodeData(){
        if (this.state.callback.status !== "EXECUTED_SUCCESS"){
            if (this.state.callback.callback !== null){
                let message = this.state.callback.callback.statusCodeData.message;
                let reason = this.state.callback.callback.statusCodeData.reason;

                if (typeof message !== 'string'){
                    message = ""
                }
                if (typeof reason !== 'string'){
                    reason = ""
                }
                return (
                    <>
                        <div><small><strong>{reason}</strong></small></div>
                        <div><small data-tid="operationStatus">{message}</small></div>
                    </>
                )
            }
        }

        return "";
    }

    renderDuration(startTime, endTime){
         // calculate duration
         let diff = this.formatDurationSeconds(startTime, endTime);
         let unit = " seconds";
         if (diff > 61){
             diff = this.formatDurationMinutes(startTime, endTime);
             unit = " minutes";
         }

         return (<><strong>Duration: </strong>{diff}{unit}</>)
    }

    renderStatus(color){

        let status = (
            <div>
                <span className={"operationType badge text-light bg-" + color} data-tid="Acknowledged Operations Status">{this.state.callback.status}</span>
            </div>
        )
    
        if (this.state.callback.status === "IN_PROGRESS"){
            status = (
                <div>
                    <span className={"operationType badge text-light bg-" + color}>{this.state.callback.status} <Spinner animation="border" size="sm" /></span>
                </div>
            )
        }
        return status;
    }

    renderProfileInfo() {
        if (this.state.callback?.profileType !== null) {
            return <div className="operationType">
                <small data-tid="operationTypeIccid"><strong>Profile Type: </strong>{this.state.callback.profileType}</small><br/><small><strong>ICCID: </strong> {this.state.callback.iccid}</small></div>
        } else if (this.state.callback.action !== 'ES4-AuditEIS') {
            return <div className="operationType"><small data-tid="operationIccid"><strong>ICCID: </strong> {this.state.callback.iccid}</small></div>;
        }

    }

    render(){
        let color = this.renderColor(this.state.callback.status);
        let operationIcon = this.renderOperationIcon(this.state.callback.action);

        let startTime = 0;
        let endTime = 0;
        let duration = "";
        let timestampDate = "";
        let timestampTime = "";

        if (this.state.callback.callback !== null){
            startTime = this.state.callback.callback.processingStart;
            endTime = this.state.callback.callback.processingEnd;

            timestampDate = this.formatDate(startTime);
            timestampTime = this.formatTime(startTime);

            // calculate duration
            duration = this.renderDuration(startTime, endTime);
        }

        let callbackTypeColor = "bg-info";
        let callbackTypeColorHighlight = ""

        if (this.state.callback.type === "campaign-operation"){
            callbackTypeColor = "bg-warning";
            callbackTypeColorHighlight = "callbackItemBackgroud";
        }

        let statusCode = this.renderStatusCodeData();

        return (
            <div className={"callbackItem " + callbackTypeColorHighlight}>
                <div className="col" data-tid="Acknowledged Operations Card">
                    <div className="row">
                        <div className="col-9">
                            <h6 className={"text-" + color} data-tid="operationType"><i
                                className={"bi " + operationIcon}></i> {this.state.callback.action}</h6>
                            {this.renderStatus(color)}
                            {this.renderProfileInfo()}
                            <div className="operationType">{statusCode}</div>
                            <div className="operationType"><small>{duration}</small></div>
                            <small><span
                                className={"operationType badge text-light " + callbackTypeColor}>{this.state.callback.type}</span></small>
                        </div>
                        <div className="col-3 rightAlign">
                            <p><small>{timestampDate}</small></p>
                            <p><small>{timestampTime}</small></p>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-bottom">Delete</Tooltip>}>
                                <button type="button"
                                        onClick={e => this.props.deleteButton()}
                                        className="btn btn-sm btn-circle btn-danger">
                                    <i className="bi bi-trash fa-sm fa-fw"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 rightAlign messageId">
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-top">Message Id</Tooltip>}>
                                <small><i data-tid="messageID">{this.state.callback.messageId}</i></small>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
    
export default CallbackItem;