import React, { Component } from "react";
import {OverlayTrigger, Button, Offcanvas, Tooltip} from 'react-bootstrap';
import "./offcanvas.css"
import QRModalWindow from "./QRModal";
import OperationsService  from "../../../services/OperationsService";
import { withOktaAuth } from "@okta/okta-react";
import { withRouter } from "react-router-dom";

import AlertService from '../../../services/AlertService';

class OffcanvasOperationButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false,
          iccid: "",
          confirmationCode: "",
          eid: "",
          email:"",
          qrData: null,
          showModal: false,
          oktaAuth: this.props.oktaAuth,
          profileState : "",
          error:false
        };
        
        this.onChangeConfirmationCode = this.onChangeConfirmationCode.bind(this);
        this.onChangeEid = this.onChangeEid.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
      }

    renderOpenButton() {
        if (this.props?.profileState ==='AVAILABLE' && (this.props.isButtonDisabled && this.props.operationType === "generate-qr")) {
            return (
                <OverlayTrigger placement="bottom"
                                overlay={<Tooltip id={`tooltip-generate-qr`}>This Profile Type is currently in use.</Tooltip>}>
                    <Button className="btn btn-circle rps-action-btn btn-primary" data-cy={this.props.title}
                            onClick={() => this.handleShow()} disabled><i className={`bi ${this.props.icon}`}/></Button>
                </OverlayTrigger>
            )
        } else if (this.props.isButtonDisabled) {
            return (
                <Button className="btn btn-circle rps-action-btn btn-primary" data-cy={this.props.title}
                        onClick={() => this.handleShow()} disabled><i className={`bi ${this.props.icon}`}/></Button>
            )
        } else {
            return (
                <OverlayTrigger placement={this.props.placement}
                                overlay={<Tooltip id={`tooltip-${this.props.placement}`}>{this.props.title}</Tooltip>}>
                    <Button className="btn btn-circle rps-action-btn btn-primary"
                            data-cy={this.props.title}
                            onClick={() => this.handleShow()}>
                        <i className={`bi ${this.props.icon}`}/>
                    </Button>
                </OverlayTrigger>
            )
        }
    }


    renderActionButton() {
        let button = null;
        if (this.props.operationType === "generate-qr") {
            const isValidEid = this.isValidEid(this.state.eid);
            button = (
                <Button
                    className="btn rps-action-btn btn-primary"
                    id="sendButton"
                    onClick={() => {const closeButton = document.getElementById('QrModalCloseButton'); closeButton.style.display = 'none';this.generateActivationCode() }}
                    data-toggle="modal"
                    data-target={'#activateModal'}
                    data-tid={"activateModal"}
                    disabled={!isValidEid}
                >
                    Send
                </Button>
            );
        } else if (this.props.operationType === "share-qr") {
            const isEmailValid = this.isValidEmail(this.state.email);
            button = (
                <Button
                    className="btn rps-action-btn btn-primary"
                    onClick={() => { this.shareCodes() }}
                    data-toggle="modal"
                    data-tid={"emailModal"}
                    data-target={'#emailModal'}
                    disabled={!isEmailValid}
                >
                    Send
                </Button>
            );
        }
        return button;
    }


    onChangeEid(e){
        const eid = e.target.value
        this.setState({eid});
    }

    onChangeEmail(e){
        this.setState({ email: e.target.value });
    }
    
    onChangeConfirmationCode(e){
        this.setState({ confirmationCode: e.target.value });
    }
    renderForm(){
        let collectDataElements = "";

        if (this.props.operationType === "generate-qr"){
            collectDataElements = (
                <div>
                    <p>
                        <span><b>EID</b> -<em>Optional</em>: </span>
                        <input
                            id="selected-eid"
                            type="text"
                            data-tid="selectedEid"
                            value={this.state.eid}
                            onChange={this.onChangeEid}
                            required
                        />
                    </p>
                    <p> </p>
                    {this.state.eid !== "" && !this.isValidEid(this.state.eid) && (
                        <div
                            style={{
                                backgroundColor: "#ffe6cc",
                                padding: "10px 10px 20px 10px",
                                border: "1px solid #ffcc99",
                                borderRadius: "4px",
                            }}
                        >Invalid Eid format. Please enter a Eid.
                        </div>
                    )}
                    <p> </p>
                    <p>
                        <span><b>CC</b> -<em>Optional</em> : </span>
                        <input
                            id="selected-confirmationCode"
                            type="text"
                            data-tid="confirmationCode"
                            value={this.state.confirmationCode}
                            onChange={this.onChangeConfirmationCode}
                            required
                        />
                    </p>
                </div>

            )
        } else if(this.props.operationType === "share-qr"){
            collectDataElements = (
                <div>
                    <p>
                        <span>Email : </span>
                        <input
                            id="selected-email"
                            type="email"
                            data-tid="selectedEmail"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            required
                        />
                    </p>
                    <p> </p>
                    {this.state.email !== "" && !this.isValidEmail(this.state.email) && (
                        <div
                            style={{
                                backgroundColor: "#ffe6cc",
                                padding: "10px 10px 20px 10px",
                                border: "1px solid #ffcc99",
                                borderRadius: "4px",
                            }}
                        >Invalid email format. Please enter a valid email address.
                        </div>
                    )}
                    <p> </p>
                </div>
            );

        }
        return collectDataElements;
    }

    isValidEmail(email) {
        // Simple email validation using regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }


     isValidEid(eid) {
        if(eid === ""){
            return true;
        }
        if (eid.length=== 32){
            return true;
        }
    }

    generateActivationCode(){
        OperationsService.handleGenerateActivationCode(this.props.iccid, this.props.oktaAuth.getAccessToken()).then((response) => {
            response.data["iccid"] = this.props.iccid
            this.setState({"qrData": response.data})
            this.setState({"showModal": true})

        }).catch((e) => {
            this.setState({"error": true})
        });
        
    }

    shareCodes(){
        OperationsService.shareActivationCode(this.props.iccid, this.props.oktaAuth.getAccessToken()).then((response) => {
            const email = this.state.email
            AlertService.showAlert("Success", "Activation Code Shared via Email", "Email has been sent to " + email);
            this.handleClose();
        }).catch((e) => {
            console.log("ERROR",e)
            AlertService.showAlert("Danger", "Share Activation Codes", "Unable to communicate with Kigen Consumer - " + e.message);
            this.handleClose();
        });
    }

    handleClose = () => {
        this.setState({show: false, value: "", validity: this.props.validity });
    };
        
    handleShow = () => {
        this.setState({show: true});
    };

    hideModal = () => {

        this.setState({
            show: false,
            showModal: false,
            eid: "",
            confirmationCode: "",
            email: ""
        })
    }
    render(){
        // dynamically create elements based on context
        let triggerButton = this.renderOpenButton();
        let button = this.renderActionButton();
        let collectDataElements = this.renderForm();

        return (

            <>
                {triggerButton}
                <Offcanvas id="QrModalCloseButton"  show={this.state.show}  onHide={e => this.handleClose()} placement="end">
                    <Offcanvas.Header>
                        <Offcanvas.Title><i className={`bi ${this.props.icon}`}/> {this.props.title}</Offcanvas.Title>
                        <Button id="closeModalQR"  data-tid={"closeModalQR"} type="button" className="btn-close text-reset new_close" onClick={() => this.hideModal()}></Button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p>{this.props.message}</p>
                        {collectDataElements}
                        {button}
                    </Offcanvas.Body>
                </Offcanvas>
                <QRModalWindow show={this.state.showModal} error={this.state.error} response={this.state.qrData}/>
            </>

        )
    }
}

export default withOktaAuth(withRouter(OffcanvasOperationButton));