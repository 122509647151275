import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './campaignButtons.css';

import CampaignService from '../../../../services/CampaignService';
import AlertService from '../../../../services/AlertService';

import HttpUtil from '../../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';
import StopCampaignModal from "./StopCampaignModal"

class CampaignOperationsButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.campaignId,
            oktaAuth: this.props.oktaAuth,
            show: false
        };
    }

    renderButton(placement, title, color, icon, onClick, disabled) {
        var button = <button type="button" data-tid={title} className={"btn btn-circle rps-action-btn " + color} onClick={onClick}><i className={`bi ${icon}`} /></button>;

        if (disabled) {
            button = <button type="button" data-tid={title} className={"btn btn-circle rps-action-btn " + color} disabled><i className={`bi ${icon}`} /></button>;
        }

        return (
            <OverlayTrigger placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}>{title}</Tooltip>}>
                {button}
            </OverlayTrigger>
        );
    };

    renderTimeSlicingButton(placement, title, color, icon, onClick, campaignStatus, sqsStatus) {

        var button = <button type="button" data-tid={title} className={"btn btn-circle rps-action-btn " + color} onClick={onClick}><i className={`bi ${icon}`} /></button>;
        
        if(title === "Cancel Campaign Processing"){
            button = <button 
            type="button" 
            data-tid={title} 
            className={"btn btn-circle rps-action-btn " + color} 
            onClick={onClick}><i 
            className={`bi ${icon}`}
            data-toggle="modal"
            data-target={'#activateModal'}
            data-tid={"activateModal"}
            /></button>;
        }

        let toolTipTitle = title;
        if(sqsStatus === 'COMPLETE' && (campaignStatus === 'IN_PROGRESS' || campaignStatus === 'PROCESSING')){
            toolTipTitle = "All Messages Processed, Pending Campaign Completion"
        }
        

        if (campaignStatus === 'COMPLETED' || campaignStatus === 'PENDING' || campaignStatus === 'ERROR') {
            button = <button type="button" data-tid={title} className={"btn btn-circle rps-action-btn " + color} disabled><i className={`bi ${icon}`} /></button>;
        } else if (campaignStatus === 'PROCESSING' || campaignStatus === 'IN_PROGRESS') {
            if (title === "Resume Campaign Processing" || sqsStatus === 'COMPLETE') {
                button = <button type="button" data-tid={toolTipTitle} className={"btn btn-circle rps-action-btn " + color} disabled><i className={`bi ${icon}`} /></button>;
            }
        } else if (campaignStatus === 'CANCELLED') {
            if (title === "Resume Campaign Processing" || title === "Pause Campaign Processing" || title === "Cancel Campaign Processing") {
                button = <button type="button" data-tid={title} className={"btn btn-circle rps-action-btn " + color} disabled><i className={`bi ${icon}`} /></button>;
            }
        } else if (campaignStatus === 'PAUSED') {
            if (title === "Pause Campaign Processing") {
                button = <button type="button" data-tid={title} className={"btn btn-circle rps-action-btn " + color} disabled><i className={`bi ${icon}`} /></button>;
            } 
        }

        return (
            <OverlayTrigger placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}>{toolTipTitle}</Tooltip>}>
                {button}
            </OverlayTrigger>
        );
    }

    renderDeleteButton(isButtonDisabled) {
        var disableButton = (<button type="button" className={"btn btn-circle rps-action-btn btn-danger"} data-tid="delete-campaign" data-toggle="modal" data-target="#deleteCampaignModal"><i className="bi bi-x-circle"></i></button>);

        if (isButtonDisabled === false) {
            // added the disable attribute
            disableButton = (<button type="button" className={"btn btn-circle rps-action-btn btn-danger"} data-tid="delete-campaign" data-toggle="modal" data-target="#deleteCampaignModal" disabled><i className="bi bi-x-circle"></i></button>);
        };

        return (
            <>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Delete Campaign</Tooltip>}>
                    {disableButton}
                </OverlayTrigger>

                <div className="modal fade" id="deleteCampaignModal" tabIndex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteCampaignModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteCampaignModalLabel">Delete Campaign</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <span>Are you sure you want to delete this campaign!</span>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                                <button type="button" className="btn btn-danger" data-cy="delete-prompt-button" onClick={e => this.onDelete(this.props.campaignId)}>Yes, Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }


    onStartCampaign(id) {
        const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
        CampaignService.startCampaign(id, config).then((response) => {
            AlertService.showAlert("Info", "Campaign Progress", "Campaign started!");
            this.props.refresh();
        }).catch((e) => {
            console.log("error starting campaign", e);
        });
    }

    onDelete(id) {
        const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
        CampaignService.deleteCampaign(id, config).then((response) => {
            if(this.props?.consumerOperation){
                window.location.href = "/qrCampaigns";
            } else{
                window.location.href = "/campaigns";
            }


        }).catch((e) => {
            var errorMessage = e.message;
            if(this.props?.consumerOperation){
                window.location.href = "/qrCampaigns?id=" + id + "&error=" + errorMessage;
            } else{
                window.location.href = "/campaigns?id=" + id + "&error=" + errorMessage;
            }

        });
    }

    onGenerateReport(id) {
        const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
        CampaignService.generateCampaignReport(id, config)
            .then((response) => {
                AlertService.showAlert("Info", "Campaign Report", "Report currently being generated!");
                this.props.refresh();
            }).catch((e) => {
                console.log("error generating campaign report", e);
            });
    }

    timeSlicingControls(id, operation) {
        const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
        CampaignService.timeSlicingControls(id, operation, config)
            .then((response) => {
                if (operation === 'resume') {
                    operation = 'resumed';
                    AlertService.showAlert("Info", "Campaign Processing", `Campaign processing has been ${operation}`);
                } else if (operation === 'pause') {
                    operation = 'paused';
                }
                this.props.refresh();
            }).catch((e) => {
                console.log("error generating campaign report", e);
            });
    }

    onDownloadReport(id) {
        const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
        CampaignService.downloadCampaignReport(id, config)
            .then((response) => {
                const downloadUrl = response.data.downloadUrl;
                fetch(downloadUrl, { method: 'GET', headers: { 'Content-Type': 'application/csv' } })
                    .then((response) => {
                        return response.blob();
                    })
                    .then((blob) => {
                        const href = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'campaign-report-' + id + '.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });

            }).catch((e) => {
                console.log("error on download campaign report", e);
            });
    }

    toggleModalWindow = () => {
        this.setState({showModal: true})
    }

    render() {
        var isStartCampaignButtonDisabled = true;
        var isGenereateCampaignButtonDisabled = true;
        var isDownloadCampaignButtonDisabled = true;
        var isDeleteCampaignButtonDisabled = true;

        if (this.props.campaignStatus !== undefined) {
            // start campaign button visibility check
            if ((this.props.campaignStatus === "PENDING")) {
                isStartCampaignButtonDisabled = false;
            }

            // generate campaign report button visibility check
            if ((this.props.campaignStatus !== "PENDING") && (this.props.campaignReportStatus !== "IN_PROGRESS")) {
                isGenereateCampaignButtonDisabled = false;
            }

            // download campaign report button visibility check
            if ((this.props.campaignReportStatus === "GENERATED")) {
                isDownloadCampaignButtonDisabled = false;
            }

            // delete campaign button visibility check
            if ((this.props.campaignStatus === "IN_PROGRESS") || (this.props.campaignStatus === "PAUSED")) {
                isDeleteCampaignButtonDisabled = false;
            }

        }

        return (
            <><div className={'click-row col-md-12 mb-4 click-card col-xl-' + this.props.colWidth}>
                <div className={'card shadow py-2 border-left-' + this.props.color}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className={'text-xs font-weight-bold text-uppercase mb-1 text-' + this.props.color}>{this.props.name}</div>
                                <div className={'h6 mb-0 text-gray-800'}>
                                    <div className="action-btns">

                                        {/* start campaign */}
                                        {this.renderButton("bottom", "Start Campaign", "btn-success", "bi-play-circle", e => this.onStartCampaign(this.props.campaignId), isStartCampaignButtonDisabled)}
                                        {/* refresh campaign */}
                                        {this.renderButton("bottom", "Refresh Campaign", "btn-info", "bi-arrow-counterclockwise", this.props.refresh, false)}

                                        {<>
                                            <span className="separator" />
                                            {this.renderTimeSlicingButton("bottom", "Resume Campaign Processing", "btn-success", "bi-play-circle", e => this.timeSlicingControls(this.props.campaignId, 'resume'), this.props.campaignStatus, this.props.sqsStatus)}
                                            {this.renderTimeSlicingButton("bottom", "Pause Campaign Processing", "btn-primary", "bi-pause-circle", e => this.timeSlicingControls(this.props.campaignId, 'pause'), this.props.campaignStatus, this.props.sqsStatus)}
                                            {this.renderTimeSlicingButton("bottom", "Cancel Campaign Processing", "btn-danger", "bi-stop-circle", e => this.toggleModalWindow(), this.props.campaignStatus, this.props.sqsStatus)}</>

                                        }

                                        <span className="separator" />

                                        {/* generate & download campaign report */}
                                        {this.renderButton("bottom", "Generate Campaign Report", "btn-primary", "bi-clipboard-data", e => this.onGenerateReport(this.props.campaignId), isGenereateCampaignButtonDisabled)}
                                        {this.renderButton("bottom", "Download Campaign Report", "btn-primary", "bi-download", e => this.onDownloadReport(this.props.campaignId), isDownloadCampaignButtonDisabled)}

                                        <span className="separator" />

                                        {/* delete campaign */}
                                        {this.renderDeleteButton(isDeleteCampaignButtonDisabled)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StopCampaignModal campaignId={this.props.campaignId} refresh={this.props.refresh}/>
            </>
        );
    }
};

export default withOktaAuth(CampaignOperationsButtons);
