import http from "../api/http-common";

const getNews = (config) => {
    return http.get("/rsp/dashboard/news/", config);
};

const getSidebarNews = (config) => {
    return http.get("/rsp/dashboard/news/sidebar", config);
};

function orderByRecordNumber(content){
    content = content.sort(function (a, b) {
      // find a record number
      let numA = 0;
      if (a.recordNumber !== null){
        numA = a.recordNumber;
      }

      // find b record number
      let numB = 0;
      if (b.recordNumber !== null){
        numB = b.recordNumber;
      }

      return numA - numB;
    });

    return content;
  }

const functions = {
    getNews,
    getSidebarNews,
    orderByRecordNumber
};

export default functions;
