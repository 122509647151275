import http from "../api/http-common";

const getAll = (config) => {
    return http.get("/rsp/inventory/profile", config);
};

const search = (config) => {
  return http.get("/rsp/inventory/profile/search/", config);
};

const getProfileTypes = (config) => {
  return http.get("/rsp/inventory/profile/profileTypes", config);
};

function getRequestParams(page, size, search){
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["size"] = size;
  }

  if (search) {
    params["searchFilter"] = search;
  }

  return params;
};

const functions = {
  getAll,
  search,
  getRequestParams,
  getProfileTypes
};

export default functions;
