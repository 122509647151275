import React, { Component } from "react";
import './pagination.css'

class Pagination extends Component {

    createPaginationButtonItem(pageNumber, currentPage){
        var page = {};

        // set index and title
        page.index = pageNumber;
        // +1 so that page count doens't start on zero
        var title = pageNumber + 1;
        page.title = title;
        
        // is the page the selected page - if `true` then set `disable`
        page.currentPage = "";
        if (currentPage.toString() === pageNumber.toString()){
            page.currentPage = "disabled";
        }

        return page;
    }

    renderPreviousButton(){
        let disabled = "";
        if ( (this.props.previous === undefined) || (this.props.previous === null) ){
            disabled = "disabled"
        }
        return (
            <li className={'page-item ' + disabled}>
                <span className="page-link clickable noselect" onClick={() => this.props.handlePreviousPageChange(this.props.previous)}>Previous</span>
            </li>
        );
    }

    renderNextButton(){
        let disabled = "";
        if ( (this.props.next === undefined) || (this.props.next === null) ){
            disabled = "disabled"
        }
        return (
            <li className={'page-item ' + disabled}>
                <span className="page-link clickable noselect" onClick={() => this.props.handleNextPageChange(this.props.next)}>Next</span>
            </li>
        );
    }

    render(){ 
        return <div className="col-sm-12 col-md-6">
            <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-end">

                {/* <!-- Previous Page Button --> */}
                {this.renderPreviousButton()}

                {/* <!-- Next Page Button --> */}
                {this.renderNextButton()}
            </ul>
            </nav>
        </div>
    }
};
    
export default Pagination;