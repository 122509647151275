import React, { Component } from "react";

class AuditItem extends Component {
      fetchOperationExecutionStatus(){
      let textColor = "text-light";
      let spanColor = "bg-success";
  
      switch (this.props.auditRecord.operationExecutionStatus.status) {
          case 'EXECUTED_SUCCESS' :
              this.props.auditRecord.operationExecutionStatus.statusCodeData = {message: "", reason: "", subject: "", subjectIdentifier: ""}
              break
          case 'EXECUTED_WITHWARNING' :
              textColor = "text-dark";
              spanColor = "bg-warning";
              break
          case 'FAILED' :
          case 'EXPIRED' :
              spanColor = "bg-danger";
              break
          // for notifications
          default :
              this.props.auditRecord.operationExecutionStatus.status = "NOTIFICATION";
              this.props.auditRecord.operationExecutionStatus.statusCodeData = {message: "", reason: "", subject: "", subjectIdentifier: ""}
              spanColor = 'bg-info'
              break
      }
      return <span className={'badge ' + textColor + ' ' + spanColor}>{this.props.auditRecord.operationExecutionStatus.status}</span>;
    }

    formatDate(){
        var date = new Date(this.props.auditRecord.operationDate);
        return date.toString()
    }

    convertOperationTypeToReadableString(){
        var operation = this.props.auditRecord.operationType;
        
        switch(operation) {
          case "EUICC_CAPABILITY_AUDIT": {
            return <><i className="bi bi-bug"></i><span> Audit EIS</span></>;
          }
          case "CREATE_ISDP": {
            return <><i className="bi bi-cloud-arrow-down"></i><span> Download Profile (Create ISDP)</span></>;
          }
          case "ENABLE_PROFILE": {
            return <><i className="bi bi-file-check"></i><span> Enable Profile</span></>;
          }
          case "DISABLE_PROFILE": {
            return <><i className="bi bi-file-minus"></i><span> Disable Profile</span></>;
          }
          case "DELETE_PROFILE": {
            return <><i className="bi bi-file-excel"></i><span> Delete Profile</span></>;
          }
          case "NOTIFICATION_FIRST_NETWORK_ATTACH": {
            return <><i className="bi bi-filter-circle"></i><span> First Network Attach</span></>;
          }
          case "NOTIFICATION_PROFILE_CHANGE_SUCCEEDED": {
            return <><i className="bi bi-check"></i><span> Profile Change Successful Notification</span></>;
          }
          case "MASTER_DELETE": {
            return <><i className="bi bi-file-x-fill"></i><span> Master Delete</span></>;
          }
          case "SET_FALLBACK_ATTRIBUTE":{
            return <><i className="bi bi-arrow-left-circle"></i><span> Set Fallback Attribute</span></>;
          }
          case "ESTABLISH_ISDR_KEYSET": {
            return <><i className="bi bi-key"></i><span> Establish ISD-R Keyset</span></>;
          }
          case "FINALISE_ISDR_HANDOVER": {
            return <><i className="bi bi-box-arrow-in-right"></i><span> Finalise ISD-R Handover</span></>;
          }
          case "NOTIFICATION_PROFILE_CHANGE_FAILED_ROLLBACK": {
            return <><i className="bi-x"></i><span> Profile Change Failed Notification - Rollback</span></>;
          }
          case "NOTIFICATION_PROFILE_CHANGE_AFTER_FALLBACK": {
            return <><i className="bi bi-filter-circle-fill"></i><span> Profile Change - Fallback</span></>;
          }
          default: {
            return <><i className="bi bi-question-circle"></i><span> {operation}</span></>;
          }
        }
    }

    render(){
        return <tr>
            <td className="align-middle"><strong>{this.convertOperationTypeToReadableString()}</strong></td>
            <td className="align-middle">{this.formatDate()}</td>
            <td className="align-middle">{this.props.auditRecord.iccid === null ? "-" : this.props.auditRecord.iccid}</td>
            <td className="align-middle">{this.fetchOperationExecutionStatus()}</td>
            <td className="align-middle">{this.props.auditRecord.operationExecutionStatus.statusCodeData.subject === "" ? "-" : this.props.auditRecord.operationExecutionStatus.statusCodeData.subject}</td>
            <td className="align-middle">{this.props.auditRecord.operationExecutionStatus.statusCodeData.reason === "" ? "-" : this.props.auditRecord.operationExecutionStatus.statusCodeData.reason}</td>
            <td className="align-middle">{this.props.auditRecord.operationExecutionStatus.statusCodeData.message === "" ? "-" : this.props.auditRecord.operationExecutionStatus.statusCodeData.message}</td>
        </tr>
    }
};

export default AuditItem;
