import React from 'react';

const Error = () => (
    <div className="text-center">
        <div className="error mx-auto" data-text="404">404</div>
        <p className="lead text-gray-800 mb-5">Page Not Found</p>
        <a href="/dashboard">← Back to Dashboard</a>
    </div>
)

export default Error;