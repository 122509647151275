import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";

import AlertService from '../../../services/AlertService';
import AdminPortalService from '../../../services/AdminPortalService';
import DateToggle from "../../Date/DateToggle"
import Spinner from 'react-bootstrap/Spinner';

import './feature-toggle.css'

class FeatureToggles extends Component {
    constructor(props) {
		super(props);
        this.state = {
            dateToggleFormat: 1,
            oktaAuth: this.props.oktaAuth,
            isLoading: true,
            features: null,
            editFeature: null
        }
        this.toggleDateFormat = this.toggleDateFormat.bind(this);
    }

    // API Integration Functions

    getFeatureList(){
        AdminPortalService.listFeature(this.state.oktaAuth.getAccessToken())
        .then((response) => {
            // sort by name
            let sortByName = AdminPortalService.sortByField(response.data, "created")

            this.setState({
                features: sortByName,
                isLoading: false
            })
        })
        .catch((e) => {
            console.log(`Error occurred in AdminPortalService createNewFeature: ${e}`);
            this.setState({
                isLoading: false
            })
        });
    }

    handleSubmitCreateFeature = (event) => {
        event.preventDefault();

        const name = event.target.inputName.value
        const description = event.target.inputDescription.value

        const feature = {
            name,
            description
        }
        
        AdminPortalService.createNewFeature(feature, this.state.oktaAuth.getAccessToken())
        .then((response) => {
            AlertService.showAlert("Success", "A new Feature Toggle Created", `Create the "${event.target.inputName.value}" Feature Toggle!"`);

            // reload features
            this.getFeatureList();
        })
        .catch((e) => {
            AlertService.showAlert("Danger", "Failed to create a new Feature Toggle Created", e.message);
        });

        // handle modal window closure
        document.getElementById("addFeatureModal").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop").forEach(el => el.classList.remove("modal-backdrop"));
    }

    handleSubmitEditFeature = (event => {
        event.preventDefault();

        const id = event.target.inputId.value
        const name = event.target.inputName.value
        const description = event.target.inputDescription.value

        const feature = {
            id,
            name,
            description
        }

        AdminPortalService.updateNewFeature(feature, this.state.oktaAuth.getAccessToken())
        .then((response) => {
            AlertService.showAlert("Success", "Feature Toggle Updated Created", `Feature Toggle "${feature.name}" updated!"`);

            // reload features
            this.getFeatureList();
        })
        .catch((e) => {
            AlertService.showAlert("Danger", "Failed to update the Feature Toggle", e.message);
        });

        // handle modal window closure
        document.getElementById("editFeatureModal").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop").forEach(el => el.classList.remove("modal-backdrop"));        
    })

    handleOnClickEditFeature = (feature) => {
        this.setState({editFeature: feature})
    }

    // Component Utils Functinos

    toggleDateFormat(index){
        let newIndex = (index === 3) ? 1 : (index + 1)
        this.setState({dateToggleFormat: newIndex})
    }

    displayFeatures(){
        let features;
        if (this.state.features === null){
            let message = (<i className="bi bi-exclamation-triangle"> No features found</i>)
            if (this.state.isLoading === true){
                message = (<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>)
            }
            features = (<tr><td colSpan="8">{message}</td></tr>)
        }else{
            features = []
            this.state.features.forEach(feature => {
                features.push(
                    <tr key={feature.id}>
                        <td>{feature.name}</td>
                        <td>{feature.description}</td>
                        <DateToggle value={feature.created} type={this.state.dateToggleFormat} toggleFunction={this.toggleDateFormat}/>
                        <DateToggle value={feature.lastUpdated} type={this.state.dateToggleFormat} toggleFunction={this.toggleDateFormat}/>
                        <td>{this.editFeatureModal(feature)}</td>
                    </tr>
                )
            })
        }
        return features
    }

    // Modal Window Functinos

    handleEditInput = (event) => {
        let editFeature = this.state.editFeature;
        editFeature.description = event.target.value
        this.setState({editFeature: editFeature})
    }

    handleInput = (event) => {}

    addFeatureModal(){
        return (
            <>
                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addFeatureModal">
                    <i className="bi bi-plus-square"></i> Add Feature Toggle
                </button>

                <div className="modal fade" id="addFeatureModal" tabIndex="-1" role="dialog" aria-labelledby="addFeatureModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addFeatureModalLabel">Add Feature Toggle</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                            <form onSubmit={this.handleSubmitCreateFeature}>
                                <div className="modal-body">
                                        <div className="form-group text_left">
                                            <label htmlFor="inputName" className="form-label title-bold">Name</label>
                                            <input type="name" className="form-control" id="inputName" placeholder="Enter name" required onChange={this.handleInput}/>
                                        </div>
                                        <div className="form-group text_left">
                                            <label htmlFor="inputDescription" className="form-label title-bold">Description</label>
                                            <input type="description" className="form-control" id="inputDescription" placeholder="Enter description" required onChange={this.handleInput}/>
                                        </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    editFeatureModal(feature){
        return (
            <>
                <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target={"#editFeatureModal"} onClick = {() => this.handleOnClickEditFeature(feature)}>
                    <i className="bi bi-pencil"></i>
                </button>

                <div className="modal fade" id={"editFeatureModal"} tabIndex="-1" role="dialog" aria-labelledby={"editFeatureModalLabel"} aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={"editFeatureModalLabel"}>Edit Feature Toggle</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                            <form onSubmit={this.handleSubmitEditFeature}>
                                <div className="modal-body">
                                    <div className="form-group text_left">
                                        <input type="id" className="form-control" id="inputId" placeholder="Enter Id" value={this.state.editFeature?.id || ''} readOnly hidden/>
                                    </div>
                                    <div className="form-group text_left">
                                        <label htmlFor="inputName" className="form-label title-bold">Name</label>
                                        <input type="name" className="form-control" id="inputName" placeholder="Enter name" value={this.state.editFeature?.name || ''} readOnly/>
                                    </div>
                                    <div className="form-group text_left">
                                        <label htmlFor="inputDescription" className="form-label title-bold">Description</label>
                                        <input type="description" className="form-control" id="inputDescription" placeholder="Enter description" value={this.state.editFeature?.description || ''} required onChange={this.handleEditInput}/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // ReactJS Functinos

    componentDidMount(){
        this.getFeatureList()
    }

    // render

    render() {
		return (
            <>
                <div className="row">
                    <div id="alert-banner" className="col-lg-12 d-grid" />
                </div>

                <div className="row">
                    <div className="col-lg-12 d-grid">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    <i className="bi bi-list"></i> Features
                                </h6>
                            </div>
                            <div className="card-body">
                            <div id="alert-form" className="col-lg-12 d-grid" />
                                <div className="table-responsive">
                                    <div id="buttonPanel">
                                        {this.addFeatureModal()}
                                    </div>
                                    <table className="table table-striped table-hover" data-tid="cold-eis-table">
                                        <thead>
                                            <tr>
                                                <th className="col-md-2">Name</th>
                                                <th className="col-md-3">Description</th>
                                                <th className="col-md-3">Created</th>
                                                <th className="col-md-3">Last Updated</th>
                                                <th className="col-md-1">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.displayFeatures()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
		);
	}
}

export default withOktaAuth(withRouter(FeatureToggles));