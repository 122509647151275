import React, { Component } from 'react';

//Navigation
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';

import Swagger from '../../components/Swagger/Swagger.js';
import Footer from '../../components/Footer';

class CreateCampaign extends Component {
  render() {
    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          <Sidebar active="Api"/>

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">

            {/* <!-- Main Content --> */}
            <div id="content">
              <Topbar icon="bi-file-binary" title="API Documentation"/>

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">   {/* CREATE CAMPAIGN MAIN CONTENT HERE */}
                
                {/* <!-- Swagger Information --> */}
                  <div className="card shadow mb-12 click-card">
                    <div className="card-body">
                      <Swagger />
                    </div>
                  </div>
                
              </div>
              {/* <!-- /.container-fluid --> */}

            </div>
            {/* <!-- End of Main Content --> */}

            <Footer />
          </div>
          {/* <!-- End of Content Wrapper --> */}

        </div>
        {/* <!-- End of Page Wrapper --> */}
      </div>
    )
  }
}

export default CreateCampaign;