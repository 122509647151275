import React, {Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';

class CountPerMonthCard extends Component {
    constructor(props) {
        super(props);   
        
        this.state = {
            // height: 100,
            colWidth: this.configureColWidth(this.props.colWidth),
            // counts:[],
            oktaAuth: this.props.oktaAuth,
            // months: this.props.months
        };
    }

    configureColWidth(colWidthFromProps){
        let colWidth = 6; // default value
        if (colWidthFromProps !== undefined){
            colWidth = colWidthFromProps;
        }
        return colWidth;
    }

    render(){
        return <div className={'d-grid col-xl-' + this.state.colWidth}>
            <div className="card shadow mb-4 h-100">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className={"m-0 font-weight-bold text-" + this.props.color}>{this.props.title} (per month)</h6>
                </div>
                <div className="card-body" >
                    <p>{this.props.message}</p>
                </div>
            </div>
        </div>;
    }
}

export default withOktaAuth(CountPerMonthCard);
