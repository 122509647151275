import React, { Component } from "react";
import "./campaign.css"
import CampaignService from '../../../services/CampaignService';
import HttpUtil from '../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';
import AlertOperationRequest from "../single-view/CampaignOperations/AlertOperationRequest.js";
import ReactDOM from 'react-dom'
let synchronousTypes  = ["update-imsi", "update-msisdn", "cold-storage-eid",  "cold-storage-iccid"];
class CreateCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            minVp: 1,   //1 seconds
            maxVp: 1800, //30 min
            type: "download-profile",
            name: "",
            vp: 1200,
            enabled: false,
            csvFile: undefined,
            oktaAuth: this.props.oktaAuth,
            coldStorageAccess: false
        }
    }

    hasColdStorageRole() {
        if(this.props.roles){
            const claimKeys = Object.keys(this.props.roles);
            const roleKey = claimKeys?.find(key => /roles_/.test(key));
            const roles = this.props.roles[roleKey];
            const cold_storage_role = roles?.find(value => /cold_storage/.test(value));
            if(cold_storage_role){
                return true;
            }
        }
        return false;
    }

    componentDidMount(){
        if(this.hasColdStorageRole()) {
            this.setState({coldStorageAccess: true})
        }

    }

    renderModalOpenButton = (title) => {
        return (
            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#createCampaignModal" data-tid="new-campaign">
                <i className="bi bi-plus-square"></i> {title}
            </button>
        )
    }

    renderModalWindow = (title) => {
        return (
            <>
                <div className="modal fade campaignModal" id="createCampaignModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="createCampaignModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="createCampaignModalLabel">{title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetModalWindow}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form name="createCampaign" onSubmit={e => this.createCampaign(e)} aria-label="Create a new Campaign">
                                <div className="modal-body">

                                    {this.renderCampaignName()}
                                    {this.renderCampaignType(this.state.type)}
                                    <hr />
                                    {this.uploadCSV()}
                                    <hr />
                                    {this.renderCampaignValidityPeriod(this.state.minVp, this.state.maxVp)}
                                    {this.renderCampaignEnabledProfile(this.state.type)}

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.resetModalWindow}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" data-tid="create-campaign">Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderCampaignName() {
        // Set Campaign Name
        return (
            <div className="mb-3">
                <label htmlFor="campaignName" className="form-label title-bold">Campaign Name</label>
                <input type="text" className="form-control" id="campaignName" placeholder="Enter new campaign name"
                    maxLength="60" data-tid="campaign-name" onChange={this.changeCampaignName} required pattern="^\S.+" />
            </div>
        )
    }

    renderCampaignType(type) {
        // Set Campaign Type
        return (
            <>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label title-bold">Campaign Type</label>
                    <select id="campaignType" className="form-select form-select-lg mb-3 campaignType" aria-label=".form-select-lg example"
                        defaultValue="download-profile" aria-describedby="basic-addon2" data-tid="campaign-type" onChange={this.changeCampaignType}>
                        <option value="download-profile">Download Profile</option>
                        <option value="enable-profile">Enable Profile</option>
                        <option value="disable-profile">Disable Profile</option>
                        <option value="delete-profile">Delete Profile</option>
                        <option value="set-fallback-attribute">Set Fallback Attribute</option>
                        <option value="audit-eis">Audit EIS</option>
                        <option value="update-msisdn">Update MSISDN</option>
                        <option value="update-imsi">Update IMSI</option>
                        {this.state.coldStorageAccess? <option value="cold-storage-eid">Cold Storage - EID</option>: null}
                        {this.state.coldStorageAccess? <option value="cold-storage-iccid">Cold Storage - ICCID</option>: null}
                    </select>
                </div>
                <div className="mb-3">
                    {this.renderCampaignDescription(type)}
                </div>
            </>
        )
    }

    renderCampaignDescription(type) {
        var campaignDescription = "";

        if (type === "") {
            campaignDescription = <span><i className="bi bi-info-circle"></i> Please select a camping type for a description.</span>

        } else if (type === "download-profile") {
            campaignDescription = <span><i className="bi bi-cloud-arrow-down"></i> Downloads a new profile onto your EIS, with the option to also enable it if desired.</span>

        } else if (type === "enable-profile") {
            campaignDescription = <span><i className="bi bi-file-check"></i> Enables one of the operational profiles on your eSIMs</span>

        } else if (type === "disable-profile") {
            campaignDescription = <span><i className="bi bi-file-minus"></i> Disables the specified profile, thereby enabling the fallback profile.</span>

        } else if (type === "delete-profile") {
            campaignDescription = <span><i className="bi bi-file-excel"></i> Deletes the specified profile.</span>

        } else if (type === "audit-eis") {
            campaignDescription = <span><i className="bi bi-bug"></i> Communicate with the EIS to resynchronize with the SM-SR server.</span>
        }
        else if (type === "set-fallback-attribute") {
            campaignDescription = <span><i className="bi bi-arrow-counterclockwise"></i> Sets the fallback attribute on the targeted profile. Note, this will unset the previous fallback profile as only one profile can have this attribute set.</span>
        }
        else if (type === "update-msisdn") {
            campaignDescription = <span><i className="bi bi-pencil-square"></i> Updates the MSISDN value on the targeted profile.</span>
        }
        else if (type === "update-imsi") {
            campaignDescription = <span><i className="bi bi-pencil-square"></i> Updates the IMSI value on the targeted profile.</span>
        }

        return campaignDescription;
    }

    uploadCSV() {
        return (
            <>
                <div className="mb-3">
                    <label htmlFor="campaignName" className="form-label title-bold">Upload CSV</label>
                </div>
                <div className="mb-3">
                <div id="alert-form" className="col-lg-12 d-grid" />
                    <label className="btn btn-default">
                        <input id="campaignCSV" type="file" data-tid="campaign-csv-upload" onChange={this.selectFile} accept=".csv" required />
                    </label>
                </div>
            </>
        )
    }

    renderCampaignValidityPeriod(min, max) {
        // Set Validity Period for Operations
        if(!synchronousTypes.includes(this.state.type)){
            return (
                <div className="mb-3">
                    <label htmlFor="campaignVP" className="form-label title-bold">Validity Period</label>
                    <input type="number" className="form-control" id="campaignVP" placeholder={this.state.vp}
                           min={min} max={max} onChange={this.changeCampaignVP} />
                </div>
            )
        }

    }

    renderCampaignEnabledProfile(type) {
            // Enable Profile on Download
        let checkButton;
            if(type ==="download-profile") {
                 checkButton = <input className="form-check-input" type="checkbox" value="" id="campaignEnabled"
                                         data-tid="enable-profile-on-download" onChange={this.changeCampaignEnabled}/>
            }
                if (type !== "download-profile") {
                    checkButton = <input className="form-check-input" type="checkbox" value="" id="campaignEnabled" disabled />
                }
                    if(this.state.type ==="download-profile"){
                        return (
                            <>
                                <div className="mb-3" id="enableOption">
                                    <label className="form-label title-bold">Other Properties</label>
                                </div>
                                <div className="mb-3">
                                    <div className="form-check">
                                        {checkButton}
                                        <label className="form-check-label" htmlFor="campaignEnabled">Enable Profile</label>
                                    </div>
                                </div>
                            </>
                        )
                    }


            }


    resetModalWindow = () => {
        this.setState({
            title: this.props.title,
            minVp: 1,   //1 seconds
            maxVp: 1800, //30 min
            type: "download-profile",
            name: "",
            vp: 1200,
            enabled: false,
            csvFile: undefined,
            coldStorageAccess: this.hasColdStorageRole()
        });
        this.hideAlert();
        const campaignName = document.getElementById("campaignName");
        const campaignType = document.getElementById("campaignType");
        const campaignCSV = document.getElementById("campaignCSV");
        const campaignVP = document.getElementById("campaignVP");
        const campaignEnabled = document.getElementById("campaignEnabled");

        if (campaignName) {
            campaignName.value = "";
        }
        if (campaignType) {
            campaignType.value = "download-profile";
        }
        if (campaignCSV) {
            campaignCSV.value = "";
        }

        if (campaignVP) {
            campaignVP.value = 1200;
        }

        if (campaignEnabled) {
            campaignEnabled.checked = false;
        }
    }

    changeCampaignName = (event) => {
        this.setState({ name: event.target.value });
    }

    changeCampaignType = (event) => {
        this.setState({ type: event.target.value });
    }

    changeCampaignVP = (event) => {
        this.setState({ vp: event.target.value });
    }

    changeCampaignEnabled = (event) => {
        this.setState({ enabled: event.target.checked });
    }

    selectFile = (event) => {
        if (!/^([a-zA-Z0-9_\-,()*&.])+$/.test(event.target.files[0]["name"])) {
            this.showAlert("Danger", "File Name Error", "Invalid file name, Valid characters are A-Z a-z 0-9 & * _ -" );
            this.setState({ csvFile: undefined });
        } else {
            this.setState({ csvFile: event.target.files[0] });
            this.hideAlert();
        }
    }

    showAlert = (variant, heading, message) => {
        ReactDOM.render(
          <AlertOperationRequest  variant={variant} heading={heading} message={message} close={this.hideAlert}/>,
          document.getElementById('alert-form')
        );
      };
    
    hideAlert = () => {
        ReactDOM.render("", document.getElementById('alert-form'));
      };

    createCampaign(event) {
        event.preventDefault();
        

        // Validate Params
        if (!/^([a-zA-Z0-9_\-,()*&.])+$/.test(event["target"][2]["value"].split('\\').pop())) {
            this.showAlert("Danger", "File Name Error", "Invalid file name, Valid characters are A-Z a-z 0-9 & * _ -" );
            return;
        } else {

        if (event.target.checkValidity()) {
            // create campaign object
            var newCampaign = CampaignService.createNewCampaignObject(
                this.state.name,
                this.state.type,
                this.state.vp,
                this.state.enabled
            )

            const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());

            // create new campaign
            CampaignService.createCampaign(newCampaign, config).then((response) => {
                var createCampaign = response.data;
                var id = createCampaign.id;

                // upload CVS file to new campaign
                CampaignService.uploadCampaignCSV(id, this.state.csvFile, this.state.oktaAuth.getAccessToken())
                    .then((response) => {
                        window.location.href = "/campaigns?id=" + id;

                    }).catch((e) => {
                        this.setState({ content: [] });
                    });

            }).catch((e) => {
                this.setState({ content: [] });
            });
        }
    }}

    render() {
        return (
            <>
                {this.renderModalOpenButton(this.state.title)}
                {this.renderModalWindow(this.state.title)}
            </>
        )
    }
}

export default withOktaAuth(CreateCampaign);
