import React, { Component } from "react";
import { Spinner } from 'react-bootstrap';
import './campaign-single.css';

import CampaignAttribute from './CampaignAttribute';

class CampaignInfoTable extends Component {

  renderTableHeaders() {
    return (
        <thead>
        <tr>
          <th>Attribute</th>
          <th>Value</th>
        </tr>
        </thead>
    );
  }

  renderEmptyTable(isLoading) {
    let loadingContent = <td colSpan="2"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
    if (isLoading) {
      loadingContent = <td colSpan="2"><Spinner animation="border" size="sm" /></td>;
    }

    return (
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody>
            <tr>
              {loadingContent}
            </tr>
            </tbody>
          </table>
        </div>
    );
  }


  renderTable(){
    if(this.props.content?.created.startsWith("consumer")){
      this.props.content.created = this.props.content.created.replace(/^consumer-/,'')
    }

    let cretedTimestamp = this.formatDate(this.props.content?.created) + " at " +  this.formatTime(this.props.content?.created);



    return (
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody data-tid="campaign-details">
            <CampaignAttribute title="Name" value={this.props.content?.name} />
            <CampaignAttribute title="Author" value={this.props.content?.author} />
            <CampaignAttribute title="Status" value={this.props.content?.status} />
            {this.formatType(this.props.content?.type) === 'Generate QR' ? null : <CampaignAttribute title="Type" value={this.formatType(this.props.content?.type)} />}
            <CampaignAttribute title="Created" value={cretedTimestamp} />
            </tbody>
          </table>
        </div>
    );
  }

  formatType(type) {
    let typeFormat;
    switch (type) {

      case "download-profile":
        typeFormat = "Download Profile";
        break;

      case "enable-profile":
        typeFormat = "Enable Profile";
        break;

      case "disable-profile":
        typeFormat = "Disable Profile";
        break;

      case "delete-profile":
        typeFormat = "Delete Profile";
        break;

      case "set-fallback-attribute":
        typeFormat = "Set Fallback Attribute";
        break;

      case "audit-eis":
        typeFormat = "Audit EIS";
        break;

      case "update-msisdn":
        typeFormat = "Update MSISDN";
        break;

      case "update-imsi":
        typeFormat = "Update IMSI";
        break;

      case "generate-qr":
        typeFormat = "Generate QR";
        break;

      case "cold-storage-eid":
        typeFormat = "Cold Storage - EID";
        break;

      case "cold-storage-iccid":
        typeFormat = "Cold Storage - ICCID";
        break;

      default:
        console.log(`Unsupported type: "${type}"`);
    }
    return typeFormat;
  }

  formatDate(timestamp) {
    var time = new Date(timestamp);
    return time.toLocaleString('en-GB', { localeMatcher: "best fit", dateStyle: "long" });
  }

  formatTime(timestamp) {
    var time = new Date(timestamp);
    return time.toLocaleString('en-GB', { localeMatcher: "best fit", timeStyle: "medium" });
  }

  render() {
    var tableHTML = "";

    if (this.props.content === null) {
      tableHTML = this.renderEmptyTable(true);

    } else if (this.props.content.length === 0) {
      tableHTML = this.renderEmptyTable(false);

    } else {
      tableHTML = this.renderTable();
    }

    return (
        <div className={"d-grid col-lg-" + this.props.colWidth}>
          <div className="card shadow mb-4">

            <div className="card-body">
              <div className="campaignDetailsTable d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary"><i className="bi bi-lightning-charge"></i> Campaign Details</h6>
              </div>
              {/* <!-- campaign info table --> */}
              <div className="table-responsive">
                {tableHTML}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default CampaignInfoTable;