import ReactDOM from 'react-dom';
import RspOperationsService from "../services/RspOperationsService";
import ConsumerOperationsService from "../services/ConsumerOperationsService";
import AlertOperationRequest from "../components/EIS/single-view/rsp-operations/AlertOperationRequest";
import HttpUtil from '../api/http-util';
import React from "react";

export const getDefaultVP_GetEis = 35;
export const getDefaultVP_AuditEis = 900;
export const getDefaultVP_DownloadProfile = 1200;
export const getDefaultVP_EnableProfile = 900;
export const getDefaultVP_DisableProfile = 900;
export const getDefaultVP_DeleteProfile = 300;
export const getDefaultVP_SetFallbackAttribute = 300;

export default class OperationsService {

  static handleClickNotImplemented = () => {
    this.showAlert("Warning", "Not Implemented", "The operation you selected has not been integrated yet.");
  }

  // RSP Operations functions

  static handleClickGetEIS = (eid, accessToken) => {
    const data = { validityPeriod: getDefaultVP_GetEis }
    const config = HttpUtil.getAuthHeader(accessToken);

    RspOperationsService.es4GetEis(eid, data, config).then((response) => {
      this.showAlert("Info", "ES4 Get EIS", "SOAP Request sent.");
    }).catch((e) => {
      this.showAlert("Danger", "ES4 Get EIS", "Unable to communicate with Kigen RSP - " + e.message);
    });
  }

  static switchToInflightTab(eid) {
    document.getElementById("nav-link-inflight").click()
  }

  static handleClickAuditEis = (eid, accessToken) => {
    let selectedValidity = document.getElementById('validity-period').value || getDefaultVP_AuditEis;
    const data = { validityPeriod: selectedValidity };
    const config = HttpUtil.getAuthHeader(accessToken);

    RspOperationsService.es4AuditEis(eid, data, config).then((response) => {
      this.showAlert("Info", "ES4 Audit EIS", "SOAP Request sent.");
      this.switchToInflightTab(eid);
    }).catch((e) => {
      this.showAlert("Danger", "ES4 Audit EIS", "Unable to communicate with Kigen RSP - " + e.message);
    });
  }

  static handleClickDownloadProfile = (eid, accessToken) => {
    let selectedIccid = document.getElementById('selected-iccid').value;
    let selectedValidity = document.getElementById('validity-period').value || getDefaultVP_DownloadProfile;

    if (selectedIccid === "") {
      this.showAlert("Danger", "ES2 Download Profile", "Please select an Iccid for this Download Operation!");
    } else {
      var checkbox = document.getElementById('enabled-profile');
      let isEnableProfile = checkbox.checked;
      const data = { validityPeriod: selectedValidity, iccid: selectedIccid, enableProfile: isEnableProfile };
      const config = HttpUtil.getAuthHeader(accessToken);

      RspOperationsService.es2DownloadProfile(eid, data, config)
        .then((response) => {
          this.showAlert("Info", "ES2 Download Profile", "SOAP Request sent.");
          this.switchToInflightTab(eid);
        }).catch((e) => {
          this.showAlert("Danger", "ES2 Download Profile", "Unable to communicate with Kigen RSP - " + e.message);
        });
    }
  }

  static handleClickDownloadProfileByProfileType = (eid, accessToken) => {
    let selectedType = document.querySelector("[name='selectedType']").value
    let selectedValidity = document.getElementById('validity-period').value || getDefaultVP_DownloadProfile;

    if (selectedType === "") {
      this.showAlert("Danger", "ES2 Download Profile", "Please select a Profile Type for this Download Operation!");
    } else {
      var checkbox = document.getElementById('enabled-profile');
      let isEnableProfile = checkbox.checked;
      const data = { validityPeriod: selectedValidity, enableProfile: isEnableProfile, profileType:selectedType };
      const config = HttpUtil.getAuthHeader(accessToken);

      RspOperationsService.es2DownloadProfileByProfileType(eid, data, config)
          .then((response) => {
            this.showAlert("Info", "ES2 Download Profile", "SOAP Request sent.");
            this.switchToInflightTab(eid);
          }).catch((e) => {
        this.showAlert("Danger", "ES2 Download Profile", "Unable to communicate with Kigen RSP - " + e.message);
      });
    }
  }

  static handleClickEnableProfile = (eid, accessToken) => {
    let selectedIccid = document.getElementById('selected-iccid').value
    let selectedValidity = document.getElementById('validity-period').value || getDefaultVP_EnableProfile;

    if (selectedIccid === "-1") {
      this.showAlert("Danger", "ES4 Enable Profile", "Please select an Iccid for this Enable Operation!");
    } else {
      const data = { validityPeriod: selectedValidity, iccid: selectedIccid };
      const config = HttpUtil.getAuthHeader(accessToken);

      RspOperationsService.es4EnableProfile(eid, data, config).then((response) => {
        this.showAlert("Info", "ES4 Enable Profile", "SOAP Request sent.");
        this.switchToInflightTab(eid);
      }).catch((e) => {
        this.showAlert("Danger", "ES4 Enable Profile", "Unable to communicate with Kigen RSP - " + e.message);
      });
    }
  }

  static handleSetFallbackAttribute = (eid, accessToken) => {
    let selectedIccid = document.getElementById('selected-iccid').value
    let selectedValidity = document.getElementById('validity-period').value || getDefaultVP_SetFallbackAttribute;

    if (selectedIccid === "-1") {
      this.showAlert("Danger", "ES4 SetFallBackAttribute", "Please select an Iccid for this Set FallBackAttribute Operation!");
    } else {
      const data = { validityPeriod: selectedValidity, iccid: selectedIccid };
      const config = HttpUtil.getAuthHeader(accessToken);

      RspOperationsService.es4SetFallbackAttribute(eid, data, config).then((response) => {
        this.showAlert("Info", "ES4 SetFallBackAttribute", "SOAP Request sent.");
        this.switchToInflightTab(eid);
      }).catch((e) => {
        this.showAlert("Danger", "ES4 SetFallBackAttribute", "Unable to communicate with Kigen RSP - " + e.message);
      });
    }
  }

  static handleClickDisableProfile = (eid, accessToken) => {
    let selectedIccid = document.getElementById('selected-iccid').value;
    let selectedValidity = document.getElementById('validity-period').value || getDefaultVP_DisableProfile;

    if (selectedIccid === "-1") {
      this.showAlert("Danger", "ES4 Disable Profile", "Please select an Iccid for this Disable Operation!");
    } else {
      const data = { validityPeriod: selectedValidity, iccid: selectedIccid };
      const config = HttpUtil.getAuthHeader(accessToken);

      RspOperationsService.es4DisableProfile(eid, data, config).then((response) => {
        this.switchToInflightTab(eid);
        this.showAlert("Info", "ES4 Disable Profile", "SOAP Request sent.");
      }).catch((e) => {
        this.showAlert("Danger", "ES4 Disable Profile", "Unable to communicate with Kigen RSP - " + e.message);
      });
    }
  }

  static handleClickDeleteProfile = (eid, accessToken) => {
    let selectedIccid = document.getElementById('selected-iccid').value;
    let selectedValidity = document.getElementById('validity-period').value || getDefaultVP_DeleteProfile;

    if (selectedIccid === "-1") {
      this.showAlert("Danger", "ES4 Delete Profile", "Please select an Iccid for this Delete Operation!");
    } else {
      const data = { validityPeriod: selectedValidity, iccid: selectedIccid }
      const config = HttpUtil.getAuthHeader(accessToken);

      RspOperationsService.es4DeleteProfile(eid, data, config).then((response) => {
        this.showAlert("Info", "ES4 Delete Profile", "SOAP Request sent.");
        this.switchToInflightTab(eid);
      }).catch((e) => {
        this.showAlert("Danger", "ES4 Delete Profile", "Unable to communicate with Kigen RSP - " + e.message);
      });
    }
  }

  static updateSubscriptionAddress = (eid, iccid, imsi, msisdn, accessToken, refresh) => {
    const data = {
      iccid: iccid,
      imsi: imsi,
      msisdn: msisdn
    };
    const config = HttpUtil.getAuthHeader(accessToken);
    RspOperationsService.es4UpdateSubscriptionAddress(eid, data, config)
      .then((response) => {
        const domParser = new DOMParser();
        const soapResponse = domParser.parseFromString(response?.data?.soapResponse, "application/xml");

        const executionStatus = soapResponse.getElementsByTagNameNS("http://namespaces.gsma.org/esim-messaging/3", "Status")[0].innerHTML;

        let alertType = "";
        let errorMessage = "";
        if (executionStatus === "EXECUTED_SUCCESS" || executionStatus === "EXECUTED_WITH_WARNING") {
          alertType = "Info";
          refresh();
        } else {
          alertType = "Danger";
          errorMessage = " - " + soapResponse.getElementsByTagNameNS("http://namespaces.gsma.org/esim-messaging/3", "Message")[0].innerHTML;
        }

        this.showAlert(alertType, "ES4 Update Subscription Address", executionStatus + errorMessage);
      })
      .catch((e) => {
        this.showAlert("Danger", "ES4 Update Subscription Address", "Unable to communicate with Kigen RSP - " + e.message);
      });
  };

  static updatePolicyRules = (eid, data, accessToken, refresh) => {
    const config = HttpUtil.getAuthHeader(accessToken);
    RspOperationsService.es4UpdatePolicyRules(eid, data, config)
        .then((response) => {
          const domParser = new DOMParser();
          const soapResponse = domParser.parseFromString(response?.data?.soapResponse, "application/xml");

          const executionStatus = soapResponse.getElementsByTagNameNS("http://namespaces.gsma.org/esim-messaging/3", "Status")[0].innerHTML;

          let alertType = "";
          let errorMessage = "";
          if (executionStatus === "EXECUTED_SUCCESS" || executionStatus === "EXECUTED_WITH_WARNING") {
            alertType = "Info";
            refresh();
          } else {
            alertType = "Danger";
            errorMessage = " - " + soapResponse.getElementsByTagNameNS("http://namespaces.gsma.org/esim-messaging/3", "Message")[0].innerHTML;
          }

          this.showAlert(alertType, "ES4 Update Policy Rules", executionStatus + errorMessage);
        })
        .catch((e) => {
          this.showAlert("Danger", "ES4 Update  Policy Rules", "Unable to communicate with Kigen RSP - " + e.message);
        });
  };



  static handleGenerateActivationCode = (iccid, accessToken) => {
    let confirmationCode = document.getElementById('selected-confirmationCode').value;
    let eid = document.getElementById('selected-eid').value;
    let qr = 'true';

    const data = {
      iccid: iccid,
      confirmationCode: confirmationCode,
      qr: qr,
      eid: eid
    };

    const config = HttpUtil.getAuthHeader(accessToken);

    let response = ConsumerOperationsService.generateActivationCode(data, config).then((response) => {       
      return response
    }).catch((e) => {
      this.showAlert("Danger", "Generate Activation Codes", "Unable to communicate with Kigen Consumer - " + e.message);
    });
    return response
  }

  static shareActivationCode = (iccid, accessToken) => {
    let email = document.getElementById('selected-email').value;
    const data = {
      iccid: iccid,
      email : email
    };

    const config = HttpUtil.getAuthHeader(accessToken);
    
    let response = ConsumerOperationsService.shareActivationCode(data, config).then((response) => {
    }).catch((e) => {
    });
    return response;
  }

  // Alert functions
  static showAlert = (variant, heading, message) => {
    ReactDOM.render(
      <AlertOperationRequest variant={variant} heading={heading} message={message} close={this.hideAlert} />,
      document.getElementById('alert-banner')
    );
    setTimeout(() => this.hideAlert(), 5000);
  }



  static hideAlert = () => {
    ReactDOM.render("", document.getElementById('alert-banner'));
  }
};


