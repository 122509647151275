import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";

import AdminPortalService from '../../../services/AdminPortalService';

import {Tab, Col, Row, Nav, Spinner, Form} from 'react-bootstrap'

import './okta-environments.css'

class OktaEnvironments extends Component {
    constructor(props) {
		super(props);
        this.state = {
            oktaAuth: this.props.oktaAuth,
            isLoading: true,
            applications: null,
        }
    }

    // API Integration Applications

    getFeatureList(){
        AdminPortalService.listApplications(this.state.oktaAuth.getAccessToken())
        .then((response) => {
            this.setState({
                applications: response.data,
                isLoading: false
            })
        })
        .catch((e) => {
            console.log(`Error occurred in AdminPortalService listApplications: ${e}`);
            this.setState({
                isLoading: false
            })
        });
    }

    // ReactJS Functinos

    displayApplicationSSO(application){
        let type = "GUI"
        if (application?.name.toLowerCase().includes("api")){
            type = "API"
        }
        return (
            <>
                <Form.Text className="text-muted">
                    <p>This is the OKTA Single Sign On application that handles all <code>{type}</code> requests!</p>
                </Form.Text>
                
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control type="text" placeholder={application?.name} disabled/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>ID:</Form.Label>
                    <Form.Control type="text" placeholder={application?.id} disabled/>
                </Form.Group>

                {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                    <a href={application?.link} className="btn btn-primary stretched-link">Configure</a>
                </Form.Group>    */}
            </>
        )
    }

    componentDidMount(){
        this.getFeatureList()
    }

    displaySsoInformation(){
        let content = (        
            <Form>
                <h5><i className="bi bi-window"></i> GUI Application:</h5>
                {this.displayApplicationSSO(this.state.applications?.gui)}
                <hr/>
                <h5><i className="bi bi-file-earmark-binary"></i> API Application:</h5>
                {this.displayApplicationSSO(this.state.applications?.api)}
            </Form>
        )
        return content
    }

    // render

    render() {
        let isLoading = ""
        if (this.state.isLoading === true){
            isLoading = (
                <Spinner className="customerSpinner" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
            )
        }

		return (
            <>
                {/* Customer Information  */}
                <div className="row mb-4">
                    <div className="col-lg-12 d-grid">
                        <div className="row mb-12">
                            <div className="col-lg-12 d-grid">
                                <div className="card shadow mb-4 h-100">
                                    <div className="card-header py-3">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            <i className="bi bi-diagram-2"></i> Environment Information
                                            {isLoading}
                                        </h6>
                                    </div>
                                    <div className="card-body">
                                    <div id="alert-form" className="col-lg-12 d-grid" />
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="sso">
                                            <Row>
                                                <Col sm={3}>
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="sso">OAuth SSO Applications</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                </Col>
                                                <Col sm={9} className="tabcolum">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="sso">{this.displaySsoInformation()}</Tab.Pane>
                                                </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
		);
	}
}

export default withOktaAuth(withRouter(OktaEnvironments));