import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { withOktaAuth } from "@okta/okta-react"
import moment from "moment"

import {Tab, Col, Row, Nav, 
    Form, Button, Table, 
    Badge, OverlayTrigger, Tooltip} from 'react-bootstrap'

// import AlertService from '../../../../services/AlertService'
import AdminPortalService from '../../../../services/AdminPortalService'
import DateToggle from "../../../Date/DateToggle"
import Spinner from 'react-bootstrap/Spinner'

import '../admin-customers.css'

class CustomerSingle extends Component {
    constructor(props) {
		super(props)
        this.state = {
            dateToggleFormat: 1,
            oktaAuth: this.props.oktaAuth,
            isLoading: true,
            customer_id: this.props.customer_id,
            customer: null,
            customerIam: null,
            customerUsers: null,
            allFeatureToggles: null,
        }
        this.toggleDateFormat = this.toggleDateFormat.bind(this)
    }

    // API Integration Functions

    getCustomer(){
        AdminPortalService.getCustomer(this.state.oktaAuth.getAccessToken(), this.state.customer_id)
        .then((customer) => {
            this.setState({
                customer: customer.data,
                isLoading: false
            })
        })
        .catch((e) => {
            console.log(`Error occurred in AdminPortalService getCustomer: ${e}`)
            this.setState({
                isLoading: false
            })
        })
    }

    getCustomerIam(){
        AdminPortalService.getCustomerIam(this.state.oktaAuth.getAccessToken(), this.state.customer_id)
        .then((users) => {
            this.setState({
                customerIam: users.data,
                isLoading: false
            })
        })
        .catch((e) => {
            console.log(`Error occurred in AdminPortalService getCustomerIam: ${e}`)
            this.setState({
                isLoading: false
            })
        })
    }

    getCustomerUsers(){
        AdminPortalService.getCustomerUsers(this.state.oktaAuth.getAccessToken(), this.state.customer_id)
        .then((iam) => {
            this.setState({
                customerUsers: iam.data,
                isLoading: false
            })
        })
        .catch((e) => {
            console.log(`Error occurred in AdminPortalService getCustomerUsers: ${e}`)
            this.setState({
                isLoading: false
            })
        })
    }

    getListFeatureToggles(){
        AdminPortalService.listFeature(this.state.oktaAuth.getAccessToken(), this.state.customer_id)
        .then((features) => {
            // remove unwanted fields
            features.data.forEach(feature => {
                delete feature.created
                delete feature.lastUpdated
                feature.selected = false
            })

            this.setState({
                allFeatureToggles: features.data,
                isLoading: false
            })
        })
        .catch((e) => {
            console.log(`Error occurred in AdminPortalService getListFeatureToggles: ${e}`)
            this.setState({
                isLoading: false
            })
        })
    }

    // Component Utils Functinos

    toggleDateFormat(index){
        let newIndex = (index === 3) ? 1 : (index + 1)
        this.setState({dateToggleFormat: newIndex})
    }

    // ReactJS Functinos

    componentDidMount(){
        this.getCustomer()
        this.getCustomerIam()
        this.getCustomerUsers()
        this.getListFeatureToggles()
    }

    diplayCustomerInformationCard(){
        const customer = this.state?.customer
        let content = (
            <>    
                <h5>Customer Data</h5>
                <Form>   
                    <Form.Text className="text-muted">
                        <p>This is the customer's metadata.</p>
                    </Form.Text>
                    <Row>
                        <Col>
                            <b>Name:</b>
                            <p>{(customer?.name) ? customer?.name : ""}</p>
                        </Col>
                        <Col>
                            <span>
                                {(this.state.isLoading === false) 
                                    ? (<><b>Created:</b><DateToggle notTable={true} value={customer?.created} type={this.state.dateToggleFormat} toggleFunction={this.toggleDateFormat}/></>) 
                                    : (<>Created:</>)
                                }
                            </span>
                        </Col>
                    </Row>                                     
                    <Form.Group className="mb-3" controlId="formDescription">
                        <Form.Label><b>Description:</b></Form.Label>
                        {/* <Form.Control type="text" placeholder="Enter description" value={customer?.description} disabled/> */}
                        <Form.Control type="text" placeholder={customer?.description} disabled/>
                    </Form.Group>

                    {/* <Button variant="primary" type="submit" disabled>Update</Button> */}
                </Form>
            </>
        )
        return content
    }

    displayCustomersIAM(){
        let customerIam = this.state?.customerIam

        let content = (
            <>                
                <h5>IAM Credentials:</h5>
                <Form>
                    <Form.Text className="text-muted">
                        <p>The same IAM credentials will need to be configured in all downstream services!</p>
                        <p>These services are: <code>RSP M2M (SM-DP, SM-SR)</code>, <code>RSP Consumer (SM-DP+)</code> and <code>M2M IoT RSP (EIM)</code>.</p>
                    </Form.Text>

                    <Form.Group className="mb-3" controlId="formUsername">
                        <Form.Label>Username:</Form.Label>
                        {/* <Form.Control type="text" placeholder="Enter eSim Owner" value={customerIam?.iam_username} /> */}
                        <Form.Control type="text" placeholder={customerIam?.iam_username} disabled/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password:</Form.Label>
                        {/* <Form.Control type="password" placeholder={customerIam?.encryptedPassword} disabled/> */}
                        <Form.Control type="password" placeholder="**********" disabled/>
                    </Form.Group>

                    <h5>Organisation ID: (OIDs)</h5>
                    <Form.Text className="text-muted">
                        <p>These are the Entity ID for the customer, they're usually the same value, but could potentialy be differnet.</p>
                    </Form.Text>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formEsimowner">
                                <Form.Label><b>eSim Owner:</b></Form.Label>
                                {/* <Form.Control type="text" placeholder="Enter eSim Owner" value={customerIam?.eSimOwner} /> */}
                                <Form.Control type="text" placeholder={customerIam?.eSimOwner} disabled/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formMnoid">
                                <Form.Label><b>Mno Id:</b></Form.Label>
                                {/* <Form.Control type="text" placeholder="Enter MNO ID" value={customerIam?.mnoId}/> */}
                                <Form.Control type="text" placeholder={customerIam?.mnoId} disabled/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <hr/>

                    {/* <Button variant="primary" type="submit" disabled>Update</Button> */}
                </Form>
            </>
        )
        return content
    }

    placeholderOnChange(toggle){
        console.log("onchagne", JSON.stringify(toggle))
    }

    displayCustomersFeatureToggles(){
        // fetch current customer feature toggles
        const customer = this.state?.customer
        const myToggles = customer?.toggles
        // fetch all feature toggles
        const allToggles = this.state.allFeatureToggles

        // merge feature toggles
        let mergedToggles = this.mergeAllFeaturesWithMyFeatures(allToggles, myToggles)

        let displayToggles = []

        mergedToggles?.forEach(toggle => {
            displayToggles.push((
                <div key={toggle.id + "b"}>
                    <hr key={toggle.id + "a"}/>
                    <Row>
                        <Col xs={8} className="featureToggleLabel font-monospace">
                            <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip-2">{toggle.description}</Tooltip>}>
                                <i className="bi bi-info-circle-fill"></i>
                            </OverlayTrigger> {toggle.name} 
                        </Col>
                        <Col xs={4}>
                            <Form.Check
                                type="switch"
                                id={toggle.id}
                                checked={toggle.selected === true ? true : false}
                                onChange={() => {
                                    this.placeholderOnChange(toggle)
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            ))

            
        })

        let content = (
            <>
                <h5>Customer's Feature Toggles:</h5>
                <Form>
                    <Form.Text className="text-muted">
                        <p>This will have the current assigned feature toggles for this customer</p>
                    </Form.Text>
                </Form>
                
                <Form>
                    {displayToggles}
                    <hr/>
                </Form>
            </>
        )
        return content
    }

    mergeAllFeaturesWithMyFeatures(allToggles, myToggles){
        let mergedToggles = allToggles
        mergedToggles?.forEach(toggle =>{
            myToggles?.forEach(myToggle => {
                if (toggle.id === myToggle.id){
                    toggle.selected = true
                }
            })
        })

        return mergedToggles
    }

    displayCustomerUsers(){
        let customerUsers = this.state?.customerUsers
        const totalUsers = customerUsers?.length
        let content = (
            <>
                <h5>Customer's Users</h5>
                <Form>
                    <Form.Text className="text-muted">
                        <p>These are the current users that have access to this customers account.</p>
                    </Form.Text>
                </Form>

                {/* <div id="buttonPanel">
                    <a href={'/'}>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addFeatureModal">
                            <i className="bi bi-plus-circle"></i> Add user to customer
                        </button>
                    </a>
                </div> */}

                <p>Totla number of users: {totalUsers}</p>
                <Table className="table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="col-sm-2">Username</th>
                            <th class="col-sm-2">First Name</th>
                            <th class="col-sm-2">Last Name</th>
                            <th class="col-sm-2">Created</th>
                            <th class="col-sm-2">Last Login</th>
                            <th class="col-sm-1">Status</th>
                            <th class="col-sm-1">Actions</th>
                        </tr>
                    </thead>
                    <tbody>                        
                        {
                            (customerUsers) 
                                ? customerUsers.map(user => {
                                    return (
                                        <tr key={user.id}>
                                            <td>{user.email}</td>
                                            <td>{user.firstName}</td>
                                            <td>{user.lastName}</td>
                                            <td>
                                                {(user.created) ? moment(user.created).startOf('minutes').fromNow() : ""}
                                            </td>
                                            <td>
                                                {(user.lastLogin) ? moment(user.lastLogin).startOf('minutes').fromNow() : ""}
                                            </td>
                                            <td>
                                                {(
                                                    user.status === "ACTIVE" 
                                                        ? (<Badge className="text-white" bg="success">{user.status}</Badge>)
                                                        : (<Badge className="text-white" bg="danger">{user.status}</Badge>)
                                                )}
                                            </td>
                                            <td>
                                            {/* <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target={"#editFeatureModal"} onClick = {() => this.handleOnClickEditFeature(feature)}> */}
                                                {/* <button type="button" className="btn btn-primary btn-sm table_button">
                                                    <i className="bi bi-plus-circle"></i>
                                                </button> */}
                                                <button type="button" className="btn btn-danger btn-sm table_button">
                                                    <i className="bi bi-dash-circle"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )  
                                })
                                : (<tr></tr>)
                        }
                    </tbody>
                </Table>
            </>
        )
        return content
    }

    // render

    updatePageAnchor(anchor) {
        window.history.replaceState(null, '', "admin-customers?customer_id=" + this.state?.customer_id + "&anchor=" + anchor)
    }

    render() {
        let isLoading = ""
        if (this.state.isLoading === true){
            isLoading = (
                <Spinner className="customerSpinner" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
            )
        }

		return (
            <>
                {/* Alert Service Section */}
                <div className="row">
                    <div id="alert-banner" className="col-lg-12 d-grid" />
                </div>

                {/* Action Buttons */}
                <div className="row">
                    <div className="col-lg-12 d-grid">
                        <div id="buttonPanel">
                            <a href={'/admin-customers'}>
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addFeatureModal">
                                    <i className="bi bi-arrow-return-left"></i> Back to Customers
                                </button>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Customer Information  */}
                <div className="row mb-4">
                    <div className="col-lg-12 d-grid">
                        <div className="row mb-12">
                            <div className="col-lg-12 d-grid">
                                <div className="card shadow mb-4 h-100">
                                    <div className="card-header py-3">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            {
                                                (this.state.isLoading === false)
                                                    ? (<><i className="bi bi-people"></i> Customer - {this.state?.customer?.name}</>)
                                                    : (<><i className="bi bi-people"></i> Customer</>)
                                            }
                                            {isLoading}
                                        </h6>
                                    </div>
                                    <div className="card-body">
                                    <div id="alert-form" className="col-lg-12 d-grid" />
                                        <Tab.Container id="left-tabs-example" defaultActiveKey={this.props.anchor}>
                                            <Row>
                                                <Col sm={3}>
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="data" onClick = {() => this.updatePageAnchor("data")}>Customer Data</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="iam" onClick = {() => this.updatePageAnchor("iam")}>IAM Credentials</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="features" onClick = {() => this.updatePageAnchor("features")}>Feature Toggles</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="users" onClick = {() => this.updatePageAnchor("users")}>Users</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                </Col>
                                                <Col sm={9} className="tabcolum">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="data">{this.diplayCustomerInformationCard()}</Tab.Pane>
                                                    <Tab.Pane eventKey="iam">{this.displayCustomersIAM()}</Tab.Pane>
                                                    <Tab.Pane eventKey="features">{this.displayCustomersFeatureToggles()}</Tab.Pane>
                                                    <Tab.Pane eventKey="users">{this.displayCustomerUsers()}</Tab.Pane>
                                                </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
		)
	}
}

export default withOktaAuth(withRouter(CustomerSingle))