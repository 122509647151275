import http from "../api/http-common";;

const dict = {}
dict["AUDIT_EIS|CREATED"] = {"en" : {"explanation":"ES4.AuditEIS Request has been received by the SMSR", "translation": "Operation created"}};
dict["AUDIT_EIS|ACCEPT"] = {"en" : {"explanation":"ES4.AuditEIS Request has been accepted for processing by the SMSR", "translation": "Accepted for processing"}};
dict["AUDIT_EIS|VALIDATE_PROFILES"] = {"en" : {"explanation":"Validated that the Function Requestor has permission to send ES5.eUICCCapabilityAudit command to the EIS", "translation": "Validated user permissions"}};
dict["AUDIT_EIS|BUILD_AND_SEND_COMMAND"] = {"en" : {"explanation":"ES5.eUICCCapabilityAudit command sent over the mobile network - currently inflight", "translation": "Audit command sent"}};
dict["AUDIT_EIS|LOAD_VALUES_FROM_CARD_RESPONSE"] = {"en" : {"explanation":"ES5.eUICCCapabilityAudit response received over the mobile network", "translation": "Audit response received"}};
dict["AUDIT_EIS|UPDATE_EIS"] = {"en" : {"explanation":"Updating EIS with ES5.eUICCCapabilityAudit response", "translation": "EIS updated"}};

dict["DOWNLOAD_PROFILE|ACCEPT"] = {"en" : {"explanation":"ES2.DownloadProfile Request has been accepted for processing by the SMDP", "translation": "Accepted for processing"}};
dict["DOWNLOAD_PROFILE|ES2_DOWNLOAD_PROFILE_AVAILABILITY_CHECK"] = {"en" : {"explanation":"The SMDP checked to make sure the profile is available for download", "translation": "Profile available"}};
dict["DOWNLOAD_PROFILE|GET_EIS"] = {"en" : {"explanation":"Sent ES3.GetEIS Request to SMSR processing ES3.GetEIS Response", "translation": "Retrieved EIS"}};
dict["DOWNLOAD_PROFILE|VALIDATE_EIS"] = {"en" : {"explanation":"Checked eUICC Eligibilty against the Profile to be downloaded", "translation": "EIS validated"}};
dict["DOWNLOAD_PROFILE|SEND_CREATE_ISDP_REQUEST"] = {"en" : {"explanation":"ES3.CreateISDP Request created and sent to the SMSR.", "translation": "Create Profile request sent"}};
dict["DOWNLOAD_PROFILE|CREATE_ISDP_RESPONSE"] = {"en" : {"explanation":"Successful ES3.CreateISDP Response received from the SMSR", "translation": "Create Profile response validated"}};
dict["DOWNLOAD_PROFILE|INSTALL"] = {"en" : {"explanation":"INSTALL [for personalization] Store Data command created and sent to the eUICC over ES8", "translation": "Key Establishment - Initiated"}};
dict["DOWNLOAD_PROFILE|INSTALL_RESPONSE"] = {"en" : {"explanation":"Successful response to the Install [for personalization] Store Data command received", "translation": "Key Establishment - Initial response received"}};
dict["DOWNLOAD_PROFILE|SEND_CERTIFICATE"] = {"en" : {"explanation":"First Store Data Command containing the SMDP certificate created and sent to the eUICC over ES8", "translation": "Key Establishment - Certificate sent"}};
dict["DOWNLOAD_PROFILE|CERTIFICATE_RESPONSE"] = {"en" : {"explanation":"Response to the First Store Data command containing the Random Challenge received", "translation": "Key Establishment - Random challenge received"}};
dict["DOWNLOAD_PROFILE|SEND_ISDR_KEYS"] = {"en" : {"explanation":"Ephemeral keypair generated and signed along with the random challenge using the SMDP key. The ephemeral public key random challenge and signature then sent to the eUICC in the Second Store Data command over ES8", "translation": "Key Establishment - Signed data sent"}};
dict["DOWNLOAD_PROFILE|RECEIPT_RESPONSE"] = {"en" : {"explanation":"Second Store Data response received containing the Receipt from the eUICC", "translation": "Key Establishment - Receipt received"}};
dict["DOWNLOAD_PROFILE|GENERATE_KEYING_MATERIAL"] = {"en" : {"explanation":"SCP03 Keyset generated using the ECASD public key and ephemeral private key", "translation": "Key Establishment - SCP03 keyset generated"}};
dict["DOWNLOAD_PROFILE|VERIFYING_RECEIPT"] = {"en" : {"explanation":"SCP03 key material used to validate the receipt received in the Second Store Data response is consistent with that what has been created by the ISD-P.", "translation": "Key Establishment - Receipt validated"}};
dict["DOWNLOAD_PROFILE|PROFILE_RESPONSE"] = {"en" : {"explanation":"Profile segment response received and checked for a successful response code", "translation": "Profile segment response received"}};
dict["DOWNLOAD_PROFILE|PROFILE_DOWNLOAD_COMPLETED"] = {"en" : {"explanation":"Sending ES3.ProfileDownloadCompleted Request to SMSR", "translation": "Profile download complete sent"}};
dict["DOWNLOAD_PROFILE|ENABLE_PROFILE_REQUEST"] = {"en" : {"explanation":"ES3.EnableProfile request created and sent to SMSR", "translation": "Enable profile request send"}};
dict["DOWNLOAD_PROFILE|HANDLE_ES3_RESPONSE"] = {"en" : {"explanation":"ES3.EnableProfile Response received from the SMSR", "translation": "Enable profile response received"}};
dict["DOWNLOAD_PROFILE|DELETE_ISDP_REQUEST"] = {"en" : {"explanation":"ES3.DeleteISDP Request sent to SMSR as part of Error Management Sub-routine", "translation": "Error Handling- Delete profile request sent"}};
dict["DOWNLOAD_PROFILE|DELETE_ISDP_REQUEST_RETRY"] = {"en" : {"explanation":"ES3.DeleteISDP Request sent again to SMSR to retry Error Management Sub-routine", "translation": "Error Handling - Delete profile retried"}};
dict["DOWNLOAD_PROFILE|DELETE_ISDP_RESPONSE"] = {"en" : {"explanation":"Received ES3.DeleteISDP Response from SMSR", "translation": "Error Handling - Delete Profile Response Received"}};

dict["ENABLE_PROFILE|CREATED"] = {"en" : {"explanation":"ES4.EnableProfile Request has been received by the SMSR", "translation": "Operation Created"}};
dict["ENABLE_PROFILE|ACCEPT"] = {"en" : {"explanation":"ES4.EnableProfile Request has been accepted for processing by the SMSR", "translation": "Accepted for processing"}};
dict["ENABLE_PROFILE|VALIDATE_PROFILES"] = {"en" : {"explanation":"Transfer rules verified permissions validated and POL2 rules checked.", "translation": "Transfer rules verified"}};
dict["ENABLE_PROFILE|SUBMIT_STORE_DATA_ENABLE_PROFILE_COMMAND"] = {"en" : {"explanation":"ES5.EnableProfile command created and sent to the mobile network - currently inflight", "translation": "Enable command sent"}};
dict["ENABLE_PROFILE|HANDLE_STORE_DATA_ENABLE_PROFILE_RESPONSE"] = {"en" : {"explanation":"ES5.EnableProfile response received from the mobile network", "translation": "Enable response received"}};
dict["ENABLE_PROFILE|NETWORK_ATTACH_NOTIFICATION"] = {"en" : {"explanation":"Network Attach Notification received for the enabled profile and Notification Confirmation sent to eUICC over ES5", "translation": "Network Attach received"}};
dict["ENABLE_PROFILE|HANDLE_NOTIFICATION_CONFIRMATION_RESPONSE"] = {"en" : {"explanation":"Notification Confirmation Response received from the enabled profile", "translation": "Confirmation response received"}};
dict["ENABLE_PROFILE|SUBMIT_DELETE_COMMAND"] = {"en" : {"explanation":"ES5.DeleteProfile command sent to mobile network - in correspondence with POL2 rules", "translation": "Delete command sent"}};
dict["ENABLE_PROFILE|HANDLE_STORE_DATA_DELETE_PROFILE_RESPONSE"] = {"en" : {"explanation":"ES5.DeleteProfile response received and processed removing the deleted profile from the EIS", "translation": "Delete response received"}};

dict["DISABLE_PROFILE|CREATED"] = {"en" : {"explanation":"ES4.DisableProfile Request received by the SMSR", "translation": "Operation created"}};
dict["DISABLE_PROFILE|ACCEPT"] = {"en" : {"explanation":"ES4.DisableProfile Request has been accepted for processing by the SMSR", "translation": "Accepted for processing"}};
dict["DISABLE_PROFILE|VALIDATE_PROFILES"] = {"en" : {"explanation":"Transfer rules verified permissions validated and POL2 rules checked.", "translation": " Transfer rules verified"}};
dict["DISABLE_PROFILE|SUBMIT_STORE_DATA_DISABLE_PROFILE_COMMAND"] = {"en" : {"explanation":"ES5.DisableProfile command created and sent to the mobile network - currently inflight", "translation": "Disable command sent"}};
dict["DISABLE_PROFILE|HANDLE_STORE_DATA_DISABLE_PROFILE_RESPONSE"] = {"en" : {"explanation":"ES5.DisableProfile response received from the mobile network", "translation": "Disable response received"}};
dict["DISABLE_PROFILE|NETWORK_ATTACH_NOTIFICATION"] = {"en" : {"explanation":"Network Attach Notification received for the enabled profile and Notification Confirmation sent to eUICC over ES5", "translation": "Network Attach received"}};
dict["DISABLE_PROFILE|DISABLE_PROFILE_NETWORK_ATTACH_RESPONSE"] = {"en" : {"explanation":"Notification Confirmation Response received from the enabled profile", "translation": "Confirmation response received"}};
dict["DISABLE_PROFILE|SUBMIT_DELETE_COMMAND"] = {"en" : {"explanation":"ES5.DeleteProfile command sent to mobile network - in correspondence with POL2 rules", "translation": "Delete command sent"}};
dict["DISABLE_PROFILE|HANDLE_STORE_DATA_DELETE_PROFILE_RESPONSE"] = {"en" : {"explanation":"ES5.DeleteProfile response received and processed removing the deleted profile from the EIS", "translation": "Delete response received"}};

dict["DELETE_ISDP|CREATED"] = {"en" : {"explanation":"ES4.DeleteProfile Request received by the SMSR", "translation": "Operation created"}};
dict["DELETE_ISDP|ACCEPT"] = {"en" : {"explanation":"ES4.DeleteProfile Request accepted for processing by the SMSR", "translation": "Accepted for processing"}};
dict["DELETE_ISDP|VALIDATE_PROFILES"] = {"en" : {"explanation":"Transfer rules verified permissions validated and POL2 rules checked.", "translation": "Transfer rules verified"}};
dict["DELETE_ISDP|SUBMIT_STORE_DATA_DISABLE_PROFILE_COMMAND"] = {"en" : {"explanation":"ES5.DisableProfile command created and sent to the mobile network - currently inflight", "translation": "Disable command sent"}};
dict["DELETE_ISDP|HANDLE_STORE_DATA_DISABLE_PROFILE_RESPONSE"] = {"en" : {"explanation":"ES5.DisableProfile response received from the mobile network", "translation": "Disable response received"}};
dict["DELETE_ISDP|NETWORK_ATTACH_NOTIFICATION"] = {"en" : {"explanation":"Network Attach Notification received for the enabled profile and Notification Confirmation sent to eUICC over ES5", "translation": "Network Attach received"}};
dict["DELETE_ISDP|DISABLE_PROFILE_NETWORK_ATTACH_RESPONSE"] = {"en" : {"explanation":"Notification Confirmation Response received from the enabled profile", "translation": "Confirmation response received"}};
dict["DELETE_ISDP|SUBMIT_DELETE_COMMAND"] = {"en" : {"explanation":"ES5.DeleteProfile command sent to mobile network - currently in flight", "translation": "Delete command sent"}};
dict["DELETE_ISDP|HANDLE_DELETE_ISDP_COMMAND_RESPONSE"] = {"en" : {"explanation":"ES5.DeleteProfile response received and processed removing the deleted profile from the EIS", "translation": "Delete response received"}};

const getInFlights = (eid, config) => {
  return http.get("/rsp/operations/inflight/eid/" + eid, config);
};

const translateOperationProcedureName = (procedureName, stageName) => {
  return dict[procedureName + "|" + stageName];
}

const functions = {
  getInFlights,
  translateOperationProcedureName
};

export default functions;
