import { useEffect, useState } from "react";
import HttpUtil from "../../api/http-util";
import loadMaintenanceWindowAlertService from "../../services/MaintenanceWindowService";
import "./maintenanceWindowAlert.css";

//Functional Component Maintenance Window Alert
const MaintenanceWindowAlert = ({ oktaAuth }) => {
	const [alert, setAlert] = useState({});

	useEffect(() => {
		loadMaintenanceWindowAlertService
			.getMaintenaceWindowAlert(
				HttpUtil.getAuthHeader(oktaAuth.getAccessToken())
			)
			.then((response) => {
				if (response.data === null) {
					console.log(`No alert was found in the database`);
				} else {
					const data = response.data;
					setAlert(data);
				}
			});
	}, [oktaAuth]);

	if (alert["alertStatus"] === true) {
		var mailLink = `mailto:${alert["serviceDeliveryManagerEmail"]}`;
		return (
			<div
				role="alert"
				className="fade alert-warning alert alert-primary show maintenanceAlert"
			>
				<h1>{alert["title"]}</h1>
				<p>
					{alert["message"]} Contact{" "}
					<a href={mailLink}>
						{alert["serviceDeliveryManagerEmail"]}
					</a>{" "}
					for more information.
				</p>
			</div>
		);
	} else {
		return <div></div>;
	}
};

export default MaintenanceWindowAlert;
