import http from "../api/http-common";

// Gets all profiles by MnoId
const getAll = (config) => {
    return http.get("/consumer/inventory/profiles", config);
};

const searchbyIccid = (iccid, config) => {
  return http.get("/consumer/inventory/profiles/search/iccid/" + iccid, config);
};

const searchByProfileType = (type, config) => {
  return http.get("/consumer/inventory/profiles/search/profileTypes/" + type, config);
}

const searchByProfileState = (state, config) => {
  return http.get("/consumer/inventory/profiles/search/profileStates/" + state, config);
}

const listProfileTypesByCount = (config) => {
  return http.get('consumer/inventory/profiles/counts/types?state=AVAILABLE', config);
}

const listProfileStatesBycount = (config) => {
  return http.get("/consumer/inventory/profiles/counts/states", config);
}

const getProfileCounts = (config) => {
  return http.get("/consumer/inventory/profiles/count", config);
}

const getRunningCampaignProfileTypeList =(config) => {
  return http.get("/consumer/campaign/generate-qr/is-running/profileType/list", config);
}


const checkCampaignOfThisProfileTypeIsRunning =(type,config) => {
  return http.get("/consumer/campaign/generate-qr/is-running/profileType/"+type, config);
}
function getRequestParams(page, size, search){
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["size"] = size;
  }

  if (search) {
    params["searchFilter"] = search;
  }

  return params;
};

const functions = {
  getAll,
  searchbyIccid,
  searchByProfileType,
  searchByProfileState,
  listProfileTypesBycount: listProfileTypesByCount,
  listProfileStatesBycount,
  getProfileCounts,
  getRequestParams,
  getRunningCampaignProfileTypeList,
  checkCampaignOfThisProfileTypeIsRunning
};

export default functions;
