import React, { Component } from "react";
import {OverlayTrigger, Button, Offcanvas, Tooltip} from 'react-bootstrap';
import "./offcanvas.css"

import ICCIDListService from "../../../../services/ICCIDListService"
import ProfileInventoryService  from "../../../../services/ProfileInventoryService"
import HttpUtil from "../../../../api/http-util";

import Select from 'react-select'
class OffcanvasOperationButton extends Component {


    constructor(props) {
        super(props);
        this.state = {
          show: false,
          value: "",
          validity: this.props.validity,
          profileTypes: [],
          typeSelect: ""
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeVP = this.onChangeVP.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
      }

    renderOpenButton(){
        if (this.props.isButtonDisabled){
            return (
                <Button className="btn btn-circle rps-action-btn btn-primary" onClick={() => this.handleShow()} disabled><i className={`bi ${this.props.icon}`}/></Button>
            )
        }else{
            return (
                <OverlayTrigger placement={this.props.placement} overlay={<Tooltip id={`tooltip-${this.props.placement}`}>{this.props.title}</Tooltip>}>        
                    <Button className="btn btn-circle rps-action-btn btn-primary"
                            data-tid={this.props.title}
                            onClick={() => this.handleShow()}>
                        <i className={`bi ${this.props.icon}`}/>
                    </Button>
                </OverlayTrigger>
            )
        }
    }

    renderActionButton(iccids){
        if (this.props.operationType === "download-profile" ||  this.props.operationType === "download-profile-by-profile-type" || this.props.operationType === "audit-eis"){
            return (<Button className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => {this.sendAndClose();}} >Send</Button>)
        }

        // need to disable the button if no items are enabled in the <select>
        let button = (<Button className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => {this.sendAndClose()}} disabled>Send</Button>)
        for (let index in iccids) {
            let iccid = iccids[index];
            if (iccid.disabled === ""){
                button = (<Button className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => {this.sendAndClose()}} >Send</Button>)
            }
        }

        return button;
    }

    onChange(e){
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (e.target.value === '' || re.test(e.target.value)) {
           this.setState({value: e.target.value})
        }
    }
    
    onChangeVP(e){
      this.setState({validity: e.target.value});
    }

    onChangeType(e){
        this.setState({typeSelect:e})
    }

    renderForm(iccids){
        let collectDataElements = "";
        if (this.props.operationType === "download-profile"){
            collectDataElements = (
                <div>
                    <p>
                        <span>ICCID:</span>
                        <input id="selected-iccid" type="text" data-tid="iccidToDownload" value={this.state.value} onChange={this.onChange}/>
                    </p>
                    <p>
                        <span>Validity Period (seconds):</span>
                        <input id="validity-period" type="number" data-tid="validityPeriod" min="1" max="86400" value={this.state.validity} onChange={this.onChangeVP} />
                    </p>                    
                    <div>
                        <label htmlFor="enabled-profile">Should this new profile also be Enabled?</label>
                        <input type="checkbox" id="enabled-profile" name="enabled-profile"/>
                    
                    </div>
                </div>
            )
        } else if(this.props.operationType === "audit-eis"){
            collectDataElements = (
              <div>
                <p>
                    <span>Validity Period (seconds):</span>
                    <input id="validity-period" onKeyDown={(e) =>["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} 
                    type="number" data-tid="validityPeriod" min="1" max="86400" value={this.state.validity} onChange={this.onChangeVP} />
                </p>
              </div>
            );

        } else if (this.props.operationType === "download-profile-by-profile-type"){

            collectDataElements = (
                <div>
                    <p>

                        <div  id="selected-profile-type" data-tid="selectedTypeToDownload" className="input-group">
                            <div style={{width:"100%"}}><Select name="selectedType"  id="selectedType" options={this.state.profileTypes}  onChange={this.onChangeType} value={this.state.typeSelect} menuPortalTarget={document.querySelector('selected-profile-type')}/>
                            </div>
                        </div>
                    </p>
                    <p>
                        <span>Validity Period (seconds):</span>
                        <input id="validity-period" type="number" data-tid="validityPeriod" min="1" max="86400" value={this.state.validity} onChange={this.onChangeVP} />
                    </p>
                    <div>
                        <label htmlFor="enabled-profile">Should this new profile also be Enabled?</label>
                        <input type="checkbox" id="enabled-profile" name="enabled-profile"/>
                    </div>
                </div>
            )
        } else {
            collectDataElements = (
              <div>
                <p>
                  <span>ICCID:</span>
                    <select name="selected-iccid" id="selected-iccid" data-tid="iccidDropdown" defaultValue="-1" onChange={e => this.doNothing()}>
                    <option value="-1" disabled> -- select an iccid -- </option>
                    {
                        iccids.map((option, index) => (
                            <option key={index} value={option.iccid} disabled={option.disabled} >{option.iccid}</option>
                        ))
                    }
                    </select>
                </p>
                <p>
                    <span>Validity Period (seconds):</span>
                    <input id="validity-period" type="number" data-tid="validityPeriod" min="1" max="86400" value={this.state.validity} onChange={this.onChangeVP} />
                </p>
              </div>
            )
        }
        return collectDataElements;
    }

    sendAndClose = () => {
      this.props.onClick();
      this.handleClose();
    }

    showProfileTypes = (response) => {
        const types = response.map(value => ({ value, label: value }));
        this.setState({profileTypes: types})
    };
    handleClose = () => {
        this.setState({show: false, value: "", validity: this.props.validity});
    };
        
    handleShow = () => {
        this.setState({show: true});
    };

    doNothing = () => {
        // have to have an onChange fucntion for <select>, so whats why this does nothing
    };

    componentDidMount() {
        // load data on first component render
        if(this.props.operationType === "download-profile-by-profile-type"){
            const config = HttpUtil.getAuthHeader(this.props.accessToken);

            ProfileInventoryService.getProfileTypes(config).then((response) =>
               this.showProfileTypes(response.data))
                .catch((e) => {
                    // add error banner here
                });
        }
    }


    render(){
        let iccids = [];
        if (this.props.operationType === "enable-profile"){
            iccids = ICCIDListService.createEnabledICCIDList(this.props.profiles);

        }else if (this.props.operationType === "disable-profile"){
            iccids = ICCIDListService.createDisableICCIDList(this.props.profiles);
        
        }else if (this.props.operationType === "delete-profile"){
            iccids = ICCIDListService.createDeleteICCIDList(this.props.profiles);
        
        }else if (this.props.operationType === "set-fallback-attribute"){
            iccids = ICCIDListService.createSetFallbackAttributeICCIDList(this.props.profiles);
        }

        // dynamically create elements based on context
        let triggerButton = this.renderOpenButton();
        let button = this.renderActionButton(iccids);
        let collectDataElements = this.renderForm(iccids);

        return (
            <>
                {triggerButton}
                <Offcanvas id={this.props.operationType} show={this.state.show} onHide={e => this.handleClose()} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><i className={`bi ${this.props.icon}`}/> {this.props.title}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p>{this.props.message}</p>
                        {collectDataElements}
                        {button}
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    }
}

export default OffcanvasOperationButton;