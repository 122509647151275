import React, { Component } from "react";
import {OverlayTrigger, Tooltip, Spinner} from 'react-bootstrap';

import HttpUtil from '../../../../api/http-util';
import CallbackService from '../../../../services/CallbackService';
import CallbackItem from './CallbackItem';

import './callback.css'

class CallbackPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eid: this.props.eid,
      callbacks: null,
      oktaAuth: this.props.oktaAuth,
    }
  }

  retrieveCallbacks = () => {
    this.setState({callbacks: null});

    const headerConfig = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
    CallbackService.getCallback(this.state.eid, headerConfig)
    .then((response) => {
        var callbackData = response.data;
        callbackData = CallbackService.orderByLatestDate(callbackData);
        this.setState({callbacks: callbackData});
    })
    .catch((e) => {
      this.setState({callbacks: []});
    });
  }

  componentDidMount() {
    this.retrieveCallbacks();
  }

  deleteCallbackButton(messageId){
    const headerConfig = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
    CallbackService.deleteCallbackByMessageId(messageId, headerConfig)
    .then((response) => {
      // refresh callback list
      this.retrieveCallbacks();
    })
    .catch((e) => {
      //to failed to delete
      alert(e);
    });
  }

  renderCallbackContent(){
    // set for no content
    let callbackList = (
      <div className="noCallbacks">
          <Spinner animation="border" size="sm" />
      </div>
    );

    if (this.state.callbacks !== null){
      if (this.state.callbacks.length !== 0){
          callbackList = [];
          for (let index in this.state.callbacks){
            let callback = this.state.callbacks[index];
            callbackList.push(
              <CallbackItem callback={callback} deleteButton={e => this.deleteCallbackButton(callback.messageId)} key={index}/>
            )
          };
      }else{
        callbackList = (
          <div className="noCallbacks">
            <div className="alert alert-warning" role="alert">
              <p><strong><i className="bi bi-exclamation-triangle"></i> No acknowledgements found!</strong></p>
              Please refer to the Audit Trail section for any operations executed more than a week ago.
            </div>
          </div>
        );
      }
    }

    return callbackList;
  }

  render(){
    return (
      <div>
        <div className="row bottom-margin">
            <div className="col-8 text-vcenter">
            <h5 className="text-primary"><i className="bi bi-check-circle"></i> Acknowledged Operations</h5>
            <p>Here are the <strong>operation acknowledgements received</strong> from the RSP server for this eSIM.</p>
            </div>
            <div className="col-4 action-btns-right">
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-bottom">Refresh Operations</Tooltip>}>
                    <button type="button"
                        onClick={e => this.retrieveCallbacks()}
                        className="btn btn-circle rps-action-btn btn-info"
                        data-tid="Acknowledged Operations Refresh">
                        <i className="bi bi-arrow-counterclockwise fa-sm fa-fw"></i>
                    </button>
                </OverlayTrigger>
            </div>
        </div>

        <h6 className="text-primary"><i className="bi bi-clock"></i> Timeline</h6>
        {this.renderCallbackContent()}
      </div>
    )
  }
}

export default CallbackPane;
