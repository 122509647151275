import './modal.css';
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom'
import AlertOperationRequest from "./rsp-operations/AlertOperationRequest";
import {Tab, Tabs} from "react-bootstrap";
const DISABLE = "DISABLE";
const DELETE = "DELETE";
const AUTO_DELETE  = "Auto-Delete"
const NOT_ALLOWED = "Not-Allowed"
const deleteNotAllowedRule = {"qualification": NOT_ALLOWED, "action": DELETE}
const disableNotAllowedRule = {"qualification": NOT_ALLOWED, "action": DISABLE}
const disableAutoDeleteRule = {"qualification": AUTO_DELETE, "action": DISABLE}

const UpdateProfile = ({
                                     closeModal, showModal, sendUpdateRequest,sendPol2Request,
                                     initialImsi, initialMsisdn, iccid,pol2Rules
                                   }) => {

  const showHideClassName = showModal ? "modal display-block" : "modal display-none";

  // store imsi in state
  const defaultImsi = initialImsi || 'defaultImsi';
  const [imsi, setImsi] = useState(defaultImsi);
  const [tabKey, setTabKey] = useState('subscriptionAddress')

  const [deleteNotAllowed, setDeleteNotAllowed] = useState(false);
  const [disableNotAllowed, setDisableNotAllowed] = useState(false);
  const [disableAutoDelete, setDisableAutoDelete] = useState(false);
  // store msisdn in state
  const defaultMsisdn = initialMsisdn || 'defaultMsisdn';
  const [msisdn, setMsisdn] = useState(defaultMsisdn);

  // create change listener for imsi and msisdn
  useEffect(() => {
    setImsi(defaultImsi);
    setMsisdn(defaultMsisdn);
    const shouldCheckDeleteNotAllowed =  pol2Rules?.some(rule => rule.action === 'DELETE' && rule.qualification === 'NOT_ALLOWED')
    const shouldCheckDisableNotAllowed =  pol2Rules?.some(rule => rule.action === 'DISABLE' && rule.qualification === 'NOT_ALLOWED')
    const shouldCheckDisableAutoDelete =  pol2Rules?.some(rule => rule.action === 'DISABLE' && rule.qualification === 'AUTO_DELETE')

    setDeleteNotAllowed(shouldCheckDeleteNotAllowed);
    setDisableNotAllowed(shouldCheckDisableNotAllowed);
    setDisableAutoDelete(shouldCheckDisableAutoDelete);
  }, [initialImsi, initialMsisdn, defaultImsi, defaultMsisdn]);

  // validate form inputs and send UpdateSubscriptionAddress request
  const validateAndSend = (e) => {
    let errors = validateInputs();
    if (errors.length > 0) {
      showAlert("Danger", "Invalid Input Values", `[${errors.join(", ")}] invalid, digits only accepted`);
    } else {
      sendUpdateRequest(imsi, msisdn);
    }
    // call preventDefault to disable html form handling
    e.preventDefault();
  };


  const handleCheckboxChange = (checkboxName) => {
    switch (checkboxName) {
      case 'deleteNotAllowed':
        setDeleteNotAllowed(!deleteNotAllowed);
        break;
      case 'disableNotAllowed':
        setDisableNotAllowed(!disableNotAllowed);
        break;
      case 'disableAutoDelete':
        setDisableAutoDelete(!disableAutoDelete);
        break;
      default:
        break;
    }
  };


  const validateInputs = () => {
    let errors = [];

    let imsiIsDigitsOnly = /^\d+$/.test(imsi);
    if (!imsiIsDigitsOnly) {
      errors.push("IMSI");
    }

    let msisdnIsDigitsOnly = /^\d+$/.test(msisdn);
    if (!msisdnIsDigitsOnly) {
      errors.push("MSISDN");
    }
    return errors;
  };

  // alert functions
  const showAlert = (variant, heading, message) => {
    ReactDOM.render(
        <AlertOperationRequest variant={variant} heading={heading} message={message} close={hideAlert}/>,
        document.getElementById('alert-form')
    );
    setTimeout(() => hideAlert(), 2000);
  };

  const hideAlert = () => {
    ReactDOM.render("", document.getElementById('alert-form'));
  };

  const updatePolicyRules = (e) => {
    if(deleteNotAllowed && disableNotAllowed && disableAutoDelete){
      showAlert("Danger", "Maximum value exceeded", "Only 2 Policy Rules can be applied.");
    }
    else if(disableAutoDelete && (deleteNotAllowed || disableNotAllowed)){
      showAlert("Danger", "Invalid Policy Rules combination", `Both ${AUTO_DELETE} and ${NOT_ALLOWED} cannot be present.`);
    } else {
      let data = getUpdatePolicyRulesData(deleteNotAllowed,disableNotAllowed,disableAutoDelete)
      sendPol2Request(data)
    }
    // call preventDefault to disable html form handling
    e.preventDefault();
  }

  function getUpdatePolicyRulesData(deleteNotAllowed,disableNotAllowed,disableAutoDelete){
    let data = {
      "iccid" : iccid,
      "pol2Rules": []
    }
    if(deleteNotAllowed){
      data.pol2Rules.push(deleteNotAllowedRule)
    } if(disableNotAllowed){
      data.pol2Rules.push(disableNotAllowedRule)
    } if(disableAutoDelete){
      data.pol2Rules.push(disableAutoDeleteRule)
    }
    return data;
  }

  const Update = (event) => {
    event.preventDefault();
    if (tabKey === 'subscriptionAddress') {
      validateAndSend(event);
    } else if (tabKey === 'policyRules') {
      updatePolicyRules(event);
    }
  }

  function resetModalWindow() {
    setImsi(defaultImsi);
    setMsisdn(defaultMsisdn);
    setTabKey('subscriptionAddress');
    closeModal();
  }

  const checkboxStyle = {
    position: 'relative',
    paddingLeft: '30px',
    cursor: 'pointer',
    display: 'inline-block',
    lineHeight: '20px',
  };

  return (

      <div className={showHideClassName}>
        <section className="modal-dialog modal-lg">

          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Profile</h5>
              <button type="button" className="close" aria-label="Close" onClick={resetModalWindow}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div>
              <div id="alert-form" className="col-lg-12 d-grid"/>
            </div>


            <div className="modal-body">
              <Tabs
                  activeKey={tabKey}
                  style={{border: 'none'}}
                  onSelect={(k) => setTabKey(k)}>
                <Tab eventKey="subscriptionAddress"
                     title="Subscription Address"
                     active={tabKey === 'subscriptionAddress'}
                     style={{
                       backgroundColor: 'aliceblue',
                       padding: '16px'
                     }}>
                  <form onSubmit={e => {
                    e.preventDefault()
                  }}>
                    <label className="form-label title-bold">ICCID</label>
                    <div>{iccid}</div>
                    <br/>
                    <label className="form-label title-bold">IMSI</label>
                    <input name="imsi"
                           data-tid="imsi"
                           className="form-control"
                           type="text"
                           onChange={e => setImsi(e.target.value)}
                           value={imsi}
                           required/>
                    <br/>
                    <label className="form-label title-bold">MSISDN</label>
                    <input name="msisdn"
                           data-tid="msisdn"
                           className="form-control"
                           type="text"
                           onChange={e => setMsisdn(e.target.value)}
                           value={msisdn}
                           required/>
                    <br/>
                  </form>
                </Tab>
                <Tab eventKey="policyRules"
                     title="Policy Rules"
                     active={tabKey === 'policyRules'}
                     style={{
                       backgroundColor: 'aliceblue',
                       padding: '16px'
                     }}>
                  <div>
                    <p><strong>Please select the rules that you want to apply</strong></p>
                    <label>
                      <input
                          type="checkbox"
                          checked={deleteNotAllowed}
                          onChange={() => handleCheckboxChange('deleteNotAllowed')}
                          style={checkboxStyle}
                          data-tid="deleteNotAllowedCheckbox"
                      />
                      <span style={{ marginLeft: '10px' }}>
                      DELETE - Not-Allowed
                    </span>
                    </label>
                    <br />

                    <label>
                      <input
                          type="checkbox"
                          checked={disableNotAllowed}
                          onChange={() => handleCheckboxChange('disableNotAllowed')}
                          style={checkboxStyle}
                          data-tid="disableNotAllowedCheckbox"
                      />
                      <span style={{ marginLeft: '10px' }}>
                      DISABLE - Not-Allowed
                      </span>
                    </label>
                    <br />
                    <label>
                      <input
                          type="checkbox"
                          checked={disableAutoDelete}
                          onChange={() => handleCheckboxChange('disableAutoDelete')}
                          style={checkboxStyle}
                          data-tid="disableAutoDeleteCheckbox"
                      />
                      <span style={{ marginLeft: '10px' }}>
                      DISABLE - Auto-Delete
                      </span>
                    </label>

                  </div>
                </Tab>
              </Tabs>
            </div>
            <div className="modal-footer">
              <input className='btn btn-primary' type='submit' data-tid='Update' value='Update'
                     onClick={e => Update(e)}/>
              <input className='btn btn-secondary' type='button' value='Cancel' onClick={resetModalWindow}/>
            </div>
          </div>
        </section>
      </div>
  );
};

export default UpdateProfile;
