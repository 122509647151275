import React, { Component } from 'react';
import "./AlertOperationRequest.css";
import CampaignService from '../../../../services/CampaignService';
import AlertService from '../../../../services/AlertService';
import HttpUtil from '../../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';

class StopCampaignModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oktaAuth: this.props.oktaAuth
        };
    }

    stopCampaign = () => {
        const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
        if(this.props?.consumerOperation){
            CampaignService.consumerTimeSlicingControls(this.props.campaignId, 'stop', config)
                .then((response) => {
                    AlertService.showAlert("Info", "Campaign Processing", "Campaign processing has been CANCELLED.");
                    this.props.refresh();
                }).catch((e) => {
                console.log("error generating campaign report", e);
            });
        } else{
            CampaignService.timeSlicingControls(this.props.campaignId, 'stop', config)
                .then((response) => {
                    AlertService.showAlert("Info", "Campaign Processing", "Campaign processing has been CANCELLED.");
                    this.props.refresh();
                }).catch((e) => {
                console.log("error generating campaign report", e);
            });
        }
        }


    render() {
        return (
            <>
                {/* THIS MIGHT NEED TO BE MOVED */}
                <div className="modal fade" id={'activateModal'} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ cursor: 'default' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Cancel Campaign
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => window.location.reload()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="text-center">You cannot resume a Cancelled Campaign. Please confirm you would like to cancel campaign processing.</p>
                            </div>
                            <div className="modal-footer">
                                <button data-tid="Cancel-Modal-Button" id={'cancelActivateBtn'} type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => window.location.reload()}>Cancel</button>
                                <button data-tid="Stop-Campaign-Modal-Button" id={'stopCampaignBtn'} type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => this.stopCampaign()}>Cancel Campaign</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withOktaAuth(StopCampaignModal);