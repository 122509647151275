import React, { Component } from "react";
import ConsumerProfileInventoryService from "../../../services/ConsumerProfileInventoryService";
import HttpUtil from "../../../api/http-util";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import Doghnut from "../ChartComponent"
import "./consumerProfiles.css";

import {OverlayTrigger, Tooltip} from 'react-bootstrap';

class ProfileStates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			oktaAuth: this.props.oktaAuth,
			profileStates: null,
			totalStates: 0,
			search: this.props.search,
		};
	}

	//Handles the response from getting the profile counts
	handleProfilesResponse(response) {
		var content = response.data._embedded.stateCountList;
		var counter = 0;
		var states = [];
		if(response.data._embedded.stateCountList[0].profileState){
			for (let i = 0; i < content.length; i++) {
				let obj = content[i];
				if(obj.profileState !== 'ALL'){
					counter += obj.totalByState;
				}
				states.push([obj.profileState, obj.totalByState]);
			}
			this.setState({ profileStates: states });
			this.setState({ totalStates: counter });
		}else {
			for (let i = 0; i < content.length; i++) {
				let obj = content[i];
				if(obj.state !== 'ALL'){
					counter += obj.totalByState;
				}
				states.push([obj.state, obj.totalByState]);
			}
			this.setState({ profileStates: states });
			this.setState({ totalStates: counter });
		}
		}
		

	//This gets the profiles and the counts of each
	handleGetProfileState() {
		const config = HttpUtil.getHttpConfig(
			null,
			this.state.oktaAuth.getAccessToken()
		);
		ConsumerProfileInventoryService.listProfileStatesBycount(config)
			.then((response) => this.handleProfilesResponse(response))
			.catch((e) => {
				console.log(
					"An error occurred trying to list profile states by count",
					e
				);
			});
	}

	//Get the profiles by the state selected in the side panel.
	searchByProfileState(profileState) {
		document.getElementById("profile-state-label").innerHTML = `Showing <span style="background-color: ${this.props.stateColors[profileState]}" class="badge text-light">${profileState}</span> profile states`;
		this.props.handlePageChange({ selected: parseInt(0)})
		this.props.searchStates(profileState, this.props.config);
		this.setState({search: profileState})
	}

		//Get the profiles by the state selected in the side panel.
	searchAll(profileState) {
		    document.getElementById("profile-state-label").innerHTML = `Showing <span style="background-color: ${this.props.stateColors[profileState]}" class="badge text-light">${profileState}</span> profile states`;
			this.setState({search: profileState})
			this.props.handlePageChange({ selected: parseInt(0)})
			this.props.getAll(profileState);
			
	}

	componentDidMount() {
		if (this.state.profileStates === null) {
			this.handleGetProfileState();
		}

		if(this.props.stateColors[this.props.search] !== undefined){
			this.props.resetSearchBar(this.state.search);

			if(this.state.search !== 'ALL'){
				this.searchByProfileState(this.state.search);
				
			} else if(this.state.search === 'ALL') {
				this.props.getAll();
			}
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.page !== this.props.page) {
			this.props.getAll();
			window.history.replaceState(
				null,
				"",
				"/consumerprofiles?search=" + this.state.search + "&searchFilter=" + this.state.search + "&size=" +this.props.size + "&page=" + this.props.page
			);
		}
		if (prevState.search !== this.state.search) {
		 	if (this.state.search !== undefined) {
					this.props.resetSearchBar(this.state.search);
					this.props.history.push(
						"/consumerprofiles?search=" + this.state.search + "&searchFilter=" + this.state.search + "&size=" +this.props.size + "&page=" + this.props.page
					);
		 		}
		 	}	 
		}
	
		getProfileStateDescriptionMap() {
			const profileStateDescriptionMap = new Map();
			profileStateDescriptionMap.set("ALL", "Total number of Profile in the SM-DP+");
			profileStateDescriptionMap.set("CONFIRMED", "The Profile is reserved for downloading (linked or not linked to an EID) with Matching ID and Confirmation Code if required");
			profileStateDescriptionMap.set("LINKED", "The Profile is reserved for downloading and is linked to an EID");
			profileStateDescriptionMap.set("ALLOCATED", "The Profile is reserved for downloading without being linked to an EID");
			profileStateDescriptionMap.set("DOWNLOADED", "The Bound Profile was delivered to the LPA");
			profileStateDescriptionMap.set("RELEASED", "The Profile is ready for download and installation after Network Configuration by the Operator (e.g.: HLR Registration)");
			profileStateDescriptionMap.set("AVAILABLE", "The Profile is available in the inventory of the SM-DP+");
			profileStateDescriptionMap.set("UNAVAILABLE", "The Profile cannot be reused anymore by the SM-DP+");
			profileStateDescriptionMap.set("ERROR", "The Profile has not been installed because of one of the following error cases: [Confirmation Code Retry Limit exceeded, Download Retry Limit exceeded, End User Rejection, Permanent error during download and installation]");
			profileStateDescriptionMap.set("INSTALLED", "The Profile was successfully installed on the eUICC");
			
			return profileStateDescriptionMap
				}

	render() {
		const profileStateDescriptionMap = this.getProfileStateDescriptionMap();

		let content;
		if (this.state.profileStates !== null && this.state.totalStates !== 0) {
			content = (
				<React.Fragment>
					<div>
						{this.state.profileStates.map((listitem, index) => (
							<div
								// If the value of the doughnut is greater than 0, apply the stateDoughnutClickable class
								className={`col-md-6 col-sm-6 statesDoghnuts ${listitem[1] > 0 ? 'stateDoughnutClickable' : ''}`}
								id={listitem[0]}
								style={{
									display: "inline-block",
									whiteSpace: "nowrap",
									textAlign: "center",
								}}
								

								key={index}
								onClick={() => listitem[1] > 0 && listitem[0] !== 'ALL' ?  this.searchByProfileState(listitem[0]) 
								: listitem[0] === 'ALL' ? this.searchAll(listitem[0]) : null }
							>
								{(index !== 0) && (index !== 1) ? <hr></hr> : ""}

								<OverlayTrigger placement="bottom" overlay={
									<Tooltip id={`tooltip-bottom`}>
										{profileStateDescriptionMap.get(listitem[0])}
									</Tooltip>
								}>
									<span className="unit_category_title" style={{"fontSize": "130%", "color": `${this.props.stateColors[listitem[0]]}`}}>
										{listitem[0]}
									</span>
								</OverlayTrigger>

								<Doghnut
									stateCount={[
										listitem[1],
										this.state.totalStates - listitem[1],
									]}
									stateType={listitem[0]}
									statesTotal={Math.round(
										(listitem[1] / this.state.totalStates) *
											100
									)}
									stateColor={this.props.stateColors[listitem[0]]}
								></Doghnut>
							</div>
						))}
					</div>
				</React.Fragment>
			);
		} else {
			content = (
				<p className="text-center" style={{"fontSize": "0.9rem"}}><i className="bi bi-exclamation-triangle"></i> No Profile States Found</p>
			);
		}
		return content;
	}
}

export default withOktaAuth(withRouter(ProfileStates));