import React, { Component } from "react";
import EisService from "../../../../services/EisService";
import EisAuditTrailTable from "./EisAuditTrailTable";
import ShowItems from "../../../common/table/ShowItems";
import HttpUtil from "../../../../api/http-util";

class AuditTrailCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: {eidAuditData: null},
            eid: this.props.eid,
            limit: this.props.limit,
            totalItems: 0,
            firstRecord: 0,
            lastRecord: 0,
            page: 0,
            oktaAuth: this.props.oktaAuth,
        };
    }


    handleGetEisAuditResponse(response) {
        const eidAuditData = response.data;
        eidAuditData.auditTrailRecords = EisService.reverseOrder(eidAuditData.auditTrailRecords);
        this.setState({ content: {eidAuditData: eidAuditData} });
        const total = response.data.auditTrailRecords.length;
        this.setState({ totalItems: total });
        this.calculateFirstRecord();
        this.calculateLastRecord();
    }

    retrieveLimit = () => {
        return this.state.limit;
    }


    retrieveAuditEIS = () => {
        const headerConfig = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
        EisService.getEidAudit(this.state.eid, this.state.limit, headerConfig)
            .then((response) => this.handleGetEisAuditResponse(response))
            .catch((e) => {
                this.setState({ content: {eidAuditData: []} });
                this.setState({firstRecord: 0})
                this.setState({lastRecord: 0})
                this.setState({totalItems: 0})
            });
    }

    componentDidMount() {
        // load data on first component render
        this.retrieveAuditEIS();
    }

    componentDidUpdate(prevProps, prevState) {
        // check if state has changed
        if (prevState.limit !== this.state.limit) {
            this.retrieveAuditEIS();
            window.history.replaceState(null, '', 'esims?eid=' + this.state.eid + "&advance_support=" + this.props.anchor + "&limit=" + this.state.limit);
        }
    }

    handleSizeChange = (event) => {
        this.setState({ limit: event.target.value });
    };

    calculateFirstRecord() {
        this.setState({ firstRecord: this.state.page * this.state.limit + 1 });
    }

    calculateLastRecord() {
        const recordEnd =
            this.state.page * parseInt(this.state.limit) +
            parseInt(this.state.limit);
        if (recordEnd > this.state.totalItems) {
            this.setState({ lastRecord: this.state.totalItems });
        } else {
            this.setState({ lastRecord: recordEnd });
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-12 d-grid">
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <div id="alert-form" className="col-lg-12 d-grid" />
                            <div className="table-responsive">
                                <EisAuditTrailTable content={this.state.content} retrieveAuditEIS={this.retrieveAuditEIS} />
                            </div>
                            <hr />
                            <div className="table-bottom-bar">
                                <div className="row">
                                    <ShowItems
                                        size={this.state.limit}
                                        handleSizeChange={this.handleSizeChange}
                                    />
                                    <div className="record-count">
                                        Showing items {this.state.firstRecord} -{" "}
                                        {this.state.lastRecord}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default AuditTrailCard;
