import React, { Component } from "react";
import { Spinner, Button } from 'react-bootstrap';
import '../../Campaign/single-view/campaign-single.css';

import CampaignAttribute from '../../Campaign/single-view/CampaignAttribute'
import CampaignQrFileUpload from './CampaignQrFileUpload.js';
import {withOktaAuth} from "@okta/okta-react";

class CampaignQrCSV extends Component {

  constructor(props){
    super(props);
    this.state = {
      oktaAuth: this.props.oktaAuth,
      showFileUpload: false,
      id: this.props.id
    };
  };

  renderTableHeaders(){
    return (
      <thead>
        <tr>
          <th>Attribute</th>
          <th>Value</th>
        </tr>
      </thead>
    )
  }

  renderEmptyTable(isLoading){
    let loadingContent = <td colSpan="2"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
    if (isLoading){
      loadingContent = <td colSpan="2"><Spinner animation="border" size="sm" /></td>;
    }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody>
            <tr>
              {loadingContent}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderTable(){
    let isError = false;
    if (this.props.content?.status === "ERROR"){
      isError = true;
    }

    let operations = <CampaignAttribute title="Number of Operations" value={this.props.content?.number_of_operations} error={isError}/>
    let error = <CampaignAttribute title="Error Message" value={this.props.content?.errorMessage} error={isError}/>
    
    if (this.props.content?.status !== "ERROR"){
      error = <tr></tr>;
    }else{
      operations = <tr></tr>;
    }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody data-tid="csv-file">
              <CampaignAttribute title="Filename" value={this.props.content?.file_name}/>
              <CampaignAttribute title="Status" value={this.props.content?.status} error={isError}/>
              {operations}
              {error}
            </tbody>
        </table>
      </div>
    )
  }

  renderUploadButton(){
    if(this.props.campaignStatus === "PENDING" || this.props.campaignStatus === "ERROR"){
      return(
        <Button className="btn btn-sm rps-action-btn btn-primary" data-cy="upload-button" onClick={e => this.openFileUpload()}>Upload</Button>
      );
    } else {
      return(<div></div>);
    }
  }

  formatDate(timestamp){
    var time = new Date(timestamp);
    return time.toLocaleString('en-GB', { localeMatcher: "best fit" , dateStyle: "long"});
  }

  formatTime(timestamp){
    var time = new Date(timestamp);
    return time.toLocaleString('en-GB', { localeMatcher: "best fit", timeStyle: "medium"});
  }

  closeFileUpload = () => {
    this.setState({showFileUpload: false});
  }

  openFileUpload = () => {
    this.setState({showFileUpload: true});
  }

  render(){
    let tableHTML;

    if (!this.props.content){
      tableHTML = this.renderEmptyTable(true);
    } else {
      tableHTML = this.renderTable();
    }

    var uploadButton = this.renderUploadButton();

    return (
      <div className={"d-grid col-lg-"+this.props.colWidth}>
        <div className="card shadow mb-4">

          <div className="card-body">
            <div className="campaignDetailsTable d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary"><i className="bi bi-lightning-charge"></i> CSV File</h6>
              {uploadButton}
            </div>

            {/* <!-- campaign info table --> */}
            <div className="table-responsive">
              {tableHTML}
            </div>
            <CampaignQrFileUpload showModal={this.state.showFileUpload}
              closeModal={this.closeFileUpload}
              id={this.state.id}
              showAlert={this.props.showAlert}
              refresh={this.props.refresh} />
          </div>
        </div>
      </div>
    )
  }
}

export default withOktaAuth(CampaignQrCSV);