import http from "../api/http-common";

const es2DownloadProfile = (eid, data, config) => {
  return http.post("/rsp/operations/download-profile/eis/" + eid, data, config);
};

const es2DownloadProfileByProfileType = (eid, data, config) => {
  return http.post("/rsp/operations/download-profile-by-profile-type/eis/" +eid , data, config);
};


// const es4GetEis = (eid, data, config) => {
//   return http.post("/rsp/es4/geteis/eis/" + eid, data, config);
// };

const es4AuditEis = (eid, data, config) => {
  return http.post("/rsp/operations/auditeis/eis/" + eid, data, config);
};

const es4EnableProfile = (eid, data, config) => {
  return http.post("/rsp/operations/enable-profile/eis/" + eid, data, config);
};

const es4DisableProfile = (eid, data, config) => {
  return http.post("/rsp/operations/disable-profile/eis/" + eid, data, config);
};

const es4DeleteProfile = (eid, data, config) => {
  return http.post("/rsp/operations/delete-profile/eis/" + eid, data, config);
};

const es4UpdateSubscriptionAddress = (eid, data, config) => {
  return http.post("/rsp/operations/update-subscription-address/eis/"+eid, data, config);
};

const es4SetFallbackAttribute = (eid, data, config) => {
  return http.post("/rsp/operations/set-fallback-attribute/eis/"+eid, data, config);
};

const es4UpdatePolicyRules = (eid, data, config) =>{
  return http.post("/rsp/operations/update-policy-rules/eis/"+eid, data, config);
}
  
const functions = {
  es2DownloadProfile,
  // es4GetEis,
  es4AuditEis,
  es4EnableProfile,
  es4DisableProfile,
  es4DeleteProfile,
  es4UpdateSubscriptionAddress,
  es4SetFallbackAttribute,
  es2DownloadProfileByProfileType,
  es4UpdatePolicyRules
};

export default functions;
