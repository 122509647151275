import React, { Component } from "react";

class Search extends Component {

    render(){
        let searchBar;
        if (this.props.value !== undefined){
            searchBar = <input id="searchBar" data-cy="search-bar" type="text" className="form-control bg-light border-0 small" 
            defaultValue={this.props.value}
            onKeyPress={this.props.handleSearchBoxChange}
            />
        }else{
            searchBar = <input id="searchBar" data-cy="search-bar" type="text" className="form-control bg-light border-0 small" 
            placeholder={this.props.placeholder}
            onKeyPress={this.props.handleSearchBoxChange}
            />
        }
        
        return <div className="col">    
                {/* <form className=""> */}
                    <div className="input-group">
                        {searchBar}
                        <div className="input-group-append">
                            <button className="btn btn-primary" data-tid="searchButton" type="button" onClick={this.props.handleSearchBoxClick}>
                                <i className="fas fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                {/* </form> */}
            </div>
    }
};
    
export default Search;