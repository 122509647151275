import React, { Component} from "react";
import { withRouter } from "react-router-dom";
import './tableButton.css';

import { withOktaAuth } from '@okta/okta-react';
import './consumerProfiles.css'

class ProfileItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
          oktaAuth: this.props.oktaAuth
        }
      }
    
    render()
    {
        let profileStatusBadge = <span style={{backgroundColor: this.props.stateColors[this.props.profile.state].toUpperCase()} } className="badge text-light">{this.props.profile.state}</span>;

        return <tr id ={this.props.position} className={`tr${this.props.rowIsActive === this.props.position ? 'active' : ''}`}
         onClick={() => this.props.onClick(this.props.position, this.props.profile.iccid)}>
          <td className="col-md-4" data-tid="iccId">{this.props.profile.iccid}</td>
          <td className="col-md-3" data-tid="mnoId">{this.props.profile.mnoId}</td>
          <td className="col-md-3" data-tid="profileType">{this.props.profile.profileType}</td>
          <td className="col-md-2" data-tid="state">{profileStatusBadge}</td>
        </tr>
    }
};
    
export default withOktaAuth(withRouter(ProfileItem));