import http from "../api/http-common";

const getMaintenaceWindowAlert = (accessToken) => {
	return http.get("/ksm/mw/", accessToken);
};

const functions = {
	getMaintenaceWindowAlert,
};

export default functions;
