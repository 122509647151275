import React, { Component } from 'react';
import "./AlertOperationRequest.css";

class QRModalWindow extends Component {

    createEidActivationModal = () => {
        const spinner = (
            <div>
                <h5>Generating QR code...</h5>
                <p></p>
            <div className="spinner-border" role="status">

            </div>
            </div>
        )

        let content = spinner;

        if (this.props.error){
            content = (
                <> <h5>
                    Unable to generate codes - Please check your input values.
                </h5>
                </>
            )
        }
        if (this.props.show){
            const qr = this.props.response;
            content = (
                <> <h5 className="modal-title" id="exampleModalLabel">Activation Code:</h5>
                    <p>{qr.activationCode}</p>
                    <p>
                        <img src={qr.qrCode} alt="QR"/>
                    </p>
                    <h6>Profile Iccid: {qr.iccid}</h6>
                </>
            )
        }

        return (
            <>
                {/* THIS MIGHT NEED TO BE MOVED */}
                <div className="modal fade" id={'activateModal'} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ cursor: 'default' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="h4 text-gray-900 mb-4">
                                    <img id="kigenWhiteLogo" src="img/kigen-dark-logo.svg" alt=""/>
                                </h1>


                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => window.location.reload()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body fontSizeTable">
                                {content}
                            </div>
                            <div className="modal-footer">
                                <button id={'cancelActivateBtn'} type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => window.location.reload()}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }

    render(){
        return (
            <>
                {this.createEidActivationModal()}
            </>
        )
      }
}

export default QRModalWindow;