import React, { Component } from "react";
import {Spinner} from 'react-bootstrap';

import ProfileItem from './ProfileItem';

class ProfileTable extends Component {
  renderTableHeaders(){
    return (
      <thead>
        <tr>
          <th>Iccid</th>
          <th>Mno Id</th>
          <th>Profile Type</th>
          <th>Imsi</th>
          <th>Msisdn</th>
          <th>State</th>
        </tr>
      </thead>
    )
  }

  renderEmptyTable(isLoading){
      let loadingContent = <td colSpan="8"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
      if (isLoading){
        loadingContent = <td colSpan="8"><Spinner animation="border" size="sm" /></td>;
      }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody>
            <tr>
              {loadingContent}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderTable(){
    return (
      <div className="table-responsive">
        <div id="alert-form" className="col-lg-12 d-grid" />
        <table className="table table-striped table-hover" data-tid="profile-table">
          {this.renderTableHeaders()}
          <tbody>
            {
              this.props.content && this.props.content.map((profile, index) => (
                <ProfileItem profile={profile} key={index}/>
              )) 
            }
          </tbody>
        </table>
      </div>
    )
  }

  render(){ 
    if (this.props.content === null){
      return this.renderEmptyTable(true);

    } else if (this.props.content.length === 0){
      return this.renderEmptyTable(false);

    } else{
      return this.renderTable();
    }
  }
}

export default ProfileTable;