import React, { Component } from "react";
import moment from "moment";

import './DateToggle.css'

class DateToggle extends Component {
    renderDateFormat(){
      if (this.props.type === 1){
        return moment(this.props.value).startOf('minutes').fromNow()
        
      } else if (this.props.type === 2){
        return moment(this.props.value).utc().format("MMMM Do YYYY, h:mm:ss a")

      } else if (this.props.type === 3){
          return moment(this.props.value).utc().format("MMMM Do YYYY")
      }
    }

    render() {
      let dateId;

      if(this.props.dateId){
        dateId = this.props.dateId
      }
      if (this.props.notTable){
        return (
          <div onClick = {() => this.props.toggleFunction(this.props.type)}>
            <span className="dottedUnderline">{this.renderDateFormat()}</span>
          </div>
        )
      }else{
        return (
          <td onClick = {() => this.props.toggleFunction(this.props.type)} id={dateId}>
            <span className="dottedUnderline">{this.renderDateFormat()}</span>
          </td>
        )
      }
    }

}

export default DateToggle