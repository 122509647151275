import React from 'react';
import './login-form.css'
import { useOktaAuth } from '@okta/okta-react';

const LoginForm = () => {
    const { oktaAuth, authState } = useOktaAuth();
    if(!authState) return null;

    const login = async () => {
        oktaAuth.signInWithRedirect({originalUri: '/' });
    };
    const logout = async () =>  {
        const loggedIn = await oktaAuth.isAuthenticated();
        if(loggedIn) {
            await oktaAuth.signOut({ clearTokensBeforeRedirect : true });
            oktaAuth.tokenManager.clear();
        } else {
            window.location.href = '/';
        }

    }

    const button = authState.isAuthenticated ?
        <button className="btn btn-primary btn-user btn-block" onClick={logout}>Logout</button> :
        <button className="btn btn-primary btn-user btn-block" onClick={login}>Login</button>;

    return (
        <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                        {/* <!-- Nested Row within Card Body --> */}
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-block bg-login-image">
                            </div>
                            <div className="col-lg-6">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">
                                            <img id="kigenWhiteLogo" src="img/kigen-dark-logo.svg" alt=""/>
                                        </h1>
                                    </div>
                                    {button}
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LoginForm;
