import React, { Component } from "react";
import './eis-single.css'

import EisService from '../../../services/EisService';

import HighlightCardValue from '../../HighlightCardValue'
import EisProfileInfoTable from './EisProfileInfoTable'
import AdvanceSupport from './advance-support/AdvanceSupport'

import SingleRspOperationsButtons from "./rsp-operations/SingleRspOperationsButtons"
import OperationsService from "../../../services/OperationsService";

import HttpUtil from '../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';

import UpdateProfile from './UpdateProfile.js';

class EisSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eid: this.props.eid,
      anchor: this.props.anchor,
      eidData: null,
      enabledIccid: '',
      oktaAuth: this.props.oktaAuth,
      showUpdateSubscriptionAddress: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideProfileUpdate = this.hideProfileUpdate.bind(this);
  }

  retrieveEid = () => {
    this.setState({eidData: null});

    const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
    EisService.getEid(this.state.eid, config)
    .then((response) => {
        var eidData = response.data;
        this.setState({eidData: eidData});
        this.findEnabledProfileIccid();
      })
    .catch((e) => {
      window.location.href = "/404";
      this.setState({eidData: []});

    });
  };

  findEnabledProfileIccid(){
    if (this.state.eidData.profileInfo !== undefined){
      for (let profile of this.state.eidData.profileInfo) {
        if (profile.state === "ENABLED"){
          this.setState({enabledIccid: profile.iccid});
        }
      }
    }
  }

  componentDidMount() {
    // load data on first component render
    this.retrieveEid();
  }

  showModal = (iccid, imsi, msisdn,pol2Rules) => {
    this.setState({showUpdateSubscriptionAddress: true})
    this.setState({iccid: iccid})
    this.setState({imsi: imsi})
    this.setState({msisdn: msisdn})
    this.setState({pol2Rules: pol2Rules})
  };

  hideProfileUpdate = () => {
    this.setState({showUpdateSubscriptionAddress: false})
    this.setState({iccid: undefined})
    this.setState({imsi: undefined})
    this.setState({msisdn: undefined})
    this.setState({pol2Rules: undefined})
  };

  sendUpdateAddressRequest = (imsi, msisdn) => {
    // send rsp request
    OperationsService.updateSubscriptionAddress(
      this.state.eid,
      this.state.iccid,
      imsi,
      msisdn,
      this.state.oktaAuth.getAccessToken(),
      this.retrieveEid
    );
    // close Update Subscription Address modal window
    this.hideProfileUpdate();
  }

  sendUpdatePolicyRulesRequest = (data) =>{
    OperationsService.updatePolicyRules(this.state.eid,data,this.state.oktaAuth.getAccessToken(),this.retrieveEid)
    // close Update Policy Rules modal window
    this.hideProfileUpdate();
  }

  render() {
    return (
      <div>
        {/* <!-- alert banner --> */}
        <div className="row">
          <div id="alert-banner" className="col-lg-12 d-grid" />
        </div>

        {/* <!-- eis card overview & rsp actions --> */}
        <div className="row">
          <HighlightCardValue colWidth="4" icon="" bold=""
            name="EID" value={this.state.eid} color="warning" />

          <HighlightCardValue colWidth="4" icon="" bold=""
            name="Current Enabled Iccid" value={this.state.enabledIccid} color="success" />

          <SingleRspOperationsButtons colWidth="4" name="RSP single operations" color="info"
            eid={this.state.eid} profiles={this.state.eidData} />
        </div>

        {/* <!-- profile information  --> */}
        <div className="row">
          <EisProfileInfoTable content={this.state.eidData} onRefresh={this.retrieveEid}
            openModal={this.showModal} />
        </div>

        {/* <!-- Update Subscription Address Modal window  --> */}
        <UpdateProfile showModal={this.state.showUpdateSubscriptionAddress}
                       closeModal={this.hideProfileUpdate}
                       sendUpdateRequest={this.sendUpdateAddressRequest}
                       sendPol2Request={this.sendUpdatePolicyRulesRequest}
                       initialImsi={this.state.imsi}
                       initialMsisdn={this.state.msisdn}
                       iccid={this.state.iccid}
                       pol2Rules={this.state.pol2Rules}
        />

        {/* <!-- advance support --> */}
        <div className="row">
          <AdvanceSupport eid={this.state.eid} anchor={this.state.anchor} location={this.props.location}/>
        </div>

      </div>
    );
  }
}

export default withOktaAuth(EisSingle);
