import React, { Component } from "react";
import {Spinner, OverlayTrigger, Tooltip} from 'react-bootstrap';
import AuditItem from './AuditItem'

class EisAuditTrailTable extends Component {
  renderTableHeaders(){
    return (
      <thead>
        <tr>
          <th>Procedure</th>
          <th>Operation Date</th>
          <th>Iccid</th>
          <th>Status</th>
          <th>Subject</th>
          <th>Reason</th>
          <th>Message</th>
        </tr>
      </thead>
    )
  }

  renderEmptyTable(isLoading){
      let loadingContent = <td colSpan="7"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
      if (isLoading){
        loadingContent = <td colSpan="7"><Spinner animation="border" size="sm" /></td>;
      }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          {this.renderTableHeaders()}
          <tbody>
            <tr>
              {loadingContent}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderTable(){
    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover" data-tid='audit-trail-table'>
            {this.renderTableHeaders()}
            <tbody>
              {
                  this.props.content && this.props.content.eidAuditData.auditTrailRecords && this.props.content.eidAuditData.auditTrailRecords.map((auditRecord, index) => (
                    <AuditItem auditRecord={auditRecord} key={index}/>
                  ))
              }
            </tbody>
        </table>
      </div>
    )
  }


  render(){
    var tableHTML = "";
    let numRecords = 0;

    if (this.props.content === null){
      tableHTML = this.renderEmptyTable(true);

    } else if (this.props.content.eidAuditData === null){
        tableHTML = this.renderEmptyTable(true);

    } else if (this.props.content.eidAuditData.length === 0){
      tableHTML = this.renderEmptyTable(false);

    } else {
        numRecords = this.props.content.eidAuditData.auditTrailRecords.length
        tableHTML = this.renderTable();
    }

    return (
      <div>
        <div className="row bottom-margin">
            <div className="col-8 text-vcenter">
                <h5 className="text-primary"><i className="bi bi-list-ol"></i>  Audit Trail - (Last {numRecords} Records)</h5>
                Here are the <strong>last {numRecords} operations</strong> that have been executed on this eSIM.
            </div>
            <div className="col-4 action-btns-right">
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-bottom">Refresh Audit Trail</Tooltip>}>
                    <button type="button"
                        onClick={e => this.props.retrieveAuditEIS()}
                        data-cy="refresh-audit-trail-button"
                        className="btn btn-circle rps-action-btn btn-info">
                        <i className="bi bi-arrow-counterclockwise fa-sm fa-fw"></i>
                    </button>
                </OverlayTrigger>
            </div>
        </div>

        <div className="table-responsive">
          {tableHTML}
        </div>
      </div>
    )
  }
}

export default EisAuditTrailTable;
