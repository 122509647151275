import Cookies from 'js-cookie';
import extractDomain from 'extract-domain';


const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const REDIRECT_URI = `${window.location.origin}/callback`;
const ROLE_SCOPE = process.env.REACT_APP_ROLE_SCOPE;

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'kigen_subscription_manager', 'profile', 'email', 'offline_access', ROLE_SCOPE],
    pkce: true,
    tokenManager: {
      syncStorage: false,
      autoRenew: true,
      storage: {
        getItem: (key) => {
          try {
            const allCookies = Cookies.get();
            const value = {};
            if (allCookies) {
              Object.keys(allCookies).forEach((k) => {
                if (k.indexOf(key) === 0) {
                  value[k.replace(`${key}_`, '')] = JSON.parse(allCookies[k]);
                }
              });
            }
            return JSON.stringify(value);
          } catch (e) {
            return null;
          }
        },
        removeItem: (key) => {
          const allCookies = Cookies.get();
          if (allCookies) {
            Object.keys(allCookies).forEach((k) => {
              if (k.startsWith(key)) {
                const extractedDomain = extractDomain(window.location.origin);
                if (!extractedDomain.includes('localhost')) {
                  Cookies.remove(k, { domain: `.${extractedDomain}` });
                } else {
                  Cookies.remove(k);
                }
              }
            });
          }
        },
        setItem: (key, val) => {
          try {
            const value = JSON.parse(val);
            let options = { expires: 1 };
            const extractedDomain = extractDomain(window.location.origin);
            if (!extractedDomain.includes('localhost')) {
              options = Object.assign(options, { domain: `.${extractedDomain}` });
            }
            Object.keys(value).forEach((k) => {
              const storageKey = `${key}_${k}`;
              delete value[k].value;
              Cookies.set(storageKey, JSON.stringify(value[k]), options);
            });
          } catch (e) {
            // do-nothing
          }
        }
      }
    },
    cookies: {
      sameSite: 'lax'
    }
  },
};

export default config;
