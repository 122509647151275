import React, { Component } from "react";

class CampaignProgress extends Component {
    render(){    
        return (
            <div className="col-md-12 mb-4 col-xl-12">
                <div className="card">
                    <div className="card-body">
                    <h6>Campaign Progress</h6>
                    <div className="progress">
                        <div className="progress-bar bg-warning" data-tid="campaign-progress-expired" role="progressbar" style={{width: this.props.expired + '%'}} aria-valuenow={this.props.expired} aria-valuemin="0" aria-valuemax="100">{this.props.expired}% [Expired]</div>
                        <div className="progress-bar bg-danger" data-tid="campaign-progress-error" role="progressbar" style={{width: this.props.error + '%'}} aria-valuenow={this.props.error} aria-valuemin="0" aria-valuemax="100">{this.props.error}% [Error]</div>
                        <div className="progress-bar bg-success" data-tid="campaign-progress-success" role="progressbar" style={{width: this.props.success + '%'}} aria-valuenow={this.props.success} aria-valuemin="0" aria-valuemax="100">{this.props.success}% [Success]</div>
                        <div className="progress-bar bg-custom-warning" data-tid="campaign-progress-warning" role="progressbar" style={{width: this.props.executedWithWarning + '%'}} aria-valuenow={this.props.executedWithWarning} aria-valuemin="0" aria-valuemax="100">{this.props.executedWithWarning}% [Warning]</div>
                        <div className="progress-bar bg-secondary progress-bar-striped progress-bar-animated" data-tid="campaign-progress-pending" role="progressbar" style={{width: this.props.pending + '%'}} aria-valuenow={this.props.pending} aria-valuemin="0" aria-valuemax="100">{this.props.pending}%
                        {
                            (this.props.campaignStatus === "CANCELLED")
                            ? " [Cancelled]"
                            : " [Pending]"
                        }
                         </div>
                    </div>
                    </div>
                </div>
                
            </div>
        )
      }
    }
    
    export default CampaignProgress;    