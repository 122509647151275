import React from 'react';
import './footer.css';

const Footer = () => (
    <footer className="sticky-footer bg-footer">

        <div className="container mx-auto">
            <div className="copyright text-center mx-auto">
                <span>Copyright &copy; {(new Date().getFullYear())} Kigen. All rights reserved.</span>
            </div>
        </div>

        <div className="container mx-auto" style={{"paddingTop":"10px"}}>
            <div className="supportPortal text-center mx-auto">
                <a href={process.env.REACT_APP_FRESHDESK_SUPPORT_PORTAL_URL} target="_blank" rel="noopener noreferrer">Support Portal</a>
            </div>
        </div>

        <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up scroll-to-top-icon"></i>
        </a>

    </footer>
);

export default Footer;