import React, { Component } from "react";

class EisItem extends Component {
    render(){
        
        return <tr>
            <td className="click-row" data-cy="e-id">
                <a href={'/esims?eid=' + this.props.eis.eid}>{this.props.eis.eid}</a>
            </td>
            {/* <td><span className="badge bg-primary text-light">{this.props.eis.numberOfProfiles}</span></td> */}
            <td data-cy="number-of-profiles"><span className="badge rounded-pill bg-light text-dark">{this.props.eis?.numberOfProfiles}</span></td>
            <td data-cy="enabled-iccid">{this.props.eis?.enabled?.iccid}</td>
            <td data-cy="profile-type">{this.props.eis?.enabled?.profileType}</td>
            <td data-cy="fallback-activate">{!this.props.eis?.enabled?.fallbackAttribute ? "" : this.props.eis?.enabled?.fallbackAttribute === 'Yes' ? "True" : "False"}</td>
        </tr>
    }
};
    
export default EisItem;
