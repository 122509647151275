import React, { Component } from 'react';
import QueryString from 'query-string';

import './eis.css'

//Navigation
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Footer from '../../components/Footer';

import EisCard from '../../components/EIS/list-view/EisCard';
import EisSingle from '../../components/EIS/single-view/EisSingle';
import { withOktaAuth } from '@okta/okta-react';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    // redirect to 404 if user doesn't have campaign role
    const claims = this.props.authState.accessToken.claims;
    if(claims){
      const claimKeys = Object.keys(claims);
      const roleKey = claimKeys?.find(key => /roles_/.test(key));
      const roles = claims[roleKey];
      const m2mRole = roles?.find(value => /ksm_m2m/.test(value));
      if(!m2mRole){
        window.location.href = "/404";
      }
    }
  }
  render() {
    let params = QueryString.parse(this.props.location.search)

    var size = params.size;
    if (size === undefined) size = 10;


    var page = params.page;
    if (page === undefined) page = 0;

    var search = params.search;
    
    // default to render list view
    var viewToRender = <EisCard size={size} page={page} search={search}/>;

    // render EisSingle if `eid` param is set
    if ((params.eid !== undefined) && (params.eid !== "")){
      // handle page anchor
      let anchor = params.advance_support;
      if (anchor === undefined){
        anchor = "auditTrails";
      }
      // render single view
      viewToRender = <EisSingle eid={params.eid} anchor={anchor} location={this.props.location}/>
    }

    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          <Sidebar active="esims"/>

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">

            {/* <!-- Main Content --> */}
            <div id="content">
              <Topbar icon="bi-cpu" title="eSIMs"/>

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">
                {viewToRender}
              </div>
              {/* <!-- /.container-fluid --> */}

            </div>
            {/* <!-- End of Main Content --> */}

            <Footer />
          </div>
          {/* <!-- End of Content Wrapper --> */}

        </div>
        {/* <!-- End of Page Wrapper --> */}
      </div>
    )
  }
}

export default withOktaAuth(Dashboard);