import React, { Component } from 'react';
import './news-side-card.css'
import { withOktaAuth } from '@okta/okta-react';
import HttpUtil from '../../api/http-util';
import NewsService from '../../services/NewsService';

class NewsSideCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsSide: [],
            oktaAuth: this.props.oktaAuth,
        }
    }
    componentDidMount(){
        this.setState({newsSide: this.retrieveNewsSide()});
    }

    retrieveNewsSide = () => {
        if(localStorage.getItem("sidebar_news") === null){
          const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
          NewsService.getSidebarNews(config).then((response) => {
              var news = response.data.Items;
              //setting to 2am to take account of BST (-1 hour), so it will always be a new day before the token is refreshed.
              var d = new Date();
              var tomorrow_at_2am =  Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate() + 1, 2, 0, 0);
              news[0].ttl = tomorrow_at_2am
              localStorage.setItem("sidebar_news", JSON.stringify(news));
              this.setState({newsSide: news});
          })
          .catch((e) => {
              this.setState({newsSide: []});
          });
        }
        else{
            var news = JSON.parse(localStorage.getItem("sidebar_news"));
            if(new Date().getTime() > news[0].ttl ){
                localStorage.removeItem("sidebar_news");
                this.retrieveNewsSide();
            }
            else{
                return news
            }
        }
    }

    renderSideCard(newsItem){
        if (newsItem !== undefined){
            return (
                <div className={this.props.toggled ? 'sidebar-card d-none d-lg-flex invisible' : 'sidebar-card d-none d-lg-flex'}>
                    <img id="newsCardImage" data-cy="news-sidebar-image" className="sidebar-card-illustration mb-2" src={newsItem.image} alt=""/>
                    <p id="newsCardTitle" data-cy="news-sidebar-title" className="text-center mb-2">{newsItem.title}</p>
                    <a className="btn btn-primary btn-sm" data-cy="news-sidebar-link" href={newsItem.link} target="_blank" rel="noopener noreferrer">Learn More</a>
                </div>
            )
        }
    }

    renderNewsSide(){
        if ( (this.state.newsSide !== undefined) && (this.state.newsSide !== []) ){
            return this.renderSideCard(this.state.newsSide[0]);
        }
    }

    render(){
        return (
            <>{this.renderNewsSide()}</>
        )
    }
}

export default withOktaAuth(NewsSideCard);
