import React from 'react';
import './highlight-card.css'

const handleClick = (myLink) => () => {
    if (myLink !== ""){
        window.location.href=myLink;
    }
}

const HighlightCard = ({  name = 'Highlight Card - No Name ', fill="h-100", icon='bi-question-circle', value='0', color="primary", link = '', colWidth = '3', bold='font-weight-bold'}) => (
    <div className={'click-row col-md-12 mb-4 click-card col-xl-' + colWidth} onClick={handleClick(link)}>
        <div className={'card shadow py-2 border-left-' + color + " " + fill}>
            <div className="card-body">
                <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                        <div className={'text-xs font-weight-bold text-uppercase mb-1 text-' + color}>{name}</div>
                        <div data-tid={name} className={'h6 mb-0 text-gray-800 ' + bold}>{value}</div>
                    </div>
                    <div className="col-auto">
                        <i className={'bi fa-2x ' + icon}></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default HighlightCard;