import React, { Component } from "react";

// navigation
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
// cards
import HighlightCardValue from "../../components/HighlightCardValue";
import CountPerMonthCard from "../../components/CountPerMonthCard";
import CountPerMonthCardNoAccess from "../../components/CountPerMonthCardNoAccess";
import NewsReel from "../../components/NewsReel";

// footer
import Footer from "../../components/Footer";

import MonthlyGraphService from "../../services/MonthlyGraphService";
import HttpUtil from "../../api/http-util";

import { withOktaAuth } from "@okta/okta-react";

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			highlight: {},
			oktaAuth: this.props.oktaAuth,
			eisCount: 0,
			eisMonths: {},
			profileCount: 0,
			profileMonths: {},
			customer: '',
			profileCountByType: {},
			consumerProfileCount: 0,
			consumerProfileMonths: {},
		};
	}

	componentDidMount() {
		this.loadEisCount();
		this.loadProfileCount();
		this.loadConsumerProfileCount();
	}

	checkConsumerAccess() {
		const claims = this.props?.authState?.accessToken?.claims;
		if (claims) {
			const claimKeys = Object.keys(claims);
			const roleKey = claimKeys?.find(key => /roles_/.test(key));
			const roles = claims[roleKey];
			const consumerRole = roles?.find(value => /consumer/.test(value));
			if (consumerRole) {
				return true;
			} else {
				return false;
			}
		}
	}

	checkM2MAccess() {
		const claims = this.props?.authState?.accessToken?.claims;
		if (claims) {
			const claimKeys = Object.keys(claims);
			const roleKey = claimKeys?.find(key => /roles_/.test(key));
			const roles = claims[roleKey];
			const m2mRole = roles?.find(value => /ksm_m2m/.test(value));
			if (m2mRole) {
				return true;
			} else {
				return false;
			}
		}
	}

	loadEisCount() {
		if (this.checkM2MAccess()) {
			MonthlyGraphService.getEisCount(HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken()))
				.then((response) => {
					var eisCount = response.data.count;
					this.setState({ eisCount: eisCount });

					var months = response.data.allMonths.Item.months;
					this.setState({ eisMonths: months });
				})
				.catch((e) => {
					this.setState({ eisCount: 0 });
					this.setState({ eisMonths: {} });
				});
		}
	}

	loadProfileCount() {
		if (this.checkM2MAccess()) {
			MonthlyGraphService.getProfileCount(HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken()))
				.then((response) => {
					var profileCount = response.data.count;
					this.setState({ profileCount: profileCount });
					var months = response.data.allMonths.Item.months;

					this.setState({ profileMonths: months });

				})
				.catch((e) => {
					this.setState({ profileCount: 0 });
				});
		}
	}

	loadConsumerProfileCount() {
		if (this.checkConsumerAccess()) {
			MonthlyGraphService.getConsumerProfileCount(HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken()))
				.then((response) => {
					var profileCount = response.data.count;
					this.setState({ consumerProfileCount: profileCount });
					var months = response.data.allMonths.Item.months;

					this.setState({ consumerProfileMonths: months });

				})
				.catch((e) => {
					this.setState({ consumerProfileCount: 0 });
				});
		}
	}

	getWelcomeMessage() {
		const fullName = localStorage.getItem("oktaUserName");

		if ((fullName === undefined) || (fullName === null)) {
			return 'Welcome!';
		} else {
			const names = fullName.split(" ");

			return `Welcome, ${names[0]}!`;
		}
	}

	getCurrentDateString(separator = ' ') {
		const date = new Date();
		const month = date.toLocaleString('default', { month: 'long' });
		const day = date.toLocaleString('default', { day: 'numeric' });
		const year = date.toLocaleString('default', { year: 'numeric' });

		return `${month}${separator}${day}${separator}${year}`;
	}

	countCard(colour, title, months) {
		return (
			<CountPerMonthCard
				color={colour}
				colWidth="4"
				title={title}
				months={months}
			/>
		);
	}

	countCardNoAccess(colour, title) {
		return (
		<CountPerMonthCardNoAccess
			color={colour}
			colWidth="4"
			title={title}
			months={{}}
			message="Licensed product only: Please contact your Kigen account manager."
		/>
		);
	}

	countHighlightCard(colour, title, value, icon, link) {
		return (
			<HighlightCardValue
				name={title}
				colWidth="4"
				icon={icon}
				value={value}
				color={colour}
				link={link}
			/>
		);
	}

	render() {

		return (
			<div>
				{/* <!-- Page Wrapper --> */}
				<div id="wrapper">
					<Sidebar active="Home" />

					{/* <!-- Content Wrapper --> */}
					<div id="content-wrapper" className="d-flex flex-column">
						{/* <!-- Main Content --> */}
						<div id="content">
							<Topbar icon="bi-house" title="Dashboard" />

							{/* <!-- Begin Page Content --> */}
							<div className="container-fluid">
								{" "}
								<div className="row">
									<div className="col">
										<div className="row col">
											<h1>{this.getWelcomeMessage()}</h1>
										</div>
										<div className="row col">
											<p>These are your analytics for today, {this.getCurrentDateString()}</p>
										</div>
									</div>
								</div>
								<div>
									{/* DASHBOARD MAIN CONTENT HERE */}
									{/* For populating the value we are using a ternary operator to determine whether or not to render a value or 0 */}
									<div className="row">
										{this.countHighlightCard("success", "EIS (Total)", this.checkM2MAccess() ? this.state.eisCount : 0, "bi-cpu", "/esims")}
										{this.countHighlightCard("primary", "M2M Profiles (Total)", this.checkM2MAccess() ? this.state.profileCount : 0, "bi-box", "/profiles")}
										{this.countHighlightCard("info", "Consumer Profiles (Total)", this.checkConsumerAccess() ? this.state.consumerProfileCount : 0, "bi-box", "/consumerprofiles")}
									</div>
									{/*  For populating the graph we are using a ternary operator to determine whether or not to render the graph or a licensing message */}
									<div className="row">
										{this.checkM2MAccess() ? this.countCard("success", "EIS Count", this.state.eisMonths) : this.countCardNoAccess("success", "EIS Count")}
										{this.checkM2MAccess() ? this.countCard("primary", "M2M Profile Count", this.state.profileMonths) : this.countCardNoAccess("primary", "M2M Profile Count")}
										{this.checkConsumerAccess() ? this.countCard("info", "Consumer Profile Count", this.state.consumerProfileMonths) : this.countCardNoAccess("info", "Consumer Profile Count")}
									</div>
								</div>
								<hr />
								<div className="row mb-4">
									<NewsReel />
								</div>
							</div>
							{/* <!-- /.container-fluid --> */}
						</div>
						{/* <!-- End of Main Content --> */}
						<Footer />
					</div>
					{/* <!-- End of Content Wrapper --> */}
				</div>
				{/* <!-- End of Page Wrapper --> */}
			</div>
		);
	}
}

export default withOktaAuth(Dashboard);
