import React, { Component } from "react";
import "./campaign.css"
import CampaignService from '../../../services/CampaignService';
import HttpUtil from '../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';
import AlertOperationRequest from "../../Campaign/single-view/CampaignOperations/AlertOperationRequest.js";
import ReactDOM from 'react-dom'
import ConsumerProfileInventoryService from "../../../services/ConsumerProfileInventoryService";
import {Tab, Tabs} from "react-bootstrap";

class CreateQRCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            minVp: 1,   //1 seconds
            maxVp: 1800, //30 min
            type: "",
            name: "",
            maxNoOfProfiles: 1,
            oktaAuth: this.props.oktaAuth,
            errorMessage: "Number of profiles must be selected",
            nameError : "A valid name must be selected",
            tabKey: 'profileType',
            csvFile: undefined,
        }
    }

    componentDidMount() {
        this.retrieveProfileCountByTypes();
    }

    getListOfCampaignOptions(response){
        this.setState({"profileCountByType": response.data});
        let options = [];
        let disabledOptions = [];
        if (this.state.profileCountByType && this.state.listInUse) {
            let data = this.state.profileCountByType;
            let profileListTypesInUse = this.state.listInUse;
            for (const entry of data) {
                let profileType = entry.type;
                let total = entry.totalByType;
                let isProfileInUse = profileListTypesInUse.includes(profileType);

                const option = (
                    <option
                        onChange={this.changeProfileType}
                        key={profileType}
                        value={`${profileType}:${total}`}
                        disabled={isProfileInUse} // Disable the option if in use
                        style={isProfileInUse ? { color: 'gray' } : {}} // Style it gray if in use
                    >
                        {profileType} - {total} {isProfileInUse ? '(In Use)' : ''}
                    </option>
                );

                if (isProfileInUse) {
                    disabledOptions.push(option);
                } else {
                    options.push(option);
                }
            }

        }
        this.setState({"options" : options, "disabledOptions":disabledOptions})
        this.setState({"maxNoOfProfiles": options[0]?.props?.children?.[2]})
    }

    retrieveProfileCountByTypes() {
        ConsumerProfileInventoryService.getRunningCampaignProfileTypeList(HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken()))
            .then((response) => this.setState({"listInUse": response.data}))
            .catch((e) => {
                console.log(
                    "An error occurred trying to list profile states by count",
                    e
                );
            })

        ConsumerProfileInventoryService.listProfileTypesBycount(HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken()))
            .then((response) =>{
                    this.getListOfCampaignOptions(response);
            }
            )
            .catch((e) => {
                console.log(
                    "An error occurred trying to list profile states by count",
                    e
                );
            })

    }

    renderModalOpenButton = (title) => {
        return (
            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#createCampaignModal" data-tid="new-campaign">
                <i className="bi bi-plus-square"></i> {title}
            </button>
        )
    }

    setTabKey = (key) => {
        this.setState({ "tabKey": key});
    }

    renderModalWindow = (title) => {
        let buttonDisabled;
        if(this.state.tabKey === "profileType" && (this.state.errorMessage || this.state.nameError)){
            buttonDisabled = true;
        } else if(this.state.tabKey === "csv" && this.state.nameError){
            buttonDisabled = true;
        }

        return (
            <>
                <div className="modal fade campaignModal" id="createCampaignModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="createCampaignModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="createCampaignModalLabel">{title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetModalWindow}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                    <Tabs
                                        activeKey={this.state.tabKey}
                                        style={{ border: 'none' }}
                                        onSelect={(k) => this.setTabKey(k)}>
                                        <Tab eventKey="profileType"
                                            title="Profile Type"
                                            active={this.state.tabKey === 'profileType'}
                                            style={{
                                                backgroundColor: 'aliceblue',
                                                padding: '16px' }}>
                                            <div>
                                                {this.renderCampaignName()}
                                                {this.renderCampaignType()}
                                                {this.renderNoOfProfiles()}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="csv"
                                            title="CSV"
                                            active={this.state.tabKey === 'csv'}
                                            style={{
                                                backgroundColor: 'aliceblue',
                                                padding: '16px' }}>
                                            <div>
                                                {this.renderCampaignName()}
                                                {this.uploadCSV()}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.resetModalWindow}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" disabled={buttonDisabled} onClick={e => this.createCampaign(e)} data-tid="create-campaign">Create</button>
                                </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderNoOfProfiles() {

        const {errorMessage } = this.state;
            return (
                <div className="mb-3">
                    <label htmlFor="NoOfProfiles" className="form-label title-bold">Choose the number of profiles to be included in this campaign</label>
                    <input type="number" className="form-control" id="NoOfProfiles" onKeyDown={(e) =>["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                           min="1" max={this.state.maxNoOfProfiles} onChange={this.changeNoOfProfiles} />
                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                </div>
            )
    }


    renderCampaignName() {
        // Set Campaign Name
        const {nameError } = this.state;
        return (
            <div className="mb-3">
                <label htmlFor="campaignName" className="form-label title-bold">Campaign Name</label>
                <input type="text" className="form-control" id="campaignName" placeholder="Enter new campaign name"
                    maxLength="60" data-tid="campaign-name" onChange={this.changeCampaignName} required pattern="^\S.+" />
                {nameError && <div style={{ color: 'red' }}>{nameError}</div>}
            </div>
        )
    }

    renderCampaignType() {
        return (
            <>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label title-bold">Profile Type - No of Available Profiles</label>
                    <select id="profileType" className="form-select form-select-lg mb-3 campaignType" aria-label=".form-select-lg example"
                        defaultValue="download-profile" aria-describedby="basic-addon2" data-tid="campaign-type" onChange={this.changeProfileType}>
                        {this.state.options}
                        {this.state.disabledOptions}
                    </select>
                </div>
                <div className="mb-3">

                </div>
            </>
        )
    }





    resetModalWindow = () => {
        if (this.state.tabKey === 'profileType') {
            const campaignName = document.getElementById("campaignName");
            const noOfProfiles =document.getElementById("NoOfProfiles");
            campaignName.value = "";
            noOfProfiles.value = "";
        }
    }

    changeCampaignName = (event) => {
        const enteredValue = event.target.value;
        // Check if the entered value matches the pattern
        const pattern = /^\S.+/;
        if (!pattern.test(enteredValue)) {

            this.setState({ nameError: "Campaign name must start with a non-whitespace character." });
            return;
        }

        // If the entered value is valid, update the state
        this.setState({ name: enteredValue, nameError: null });
    }

    changeProfileType = (event) => {
        document.getElementById("NoOfProfiles").value = "";
        const selectedValue = event.target.value;
        const [type, total] = selectedValue.split(':');
        this.setState({ type: type});
        this.setState({maxNoOfProfiles : total})
        

    }

    changeNoOfProfiles = (event) => {
        const selectedValue = Number(event.target.value);
        let max = Number(this.state.maxNoOfProfiles)
        if(max === 0){
            max = this.state.profileCountByType[0].totalByProfileType
            this.setState({type:this.state.profileCountByType[0].profileType})
        }
        if (selectedValue === 0) {
            this.setState({errorMessage: 'Number of profiles must be selected'});
        } else if (selectedValue > max) {
            this.setState({errorMessage: 'Selected value exceeds the number of AVAILABLE profiles.'});
        } else {
            this.setState({totalProfiles: selectedValue, errorMessage: null});
        }
    };


    showAlert = (variant, heading, message) => {
        ReactDOM.render(
          <AlertOperationRequest  variant={variant} heading={heading} message={message} close={this.hideAlert}/>,
          document.getElementById('alert-form')
        );
        setTimeout(() => this.hideAlert(), 4000);
      };
    
    hideAlert = () => {
        ReactDOM.render("", document.getElementById('alert-form'));
      };

    createCampaign(event) {
        event.preventDefault();
        if (this.state.tabKey === 'profileType') {
            this.createCampaignByProfileType()
        } else if (this.state.tabKey === 'csv') {
            this.createQRByCsvCampaign()
        }
    }


    createQRByCsvCampaign(){
        if (!this.state.csvFile) {
            // Display warning message if no CSV file is selected
            this.showAlert("Warning", "", "Please select a CSV file.");
            return; // Prevent further execution
        }
        let newCampaign = CampaignService.createNewQRCampaignByCsvObject(
            this.state.name,
            'generate-qr'
        )
        const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());

        CampaignService.createQRByCsvCampaign(newCampaign, config).then((response) => {
            var createCampaign = response.data;
            var id = createCampaign.id;

            // upload CVS file to new campaign
            CampaignService.uploadCampaignConsumerCSV(id, this.state.csvFile, this.state.oktaAuth.getAccessToken())
                .then((response) => {
                    window.location.href = "/qrCampaigns?id=" + id;

                }).catch((e) => {
                console.log("error uploading CSV")
                this.setState({ content: [] });
            });

        }).catch((e) => {
            this.setState({ content: [] });
        });

    }

    createCampaignByProfileType(){
        if(!this.state.type){
            this.state.type = this.state.profileCountByType[0].type;
        }

        let newCampaign = CampaignService.createNewQRCampaignByProfileTypeObject(
            this.state.name,
            'generate-qr',
            this.state.type,
            this.state.totalProfiles

        )

        const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
        // create new campaign
        CampaignService.createQRByProfileTypeCampaign(newCampaign, config).then((response) => {
            let createCampaign = response.data;
            let id = createCampaign.id;
            window.location.href = "/qrCampaigns?id=" + id;
        }).catch((e) => {
            this.setState({ content: [] });
        });
    }


    selectFile = (event) => {
        this.setState({ csvFile: null })
        if (!/^([a-zA-Z0-9_\-,()*&.])+$/.test(event?.target?.files?.[0]?.["name"])) {
            this.showAlert("Danger", "File Name Error", "Invalid file name, Valid characters are A-Z a-z 0-9 & * _ -" );
            this.setState({ csvFile: undefined });
        } else {
            this.setState({ csvFile: event.target.files[0] });
        }
    }

    uploadCSV() {
        return (
            <>
                <div className="mb-3">
                    <label htmlFor="campaignName" className="form-label title-bold">Upload CSV</label>
                </div>
                <div className="mb-3 ">
                    <div id="alert-form" />
                    <label className="btn btn-default">
                        <input id="campaignCSV" type="file" data-tid="generate-qr-campaign-csv-upload" onChange={this.selectFile} accept=".csv" required />
                    </label>
                </div>
            </>
        )
    }



    render() {
        return (
            <>
                {this.renderModalOpenButton(this.state.title)}
                {this.renderModalWindow("New QR Campaign")}
            </>
        )
    }
}

export default withOktaAuth(CreateQRCampaign);
