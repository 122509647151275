import ReactDOM from 'react-dom'
import React, { Component } from "react";
import '../../Campaign/single-view/campaign-single.css'

import CampaignService from '../../../services/CampaignService';
import CampaignQrCSV from './CampaignQrCSV'

import HighlightCardValue from '../../HighlightCardValue'
import CampaignInfoTable from '../../Campaign/single-view/CampaignInfoTable'
import CampaignParamsTable from '../../Campaign/single-view/CampaignParamsTable'

import CampaignQrOperationsButtons from './CampaignQrOperationsButtons'
import AlertOperationRequest from "../../EIS/single-view/rsp-operations/AlertOperationRequest"

import CampaignProgress from '../../Campaign/single-view/CampaignProgress'

import HttpUtil from '../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';
import CampaignQRParamsTable from "./CampaignQRParamsTable";
import CampaignCSV from "../../Campaign/single-view/CampaignCSV";

let campaignWithCsvSupplied;

class CampaignQRSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      campaignData: null,
      campaignProgress: null,
      name: "",
      status: "",
      type: "",
      oktaAuth: this.props.oktaAuth,
      error_message: this.props.error
    }
  }

  refreshCampaign = () => {
    this.setState({campaignData: null});

    this.getCampaign();
  };

  getCampaign = () => {
    const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken())
    CampaignService.getConsumerCampaign(this.state.id, config)
        .then((response) => {
          var c = response.data;

          this.setState({campaignData: c});
          this.setState({name: c.name});
          this.setState({status: c.status});
          this.setState({type: c.type});
          campaignWithCsvSupplied = c.properties?.campaignWithCsvSupplied
          // retrieve campaign progress
          this.retrieveCampaignProgress();

        })
        .catch((e) => {
          window.location.href = "/404";
          this.setState({campaign: []});
        })
  }

  retrieveCampaignProgress = () => {
    this.setState({campaignProgress: {success: 0, error: 0, expired: 0, executedWithWarning:0, pending: 100}});

    const campaign = this.state.campaignData;

    // only fetch the campaign metrics if the campaign has been started
    if (campaign.status !== "PENDING"){
      const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
      CampaignService.getConsumerCampaignMetrics(this.state.id, config)
      .then((res) => {
        var metrics = res.data;

        var total = metrics.TOTAL;

        var success = CampaignService.percentage(metrics.EXECUTED_SUCCESS, total);
        var expired = CampaignService.percentage(metrics.EXPIRED, total);
        var failed = CampaignService.percentage(metrics.FAILED, total);
        const executedWithWarning = CampaignService.percentage(metrics.EXECUTED_WITHWARNING, total);

        var pendingCount = total - (metrics.EXECUTED_SUCCESS + metrics.EXPIRED + metrics.FAILED + metrics.EXECUTED_WITHWARNING);
        var pending = CampaignService.percentage(pendingCount, total);

        var progress = {
          success: success,
          error: failed,
          expired: expired,
          pending: pending,
          executedWithWarning: executedWithWarning
        }

        this.setState({campaignProgress: progress});
      })
    }
  }

  componentDidMount() {
    // load data on first component render
    this.refreshCampaign();

    if (this.state.error_message !== undefined){
      this.showErrorAlert();
    }
  }

  showErrorAlert = () => {
    ReactDOM.render(
      <AlertOperationRequest  variant="Danger" heading="Error" message={this.state.error_message} close={this.hideAlert}/>,
      document.getElementById('alert-banner')
    );
    setTimeout(() => this.hideAlert(), 3000);
  }

  showAlert = (type, heading, message) => {
    ReactDOM.render(
      <AlertOperationRequest variant={type} heading={heading} message={message} close={this.hideAlert}/>,
      document.getElementById('alert-banner')
    );
    setTimeout(() => this.hideAlert(), 3000);
  }

  hideAlert = (eid, accessToken) => {
    ReactDOM.render("", document.getElementById('alert-banner'));
  }

  render() {

    return (
      <div>
        <div className="">

          {/* <!-- quick status information  --> */}
          <div className="row">
            <div className="col">
              <CampaignProgress
                success={this.state.campaignProgress?.success}
                error={this.state.campaignProgress?.error}
                expired={this.state.campaignProgress?.expired}
                pending={this.state.campaignProgress?.pending}
                executedWithWarning={this.state.campaignProgress?.executedWithWarning}
                campaignStatus={this.state.campaignData?.status}/>
                <div id="alert-banner" className="col-lg-12 d-grid" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              {/* <HighlightCardValue colWidth="12" icon="" bold="" name="Campaign name" value={this.state.name} color="warning" /> */}
              <HighlightCardValue fill="" colWidth="12" icon="" bold="" name="Campaign Status" value={this.state.status} color="success" />
              <CampaignInfoTable colWidth="12" content={this.state.campaignData} onRefresh={this.refreshCampaign}/>
              {campaignWithCsvSupplied === "true" && (<CampaignQRParamsTable colWidth="12" content={this.state.campaignData} onRefresh={this.refreshCampaign}/>)}
            </div>

            <div className="col">
              <CampaignQrOperationsButtons colWidth="12" color="info" name="Campaign Operations" refresh={this.refreshCampaign}
                  csvReady={this.state?.campaignData?.csv?.status}
                  campaignStatus={this.state?.campaignData?.status}
                  campaignReportStatus={this.state?.campaignData?.report?.status}
                  campaignId={this.props.id}
                  consumerOperation={true}
              />
              {campaignWithCsvSupplied !== "true" && (<CampaignQRParamsTable colWidth="12" content={this.state.campaignData} onRefresh={this.refreshCampaign}/>)}
              {campaignWithCsvSupplied === "true" && (<HighlightCardValue fill="" colWidth="12" icon="" bold="" name="CSV Status" value={this.state?.campaignData?.csv?.status} color="success" />)}

              {campaignWithCsvSupplied === "true" && (<CampaignQrCSV colWidth="12" content={this.state?.campaignData?.csv} onRefresh={this.refreshCampaign}
                           id={this.state.id}
                           showAlert={this.showAlert}
                           refresh={this.getCampaign}
                           campaignStatus={this.state.status}/>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withOktaAuth(CampaignQRSingle);
