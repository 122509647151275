import '../../Campaign/single-view/campaign-single.css';
import React, { useState } from "react";
import { useOktaAuth } from '@okta/okta-react';
import CampaignService from '../../../services/CampaignService';
import AlertOperationRequest from "../../EIS/single-view/rsp-operations/AlertOperationRequest";
import ReactDOM from 'react-dom'

const CampaignQrFileUpload = ({showModal, closeModal, id, showAlert, refresh}) => {

  const showHideClassName = showModal ? "modal display-block" : "modal display-none";
  const { oktaAuth } = useOktaAuth();
  const [csvFile, setCsvFile] = useState();

  const uploadCSV = async (event) => {
    event.preventDefault();
      await CampaignService.uploadCampaignConsumerCSV(id, csvFile, oktaAuth.getAccessToken())
        .then((response) => {
          closeModal();
          showAlert("Info", "Information", response.data);
          setTimeout(() => refresh(), 3000);
        })
        .catch((e) => {
          console.log("error uploading CSV", e);
          closeModal();
          showAlert("Danger", "Error", "Error uploading Campaign CSV File");
        });
  };

  var selectFile = (event) => {
    if (!/^([a-zA-Z0-9_\-,()*&.])+$/.test(event.target.files[0]["name"])) {
        showAlertError("Danger", "File Name Error", "Invalid file name, Valid characters are A-Z a-z 0-9 & * _ -" );
        setCsvFile(undefined);

        document.getElementById("uploadButton").disabled = true;
    } else {
        setCsvFile(event.target.files[0]);
        hideAlert();
        document.getElementById("uploadButton").disabled = false;
    }
}

  var showAlertError = (variant, heading, message) => {
    ReactDOM.render(
      <AlertOperationRequest  variant={variant} heading={heading} message={message} close={hideAlert}/>,
      document.getElementById('modal-alert-form')
    );
  };

var hideAlert = () => {
  ReactDOM.render("", document.getElementById('modal-alert-form'));
};

  return (
    <div className={showHideClassName}>
      <section className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">Upload Campaign CSV File</div>
            <button type="button" data-cy="close-button" className="close" aria-label="Close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="modal-alert-form" className="col-lg-12 d-grid" />
          <form name="uploadCSV" onSubmit={e => uploadCSV(e)} aria-label="Upload a new Campaign CSV file">
          <div className="modal-body">
            <div className="mb-3">
                <label className="btn btn-default">
                <input id="campaignCSV" data-cy="choose-file-button" type="file" onChange={selectFile} accept=".csv" required />
                </label>
            </div>
          </div>
          <div className="modal-footer">
          <input className='btn btn-primary' data-cy="form-upload-button" type='submit' value='Upload' id='uploadButton'/>
          <input className='btn btn-secondary' data-cy="cancel-button" type='button' value='Cancel' onClick={closeModal} />
          </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default CampaignQrFileUpload;
