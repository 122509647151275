import http from "../api/http-common";

const createCampaign = (data, config) => {
  return http.post("/rsp/campaign", data, config);
};

const createQRByProfileTypeCampaign = (data, config) => {
  return http.post("/consumer/campaign", data, config);
};

const createQRByCsvCampaign = (data, config) => {
  return http.post("/consumer/campaign/csv", data, config);
};


const createNewCampaignObject = (name, type, vp, enabled) => {
  var campaign ={
    "name": name,
    "type": type,
    "properties": {
      "validityPeriod": vp,
      //the `type` and `procedure` are currently the same, but could be different in the future - this is future proofing the solution
      "procedure": type,
      "enable": enabled
    }
  }
  return campaign;
}

const uploadCampaignCSV = (id, file, accessToken) => {
  return http.put("/rsp/campaign/" + id + "/upload/" + file.name, file, {
    headers: {
      "Content-type": "text/csv",
      "Authorization": `Bearer ${accessToken}`,
    }
  })
};

const uploadCampaignConsumerCSV = (id, file, accessToken) => {
  return http.put("/consumer/campaign/" + id + "/upload/" + file.name, file, {
    headers: {
      "Content-type": "text/csv",
      "Authorization": `Bearer ${accessToken}`,
    }
  })
};

const getCampaign = (id, config) => {
    return http.get("/rsp/campaign/" + id, config);
};

const getConsumerCampaign = (id, config) => {
  return http.get("/consumer/campaign/" + id, config);
};


const startCampaign = (id, config) => {
  return http.get("/rsp/campaign/" + id + "/start", config);
};

const startConsumerCampaign = (id, config) => {
  return http.get("/consumer/campaign/" + id + "/start", config);
};

const getCampaignMetrics = (id, config) => {
  return http.get("/rsp/campaign/" + id + "/metrics", config);
};

const getConsumerCampaignMetrics = (id, config) => {
  return http.get("/consumer/campaign/" + id + "/metrics", config);
};


const getAllCampaigns = (config) => {
  return http.get("/rsp/campaign", config);
};

const getAllQrCampaigns = (config) => {
  return http.get("/consumer/campaign", config);
};





// XXX is this needed?
const updateCampagin = (id, params) => {
  return http.put("/rsp/campaign/" + id, params);
};

const deleteCampaign = (id, config) => {
  return http.delete("/rsp/campaign/" + id, config);
};

const deleteConsumerCampaign = (id, config) => {
  return http.delete("/consumer/campaign/" + id, config);
};


const generateCampaignReport = (id, config)=>{
  return http.get("/rsp/campaign/" + id + "/report/generate", config);
}

const generateConsumerCampaignReport = (id, config)=>{
  return http.get("/consumer/campaign/" + id + "/report/generate", config);
}


const timeSlicingControls = (id, operation, config)=>{
  return http.get("/rsp/campaign/" + id + "/processing/" + operation, config);
}

const consumerTimeSlicingControls = (id, operation, config)=>{
  return http.get("/consumer/campaign/" + id + "/processing/" + operation, config);
}

const downloadCampaignReport = (id, config)=>{
    return http.get("/rsp/campaign/" + id + "/report/download", config);
}

const downloadConsumerCampaignReport = (id, config)=>{
  return http.get("/consumer/campaign/" + id + "/report/download", config);
}

const downloadCodes = (id, config)=>{
  return http.get("/consumer/campaign/" + id + "/codes/download", config);
}

function getRequestParams(size, previous, next, search){
  let params = {};

  if (size) {
    params["size"] = size;
  }

  if (previous) {
    params["previous"] = previous;
  }

  if (next) {
    params["next"] = next;
  }

  // if (search) {
  //   params["searchFilter"] = search;
  // }

  return params;
};

function percentage(partialValue, totalValue) {
  var per = (100 * partialValue) / totalValue;
  return parseFloat(per).toFixed(2);
}

const createNewQRCampaignByCsvObject = (name, type) => {
  return {"name": name, "type": type}
}

const createNewQRCampaignByProfileTypeObject = (name, type,profileType, noOfProfiles) => {
  return {
    "name": name,
    "type": type,
    "properties": {
      "profileType" : profileType,
      "quantity": noOfProfiles
    }
  }
}


const functions = {
  createCampaign,
  createQRByCsvCampaign,
  createQRByProfileTypeCampaign,
  createNewQRCampaignByProfileTypeObject,
  createNewQRCampaignByCsvObject,
  createNewCampaignObject,
  uploadCampaignCSV,
  uploadCampaignConsumerCSV,
  startCampaign,
  getCampaign,
  getAllCampaigns,
  updateCampagin,
  deleteCampaign,
  getCampaignMetrics,
  getRequestParams,
  percentage,
  downloadCampaignReport,
  generateCampaignReport,
  getAllQrCampaigns,
  timeSlicingControls,
  getConsumerCampaign,
  startConsumerCampaign,
  deleteConsumerCampaign,
  getConsumerCampaignMetrics,
  downloadConsumerCampaignReport,
  generateConsumerCampaignReport,
  consumerTimeSlicingControls,
  downloadCodes
};

export default functions;
