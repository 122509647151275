import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Component } from "react";

class Doghnut extends Component {
	constructor(props) {
		super(props);
		ChartJS.register(ArcElement, Tooltip, Legend);

		let typeString = this.props.stateType.toLowerCase();
		typeString = typeString.charAt(0).toUpperCase() + typeString.slice(1)

		this.data = {
			labels: [typeString, "Others"],
			datasets: [
				{
					label: this.props.statesTotal,
					data: this.props.stateCount,
					backgroundColor: [
						this.props.stateColor,
						"rgba(224, 224, 224, 1)",
					],
					borderWidth: 1,
				},
			],
		};

	}
	options={
		padding:"0px",
		responsive:true,
		maintainAspectRatio:true,
		// defaultFontSize:"14px",
		// minFontSize: 10,
		width:"auto",
		height:"auto",
		plugins: {
			legend: {
			  display: false
			}
		}
	}

	textCenter = {
		id: "textCenter",
		beforeDatasetsDraw(chart, args, pluginOptions) {
			const { ctx, data } = chart;
			ctx.save();
			ctx.font = "bolder 12px sans-serif";
			ctx.fillStyle = "#858796";
			ctx.textAlign = "center";
			ctx.textBaseline = "middle";
			ctx.minFontSize = 60;
			ctx.fillText(
				data.datasets[0].label + "%",
				chart.getDatasetMeta(0).data[0].x,
				chart.getDatasetMeta(0).data[0].y
			);
		},
	};


	render() {
		return (
			<Doughnut
				data={this.data}
				options={this.options}
				plugins={[this.textCenter]}
			/>
		);
	}
}

export default Doghnut;
