import http from "../api/http-common";

const getEisCount = (config) => {
  return http.get("/rsp/dashboard/counts/eis", config);
};

const getProfileCount = (config) => {
  return http.get("/rsp/dashboard/counts/profile", config);
};

const getConsumerProfileCount = (config) => {
  return http.get("/rsp/dashboard/counts/consumer", config);
};

// const getEisCountForGraph = (config) => {
//     return http.get("/rsp/dashboard/graphs/eis/count/", config);
// };

// const getProfileForGraph = (config) => {
//     return http.get("/rsp/dashboard/graphs/profile/count", config);
// };

function convertMapToArray(data){
  var monthlyCounts = []
  for (let month = 0; month < 12; month++) {
    monthlyCounts.push(data[month]);
  }

  return monthlyCounts;
}

function reorderCountsForJanuary(currentMonth, data){
  var first = data.slice(11);
  var second = data.slice(currentMonth-1, 11);
  return second.concat(first);
}


function reorderByCurrentMonth(currentMonth, data){
  var first = data.slice(0, currentMonth);
  var second = data.slice(currentMonth, 12);

  return second.concat(first);
}

const functions = {
  getEisCount,
  getProfileCount,
  getConsumerProfileCount,
  // getEisCountForGraph,
  // getProfileForGraph,
  convertMapToArray,
  reorderByCurrentMonth,
  reorderCountsForJanuary
};

export default functions;
