import React, { Component } from "react";

//import OperationButton from "./OperationButton";
import OffcanvasOperationButton from "./OffcanvasOperationButton";
import OperationsService, {getDefaultVP_SetFallbackAttribute, getDefaultVP_AuditEis, getDefaultVP_DownloadProfile, getDefaultVP_EnableProfile, getDefaultVP_DisableProfile, getDefaultVP_DeleteProfile} from "../../../../services/OperationsService";

import { withOktaAuth } from '@okta/okta-react';

class SingleRspOperationsButtons extends Component {

    shouldProfileButtonsBeDisabled(){
        let isButtonDisabled = false;

        if (this.props.profiles !== null){
            let numberOfProfiles = this.props?.profiles?.profileInfo?.length;
            if (numberOfProfiles <= 1){
                isButtonDisabled = true
            }
        }
        return isButtonDisabled;
    }

    render(){
        let isButtonDisabled = this.shouldProfileButtonsBeDisabled();

        return (
            <div className={'click-row col-md-12 mb-4 click-card col-xl-' + this.props.colWidth}>
                <div className={'card shadow h-100 py-2 border-left-' + this.props.color}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                            <div className={'text-xs font-weight-bold text-uppercase mb-1 text-' + this.props.color}>{this.props.name}</div>
                            <div className={'h6 mb-0 text-gray-800'}>
                                <div className="action-btns">
                                    {/* GET EIS */}
                                    {/* <OperationButton placement="bottom" title="Get EIS" icon="bi-clipboard-check" isButtonDisabled={false}
                                        // onClick={this.handleClickGetEIS} />
                                        onClick={e => OperationsService.handleClickGetEIS(this.props.eid, this.props.oktaAuth.getAccessToken())} /> */}
                                    
                                    {/* DOWNLOAD PROFILE */}
                                    <OffcanvasOperationButton placement="bottom" title="Download Profile" icon="bi-cloud-arrow-down" isButtonDisabled={false}
                                        profiles={this.props.profiles} operationType="download-profile"
                                        message="Please enter the ICCID that you'd like to Download onto this eUICC."
                                        onClick={e =>  OperationsService.handleClickDownloadProfile(this.props.eid, this.props.oktaAuth.getAccessToken()) }
                                        validity={getDefaultVP_DownloadProfile}/>

                                    <OffcanvasOperationButton placement="bottom" title="Download Profile By Profile Type"  icon="bi-arrow-down-circle" isButtonDisabled={false}
                                                              profiles={this.props.profiles} operationType="download-profile-by-profile-type"
                                                              message="Please enter the type of Profile that you'd like to Download onto this eUICC."
                                                              accessToken={this.props.oktaAuth.getAccessToken()}
                                                              onClick={e =>  OperationsService.handleClickDownloadProfileByProfileType(this.props.eid, this.props.oktaAuth.getAccessToken()) }
                                                              validity={getDefaultVP_DownloadProfile}/>

                                    {/* PROFILE ENABLE / DISABLE / DELETE */}
                                    <OffcanvasOperationButton placement="bottom" title="Enable Profile" icon="bi-file-check" isButtonDisabled={isButtonDisabled}
                                        profiles={this.props.profiles} operationType="enable-profile"
                                        message="Please select the ICCID that you'd like to Enable on this eUICC."
                                        onClick={e => OperationsService.handleClickEnableProfile(this.props.eid, this.props.oktaAuth.getAccessToken())}
                                        validity={getDefaultVP_EnableProfile} />

  

                                    <OffcanvasOperationButton placement="bottom" title="Disable Profile" icon="bi-file-minus"
                                        isButtonDisabled={isButtonDisabled}
                                        profiles={this.props.profiles} operationType="disable-profile"
                                        message="Please select the ICCID that you'd like to Disable on this eUICC."
                                        onClick={e => OperationsService.handleClickDisableProfile(this.props.eid, this.props.oktaAuth.getAccessToken())}
                                        validity={getDefaultVP_DisableProfile} />

                                    <OffcanvasOperationButton placement="bottom" title="Delete Profile" icon="bi-file-excel"
                                        isButtonDisabled={isButtonDisabled}
                                        profiles={this.props.profiles} operationType="delete-profile"
                                        message="Please select the ICCID that you'd like to Delete from this eUICC."
                                        onClick={e => OperationsService.handleClickDeleteProfile(this.props.eid, this.props.oktaAuth.getAccessToken())}
                                        validity={getDefaultVP_DeleteProfile}/>

                                    {/* AUDIT */}
                                    <OffcanvasOperationButton placement="bottom" title="Audit EIS" icon="bi-bug"
                                        isButtonDisabled={false}
                                        operationType="audit-eis"
                                        message="Send and Audit EIS command with the validity period below."
                                        onClick={e => OperationsService.handleClickAuditEis(this.props.eid, this.props.oktaAuth.getAccessToken())}
                                        validity={getDefaultVP_AuditEis}/>

                                    {/*Set Fallback Attribute*/}
                                    <OffcanvasOperationButton placement="bottom" title="Set Fallback Attribute" icon="bi bi-arrow-counterclockwise" isButtonDisabled={isButtonDisabled}
                                        profiles={this.props.profiles} operationType="set-fallback-attribute"
                                        message="Please select the ICCID of the profile on which you would like to set the Fallback Attribute"
                                        onClick={e => OperationsService.handleSetFallbackAttribute(this.props.eid, this.props.oktaAuth.getAccessToken())}
                                        validity={getDefaultVP_SetFallbackAttribute} />  

                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default withOktaAuth(SingleRspOperationsButtons);
