import React, { Component } from "react";

class ShowItems extends Component {
    render(){ 
        return <div className="col-sm-12 col-md-6">
        <div className="dataTables_length" id="dataTable_length">
        <label>Show <select value={this.props.size} 
            onChange={this.props.handleSizeChange}
            name="dataTable_length" data-tid="showItems" aria-controls="dataTable" className="inline-select custom-select custom-select-sm form-control form-control-sm">'
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
            </select> entries</label>
        </div>
    </div>
    }
};
    
export default ShowItems;