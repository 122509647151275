import React, { Component } from 'react';
import QueryString from 'query-string';
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";

import './admin-customers.css'

//Navigation
import SidebarAdmin from '../../components/Navigation/SidebarAdmin';
import Topbar from '../../components/Navigation/Topbar';
import Footer from '../../components/Footer';
import CustomersList from '../../components/admin/customers/CustomerListView/CustomersList';
import CustomerSingle from '../../components/admin/customers/CustomerSingleView/CustomerSingle';

class AdminFeatureToggles extends Component {
  constructor(props) {
		super(props);
        // redirect to 404 if user doesn't have campaign role
        const claims = this.props.authState.accessToken.claims;
        if(claims){
            const claimKeys = Object.keys(claims);
            const roleKey = claimKeys?.find(key => /roles_/.test(key));
            const roles = claims[roleKey];
            const consumerRole = roles?.find(value => /okta_admin/.test(value));
            if(!consumerRole){
                window.location.href = "/404";
            }
        }
  }

  render() {
    let params = QueryString.parse(this.props.location.search)

    // render customer list view
    let viewToRender = <CustomersList />
    if ((params.customer_id !== undefined) && (params.customer_id !== "")){
      // render customer single view
      if ((params.anchor === undefined) || (params.anchor === "")){
        params.anchor = "data";
      }

      viewToRender = <CustomerSingle customer_id={params.customer_id} anchor={params.anchor}/>
    }

    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          <SidebarAdmin active="customers"/>

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">

            {/* <!-- Main Content --> */}
            <div id="content">
              <Topbar icon="bi-people" title="Customers"/>

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">
                {viewToRender}
              </div>
              {/* <!-- /.container-fluid --> */}

            </div>
            {/* <!-- End of Main Content --> */}

            <Footer />
          </div>
          {/* <!-- End of Content Wrapper --> */}

        </div>
        {/* <!-- End of Page Wrapper --> */}
      </div>
    )
  }
}

export default withOktaAuth(withRouter(AdminFeatureToggles));