import React, { Component } from "react";
import {Spinner} from 'react-bootstrap';
import './campaign-single.css'
import CampaignAttribute from './CampaignAttribute'

let synchronousTypes  = ["update-imsi", "update-msisdn", "cold-storage-eid", "cold-storage-iccid"];
class CampaignInfoTable extends Component {

  renderTableHeaders(){
    return (
      <thead>
        <tr>
          <th>Attribute</th>
          <th>Value</th>
        </tr>
      </thead>
    )
  }

  renderEmptyTable(isLoading){
    let loadingContent = <td colSpan="2"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
    if (isLoading){
      loadingContent = <td colSpan="2"><Spinner animation="border" size="sm" /></td>;
    }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody>
            <tr>
              {loadingContent}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderTable(){

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody data-tid="parameters-table">
              <CampaignAttribute title="Procedure" value={this.props.content?.properties?.procedure}/>
              <CampaignAttribute title="Mno Id" value={this.props.content?.properties?.mnoId}/>
              {!synchronousTypes.includes(this.props.content?.properties?.procedure) &&<CampaignAttribute title="Validity Period" value={this.props.content?.properties?.validityPeriod}/>}
              {this.props.content?.properties?.procedure ==="download-profile"&&  <CampaignAttribute title="Enable" value={this.props.content?.properties?.enable === true ? "True":"False" }/>}
            </tbody>
        </table>
      </div>
    )
  }

  render(){ 
    var tableHTML = "";

    if (this.props.content === null){
      tableHTML = this.renderEmptyTable(true);

    } else if (this.props.content.length === 0){
      tableHTML = this.renderEmptyTable(false);

    } else{
      tableHTML = this.renderTable();
    }

    return (
      <div className={"d-grid col-lg-"+this.props.colWidth}>
        <div className="card shadow mb-4">

          <div className="card-body">
            <div className="campaignDetailsTable d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary"><i className="bi bi-info-circle"></i> Parameters</h6>
            </div>

            {/* <!-- campaign info table --> */}
            <div className="table-responsive">
              {tableHTML}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CampaignInfoTable;